<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-full pb-16 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div>
            <div v-if="report" @click="report = ''" class="text-center text-sm text-red-500 cursor-pointer  mb-1">{{ report }}</div>
            <div><input v-model="receiverUid" required placeholder="กรอก UID ของเจ้านายใหม่" class="w-full p-3 text-lg font-bold bg-gray-700 rounded border border-gray-600 focus:border-indigo-500 focus:outline-none text-center" type="text"></div>
          </div>
          <div class="mt-1">
          <button v-if="!isPending" @click="handleChangeWorkerOwner" type="button" class="w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">เปลี่ยนเจ้าของ</button>
          <button v-if="isPending"  type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm animate-pulse  duration-300">LOADING...</button>
          <button v-if="!isPending" @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
        </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
import { projectFirestore, projectFunctions } from '@/firebase/config'
import { useRouter } from 'vue-router'
  export default {
    props: ['id'],
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}
        const router = useRouter()

        const receiverUid = ref('')
        const isPending = ref(false)
        const report = ref('')
        
        const handleChangeWorkerOwner = e =>{
          const changeWorkerOwner = projectFunctions.httpsCallable('changeWorkerOwner')
          isPending.value = true
          changeWorkerOwner({receiverUid: receiverUid.value, workerId: props.id}).then(result =>{
            report.value = result.data
            isPending.value = false
            if(result.data === 'เปลี่ยนเจ้านายใหม่สำเร็จ'){router.push({ name: 'Worker' })}
          }).catch(err =>{
            isPending.value = false
          })
        }

        return { report,cancelModal,handleChangeWorkerOwner,isPending,receiverUid }
    },
  }
  </script>
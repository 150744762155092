<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-4/5 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-gray-300 p-3">
          <div>
            <div class="text-center font-bold text-xl">ไอเทมสำหรับผู้เล่นใหม่ !!</div>
            <div class="grid grid-cols-1">
                <div v-for="item in items" :key="item.id">
                  <div class="flex py-0.5 text-xs sm:text-sm justify-between items-center border-b border-gray-700">
                    <div class="flex items-center space-x-1">
                      <div><img :src="item.img" class="w-7 sm:w-10"></div>
                      <div class="flex justify-center items-center space-x-1 border border-gray-700 px-1 rounded">
                        <div><img src="../assets/images/star.png" class="w-3"></div>
                        <div class=" font-bold text-sm">{{ item.star }}</div>
                      </div>
                      <div :class="item.textColor" class="font-bold">{{ item.name }}</div>
                    </div>
                    <div class="flex items-center space-x-1 font-bold  text-xs sm:text-sm">
                      <div v-if="item.health >= 1" class="flex items-center space-x-1">
                        <div>{{ item.health }}</div>
                        <div><img src="../assets/images/hearth.png" class="w-4 sm:w-5"></div>
                      </div>
                      <div v-if="item.attack >= 1" class="flex items-center space-x-1">
                        <div>{{ item.attack }}</div>
                        <div><img src="../assets/images/sword.png" class="w-4 sm:w-5"></div>
                      </div>
                      <div v-if="item.defend >= 1" class="flex items-center space-x-1">
                        <div>{{ item.defend }}</div>
                        <div><img src="../assets/images/shield.png" class="w-4 sm:w-5"></div>
                      </div>
                      <div v-if="item.accuracy >= 1" class="flex items-center space-x-1">
                        <div>{{ item.accuracy }}</div>
                        <div><img src="../assets/images/target.png" class="w-4 sm:w-5"></div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div >
          <button v-if="!isPending" @click="handleGetFreeWorker()" type="button" class="w-full justify-center rounded bg-lime-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-lime-700 duration-300">รับไอเทมฟรี</button>
          <button v-if="isPending"  class="mt-1.5 w-full justify-center rounded bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   ">Loading...</button>
          <button v-if="!isPending" @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
        </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
  import { projectFirestore, projectFunctions } from '@/firebase/config'
  import { useRouter } from 'vue-router'

  export default {
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}
        const router = useRouter()
        const items = ref([
           {name:'ท่อนไม้',health: 0, attack: 10, defend: 0, accuracy: 0,star: 0, type: 'weapon', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fstick.png?alt=media&token=f689ed10-e41d-44bc-ab00-c1dc0522063a'},
           {name:'หมวกเน่า',health: 50, attack: 0, defend: 0, accuracy: 0,star: 0, type: 'head', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
           {name:'เสื้อเน่า',health: 50, attack: 0, defend: 1, accuracy: 0,star: 0, type: 'body', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
           {name:'กางเกงเน่า',health: 50, attack: 0, defend: 0, accuracy: 0,star: 0, type: 'bottom', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
           {name:'รองเท้าเน่า',health: 50, attack: 0, defend: 0, accuracy: 0,star: 0, type: 'foots', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
        ])
        const isPending = ref(false)
        const report = ref('')
        const handleGetFreeWorker = e =>{
          const getFreeWorker = projectFunctions.httpsCallable('getFreeItem')
          isPending.value = true
          getFreeWorker().then(result =>{
            isPending.value = false
            report.value = result.data
            if(result.data === 'คุณได้รับแพคไอเทมสำหรับผู้เล่นใหม่'){
              context.emit('cancelModal')
              router.push({ name: 'Item' })
            }
          }).catch(err =>{
            isPending.value = false
          })
        }
  
        return { items, isPending,handleGetFreeWorker,cancelModal }
    },
  }
  </script>
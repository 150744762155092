<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-full pb-16 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div class="grid grid-cols-1 gap-1 text-center">
            <div class="text-lg font-bold text-center">สิทธิประโยชน์สำหรับ VIP คนงาน</div>
            <div class="flex items-center space-x-1 justify-center bg-lime-100 p-3  border border-lime-500 rounded text-green-900 text-xs sm:text-sm">
              <div><img src="../../assets/images/rip.png" class="w-5 animate-pulse"></div>
              <div>คนงานเกิดอัตโนมัติ และทำงานอัตโนมัติ</div>
            </div>
            <div class="flex items-center space-x-1 justify-center bg-lime-100 p-3  border border-lime-500 rounded text-green-900 text-xs sm:text-sm">
              <div><img src="../../assets/images/boost.png" class="w-5 animate-pulse"></div>
              <div>ได้รับเหรียญเงิน 2 เท่าเมื่อสังหารโจรสำเร็จ</div>
            </div>
            <div class="flex items-center space-x-1 justify-center bg-lime-100 p-3  border border-lime-500 rounded text-green-900 text-xs sm:text-sm">
              <div><img src="../../assets/images/boost.png" class="w-5 animate-pulse"></div>
              <div>เพิ่มโอกาสการดรอปไอเทม 2 เท่า</div>
            </div>
            <div class="flex items-center space-x-1 justify-center bg-lime-100 p-3  border border-lime-500 rounded text-green-900 text-xs sm:text-sm">
              <div><img src="../../assets/images/boost.png" class="w-5 animate-pulse"></div>
              <div>เพิ่มโอกาสการดรอปของสะสม 2 เท่า</div>
            </div>
            <div class="flex items-center space-x-1 justify-center bg-lime-100 p-3  border border-lime-500 rounded text-green-900 text-xs sm:text-sm">
              <div><img src="../../assets/images/boost.png" class="w-5 animate-pulse"></div>
              <div>เพิ่มโอกาสดรอปคีย์คนงาน 2 เท่า</div>
            </div>
            <div class="flex items-center space-x-1 justify-center bg-lime-100 p-3  border border-lime-500 rounded text-green-900 text-xs sm:text-sm">
              <div><img src="../../assets/images/boost.png" class="w-5 animate-pulse"></div>
              <div>เกิดไวขึ้น 1 ชั่วโมง (จากปกติ 2 ชั่วโมง)</div>
            </div>
            <div class="flex items-center space-x-1 justify-center bg-lime-100 p-3  border border-lime-500 rounded text-green-900 text-xs sm:text-sm">
              <div><img src="../../assets/item/pet/rottweiler.png" class="w-5 animate-pulse"></div>
              <div>สามารถดรอปคีย์สัตว์เลี้ยง</div>
            </div>
            <a href="https://discord.gg/tdD5cx75Un" target="_blank" class="my-0.5 mb-1">
              <div class=" p-2 rounded duration-300 hover:bg-indigo-700 flex items-center justify-center space-x-1 w-ull bg-indigo-900">
                <div><img src="../../assets/images/discord.png" class="w-6 opacity-80 hover:opacity-100 cursor-pointer"></div>
                <div>เข้าดิสคอร์ดเพื่อเติม VIP</div>
              </div>
            </a>
          </div>
          <div class="text-sm text-center">{{ report }}</div>
          <form v-if="!isPending" @submit.prevent="handleAddVip" class="mt-1.5">
            <input  v-model="key" type="text" required class="w-full p-3 bg-gray-700 rounded border border-gray-600 focus:border-indigo-500 focus:outline-none text-center text-2xl" placeholder="กรอกคีย์">
            <button   type="submit" class="mt-1.5 w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">เติมคีย์ VIP 30 วัน</button>
            
            <button   @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
          </form>
          <div>
            <div v-if="isPending" class="flex items-center justify-center h-20">
              <div><img src="../../assets/images/vip.png" class="w-20 animate-spin"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
import { projectFunctions } from '@/firebase/config'
  export default {
    props:['id'],
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}
        const key = ref('')
        const report = ref('')
        const isPending = ref(false)
        const handleAddVip = e =>{
          const addVip = projectFunctions.httpsCallable('addVip')
          isPending.value = true
          addVip({password: key.value,id: props.id}).then(result =>{
            report.value = result.data
            isPending.value = false
            if(result.data === 'เติม VIP สำเร็จ'){
              context.emit('cancelModal')
            }
          }).catch(err =>{
            isPending.value = false
          })
        }
        return { cancelModal,key,handleAddVip,isPending,report }
    },
  }
  </script>
<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-4/5 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div>
            <div class="text-sm text-gray-300">UID PARTNER</div>
            <div><input v-model="uidPartner" type="text" class="p-1 text-gray-900 bg-gray-100 w-full rounded"></div>
            <div v-if="report" class="text-center text-sm">{{ report }}</div>
          </div>
          <div class="mt-2">
          <button v-if="!isPending" @click="handleRemovePartner" type="button" class="w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">ยกเลิกพาร์ทเนอร์</button>
          <button v-if="!isPending" @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
        </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
import { projectFunctions } from '@/firebase/config'
  export default {
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}
        const uidPartner = ref('')
        const isPending = ref(false)    
        const report = ref('')

        const handleRemovePartner = e =>{
          const removePartner = projectFunctions.httpsCallable('removePartner')
          isPending.value = true
          removePartner({uidPartner: uidPartner.value}).then(result =>{
            report.value = result.data
            isPending.value = false
            
          }).catch(err =>{
            isPending.value = false
          })
        }

        return { handleRemovePartner,isPending,cancelModal,uidPartner,report }
    },
  }
  </script>
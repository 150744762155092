<template>
  <div v-if="!worker.isLive" class="m-1 text-xs sm:text-sm">
    <div v-if="bornHours >= 0 && bornMinutes >= 0 && bornSeconds >= 0" class="p-2 bg-gray-900 border-red-500 border rounded text-center text-red-500 font-bold">คนงานเสียชีวิตกำลังเกิดภายใน {{ bornHours }} ชั่วโมง {{ bornMinutes }} นาที {{ bornSeconds }} วินาที</div>
    <div v-if="bornHours < 0 && bornMinutes < 0 && bornSeconds < 0" class="p-2 bg-gray-900 border-lime-500 border rounded text-center text-lime-500 font-bold">กำลังจะเกิดภายใน 3 นาที</div>
  </div>
  <div v-if="workerExists" class="p-1 pb-24 mb-auto h-full overflow-auto" id="style-1">
    <div class="grid grid-cols-1 sm:grid-cols-3 gap-1">
      <Information :id="id" />
      <Item :id="id" />
      <Menu :id="id" />
      <Log :id="id" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { projectFirestore, projectFunctions } from '@/firebase/config'
import Information from '../../components/Worker/Information.vue'
import Item from '../../components/Worker/Item.vue'
import Menu from '../../components/Worker/Menu.vue'
import Log from '../../components/Worker/Log.vue'
import getUser from '@/composables/getUser'
import dayjs from 'dayjs';
import 'dayjs/locale/th';

export default {
  props: ['id'],
  components: { Information, Item, Menu, Log },
  setup(props) {
    const workerExists = ref(false)
    const workerInstantBorn = ref()
    const workerFirstAid = ref()
    const router = useRouter()
    const { user,admin } = getUser()
    const worker = ref({})
    const checkWorkerExists = async () => {
      try {
        const doc = await projectFirestore.collection('workers').doc(props.id).get()
        if (doc.exists) {
          if (user.value.uid === doc.data().ownerUid || admin.value ) {
            workerExists.value = true
          } else {
            router.push('/')
          }
        } else {
          router.push('/')
        }
      } catch (error) {
        console.error("Error checking worker existence: ", error)
        router.push('/')
      }
    }

    const bornMinutes = ref(1)
    const bornSeconds = ref(1)
    const bornHours = ref(1)
    projectFirestore.collection('workers').doc(props.id).onSnapshot(snapshot =>{
      worker.value = snapshot.data()
      workerInstantBorn.value = snapshot.data().instantBorn.toDate().getTime()
      workerFirstAid.value = snapshot.data().firstAid.toDate().getTime()
      var countDownDate = snapshot.data().born.toDate().getTime() + 3000
      var x = setInterval(() => {
        var now = new Date().getTime()
        var distance = countDownDate - now
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000)
        bornHours.value = hours
        bornMinutes.value = minutes
        bornSeconds.value = seconds
        if (distance < 0) {
          clearInterval(x)
        }
      }, 0)
    })

    const instantBornPending = ref(false)
    const handleInstantBorn = e =>{
      const instantBorn = projectFunctions.httpsCallable('instantBorn')
      instantBornPending.value = true
      instantBorn({id: props.id}).then(reuslt =>{
        console.log(reuslt.data)
        instantBornPending.value = false
      }).catch(err =>{
        instantBornPending.value = false
      })

    }

    const firstAidPending = ref(false)
    const handleFirstAid = e =>{
      const firstAid = projectFunctions.httpsCallable('firstAid')
      firstAidPending.value = true
      firstAid({id: props.id}).then(result =>{
        firstAidPending.value = false
      }).catch(err =>{
        firstAidPending. value =false
      })
    }

    onMounted(() => {
      checkWorkerExists()
    })

    return { instantBornPending,
handleInstantBorn,
firstAidPending,
handleFirstAid, workerFirstAid, firstAidPending, handleFirstAid, dayjs,workerInstantBorn,instantBornPending,handleInstantBorn,bornHours,worker,workerExists,bornMinutes,bornSeconds }
  },
}
</script>

<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-4/5 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div>
            <div class="space-y-2">
            <div class="flex justify-center"><img :src="order.img" class="w-28"></div>
            
            <div class="flex items-center justify-center  space-x-1">
              <div class="flex justify-center items-center space-x-1 border border-gray-700 px-1 rounded">
                <div><img src="../../assets/images/star.png" class="w-3"></div>
                <div class=" font-bold text-sm">{{ order.star }}</div>
              </div>
              <div class="text-center font-bold text-sm">{{order.name}}</div>
              </div>
              <div class="flex justify-center space-x-1 items-center text-sm font-bold">
                <div v-if="order.health >= 1" class="flex items-center space-x-1">
                  <div><img src="../../assets/images/hearth.png" class="w-4"></div>
                  <div>{{ order.health  }}</div>
                </div>
                <div v-if="order.attack >= 1" class="flex items-center space-x-1">
                  <div><img src="../../assets/images/sword.png" class="w-4"></div>
                  <div>{{ order.attack }}</div>
                </div>
                <div v-if="order.defend >= 1" class="flex items-center space-x-1">
                  <div><img src="../../assets/images/shield.png" class="w-4"></div>
                  <div>{{ order.defend  }}</div>
                </div>
                <div v-if="order.accuracy >= 1" class="flex items-center space-x-1">
                  <div><img src="../../assets/images/target.png" class="w-4"></div>
                  <div>{{ order.accuracy  }}</div>
                </div>
              </div>
              
          </div>
          <div class="text-center text-sm text-red-500">{{ report }}</div>
          </div>
          <div class="mt-2">
          <button v-if="!isPending && user.uid !== order.ownerUid" @click="handleBuyOrder" type="button" class="flex items-center space-x-1 w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">
            <div >ซื้อ</div>
            <div>{{ order.price }}</div>
            <div><img src="../../assets/images/coin.png" class="w-5"></div>
          </button>
          <button v-if="!isPending && user.uid === order.ownerUid" type="button" class="flex items-center space-x-1 w-full justify-center rounded bg-red-900 px-3 py-2 text-sm font-semibold text-white shadow-sm cursor-not-allowed">
            <div >คุณไม่สามารถซื้อไอเทมของคุณเองได้</div>
          </button>
          <button v-if="isPending" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm duration-500 cursor-not-allowed">Loading...</button>
          <button v-if="!isPending" @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
        </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import getUser from '@/composables/getUser';
import { projectFirestore, projectFunctions } from '@/firebase/config';
import { ref } from 'vue'
import { useRouter } from 'vue-router'

  export default {
    props: ['orderId'],
    setup(props,context) {
        const { user } = getUser()
        const router = useRouter()
        const cancelModal = e =>{context.emit('cancelModal')}
        const order = ref({})
        projectFirestore.collection('orders').doc(props.orderId).get().then(snapshot =>{
            order.value = snapshot.data()
        })

        const report = ref('')
        const isPending = ref(false)
        const handleBuyOrder = e =>{
          const buyOrder = projectFunctions.httpsCallable('buyOrder')
          isPending.value = true
          buyOrder({orderId: props.orderId}).then(result =>{
            report.value = result.data
            isPending.value = false
            if(result.data === 'คุณซื้อไอเทมสำเร็จ'){
              router.push({ name: 'Item' })
            }
          }).catch(err =>{
            report.value = err.message
            isPending.value = false
            
          })

        }

        return { user,cancelModal,handleBuyOrder, report, isPending,order }
    },
  }
  </script>
<template>
    <!-- <div v-if="admin" class="flex items-center justify-center bg-indigo-900 hover:bg-indigo-800 p-3 duration-300 cursor-pointer">เคลียร์ตาราง</div> -->
    <div class="grid grid-cols-2 text-center">
      <div class="p-2 bg-indigo-900 hover:bg-indigo-800 cursor-pointer">ยังไม่ถูกซื้อ</div>
      <div class="p-2 bg-indigo-900 hover:bg-indigo-800 cursor-pointer">ถูกซื้อแล้ว</div>
    </div>
    <div class="p-1 pb-16 mb-auto h-full text-white  overflow-auto" id="style-1">
      <div>
        <div>
          <div class="grid grid-cols-5 sm:grid-cols-6 md:grid-cols-7 lg:grid-cols-8 xl:grid-cols-9 2xl:grid-cols-10 gap-1">
            <div v-for="lottery in lotteries" :key="lottery">
              <div class="bg-gray-900 hover:bg-indigo-900 hover:text-gray-300 cursor-pointer duration-200 p-3 rounded text-center">{{ lottery.number }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
import getUser from '@/composables/getUser';
import { projectFirestore } from '@/firebase/config';
import { ref } from 'vue';
  export default {
      setup() {
        const { user,admin } = getUser()
        const filterSold = ref('sold')

        const clear = e =>{
          projectFirestore.collection('lotteries').get().then(snapshot =>{
            snapshot.forEach(lotteryDoc =>{
              lotteryDoc.ref.update({
                ownerUid: ''
              })
            })
          })
        }
        

        const lotteries = ref([])
        projectFirestore.collection('lotteries').orderBy('position','asc').get().then(snapshot => {
          snapshot.forEach(lotteryDoc => {
            lotteries.value.push(lotteryDoc.data()) // ใช้ push เพื่อเพิ่มข้อมูลลงใน array
          })
        })


        
        
        return { admin,lotteries,clear }
      },
  }
  </script>
<template>
  <div class="mx-auto py-2 text-white w-full">
    <ModalKey v-if="toggleModal === 'ModalKey'" @cancelModal="toggleModal =''" :keySelected="keySelected" />
    <div class="grid grid-cols-1 gap-2">
      <div v-for="key in keys" :key="key.id" @click="handleModal('ModalKey', key)" :class="[key.used ? 'bg-red-900' : 'bg-gray-800']" class="flex items-center justify-between p-1 rounded cursor-pointer transition-transform duration-300 hover:scale-105 hover:bg-opacity-80">
        <div class="flex items-center space-x-4">
          <img :src="key.img" class="w-10 rounded-full">
          <div class="text-lg font-bold">{{ key.password }}</div>
          <div v-if="!key.used">
            <svg xmlns="http://www.w3.org/2000/svg" :class="[now >= key.expireDate.toDate() ? 'text-red-500' : 'text-green-500']" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
          </div>
        </div>
        <div class="text-gray-400">{{ dayjs(key.createdAt.toDate()).locale('th').fromNow() }}</div>
      </div>
    </div>
    <div class="flex justify-center mt-4" v-if="moreAvailable">
      <button @click="loadMore" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Load More
      </button>
    </div>
  </div>
</template>


<script>
import { ref, onMounted, onUnmounted } from 'vue'
import { projectFirestore } from '@/firebase/config'
import getUser from '@/composables/getUser'
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import relativeTime from 'dayjs/plugin/relativeTime'
import ModalKey from '../partner/ModalKey.vue'

export default {
  components: { ModalKey },
  setup() {
    dayjs.extend(relativeTime)
    const { user } = getUser()
    const toggleModal = ref('')
    const keys = ref([])
    const lastVisible = ref(null)
    const moreAvailable = ref(true)
    const unsubscribe = ref(null)
    const now = new Date()
    const limit = 20

    const loadKeys = (isLoadMore = false) => {
      let query = projectFirestore.collection('keys')
        .where('partnerUid', '==', user.value.uid)
        .orderBy('createdAt', 'desc')
        .limit(limit)

      if (isLoadMore && lastVisible.value) {
        query = query.startAfter(lastVisible.value)
      }

      if (unsubscribe.value) {
        unsubscribe.value() // Unsubscribe from previous snapshot listener
      }

      unsubscribe.value = query.onSnapshot(snapshot => {
        if (!isLoadMore) {
          keys.value = []
        }

        if (snapshot.docs.length > 0) {
          lastVisible.value = snapshot.docs[snapshot.docs.length - 1]
          snapshot.forEach(keyDoc => {
            const key = keyDoc.data()
            key.id = keyDoc.id
            keys.value.push(key)
          })
        }

        if (snapshot.docs.length < limit) {
          moreAvailable.value = false
        }
      })
    }

    const keySelected = ref('')
    const handleModal = (modal, key) => {
      toggleModal.value = modal
      keySelected.value = key
    }

    const loadMore = () => {
      loadKeys(true)
    }

    onMounted(() => {
      loadKeys()
    })

    onUnmounted(() => {
      if (unsubscribe.value) {
        unsubscribe.value()
      }
    })

    return { toggleModal, dayjs, keys, now, keySelected, handleModal, loadMore, moreAvailable }
  }
}
</script>

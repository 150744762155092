<template>
    <div class="grid grid-cols-4 gap-1">
       <div v-for="weapon in weapons" :key="weapon">
         <div :class="[weapon.name === weaponSelected.name ? 'bg-yellow-900' : 'bg-gray-900']" class="bg-gray-900 rounded">
           <div class="flex justify-center"><img :src="weapon.img" class="w-12"></div>
             <div class="flex justify-center items-center space-x-1">
               <div><img src="../../assets/images/star.png" class="w-2.5"></div>
               <div class="font-bold text-xs">{{ weapon.star }}</div>
             </div>
             <div :class="weapon.textColor" class="text-center text-xs sm:text-sm ">{{ weapon.name }}</div>
             <div class="flex justify-center items-center space-x-1 ">
                  <div class="text-xs">{{ weapon.attack }}</div>
                <div><img src="../../assets/images/sword.png" class="w-2.5"></div>
                <div class="text-xs">{{ weapon.defend }}</div>
                <div><img src="../../assets/images/shield.png" class="w-2.5"></div>
              </div>
           </div>
         </div>
       </div>   
   </template>
   
   <script>
   import { ref } from 'vue'
   export default {
       setup() {
         const weapons = ref([
         {name:'ส้อม', attack: 10, defend: 0,star: 1, textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Ffork.png?alt=media&token=3950af1a-4a13-4676-80f1-e7ad3d77c720'},
          {name:'มัดหั่นผัก', attack: 13, defend: 0,star: 2, textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fbreadknife.png?alt=media&token=37625740-6cfc-4e79-80dc-ad00483945e9'},
          {name:'พลั่ว', attack: 16, defend: 0,star: 3, textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fpickaxe.png?alt=media&token=501fe6ca-c121-437d-ad44-a7458131a1e6'},
          {name:'มีด', attack: 19, defend: 0,star: 4, textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fknife.png?alt=media&token=e473e019-a551-443b-a26b-c4aa2705fce6'},
          {name:'อีโต้', attack: 22, defend: 0,star: 5, textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fcleaver.png?alt=media&token=4fee1733-6261-4639-9e52-5e97a79b84c5'},
          {name:'ขวาน', attack: 25, defend: 0,star: 6, textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Faxe.png?alt=media&token=e1c886d4-0553-41a1-9f6d-f7f2c1d16df3'},
          {name:'สนับมือ', attack: 28, defend: 0,star: 7, textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2FBrassKnuckles.png?alt=media&token=278399ee-e674-48bb-8367-e45713cd8b13'},
          {name:'ดาบ', attack: 31, defend: 0,star: 8, textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fsword.png?alt=media&token=61028d31-1ae6-4eed-9294-f00ad0971dd9'},
          {name:'ดาบโจรสลัด', attack: 34, defend: 0,star: 9, textColor: 'text-violet-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fpiratesword.png?alt=media&token=79009398-9469-40d0-bba2-a2c0261cc251'},
          {name:'แซ่', attack: 37, defend: 0,star: 10, textColor: 'text-pink-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2FWhip.png?alt=media&token=5c21b43b-98b2-4441-9caf-713c439cd6d4'},
         ])
   
         const weaponSelected = ref({
           name: '',
           health: 0,
           star: 0,
           attack: 0,
           defend: 0,
           img: ''
           })
   
           setInterval(() => {
           let random = Math.floor(Math.random() * weapons.value.length);
           weaponSelected.value = weapons.value[random];
           }, 100);
   
   
         return { weapons,weaponSelected }
       },
   }
   </script>
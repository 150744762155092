<template>
    <div class="p-1 sm:p-4 pb-24 mb-auto h-full text-white  overflow-auto" id="style-1">
        <div>asdsa</div>
    </div>
</template>
<script>
export default {
    setup() {
        
    },
}
</script>
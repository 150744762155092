<template>
  <div class="p-1 pb-24 mb-auto h-full text-white overflow-auto" id="style-1">
    <div class="grid grid-cols-6 bg-gray-900 text-gray-300 p-1 text-center text-xs sm:text-sm rounded-t border-b border-gray-700">
      <div>ประเภท</div>
      <div class=" col-span-2">ชื่อไอเทม</div>
      <div>ราคา</div>
      <div>จำนวนเงินที่ได้รับ</div>
      <div>เวลา</div>
    </div>
    <div v-for="log in logs" :key="log.id" class="grid grid-cols-6 items-center bg-gray-900 p-1 text-center text-xs sm:text-sm rounded-t border-b border-gray-700">
      <div :class="[log.info === 'ขาย' ? 'text-red-500' : 'text-green-500']">{{ log.info }}</div>
      <div class=" col-span-2 flex justify-center items-center space-x-1">
        <div><img :src="log.img" class="w-10"></div>
        <div class="text-center text-xs">{{ log.name }}</div>
      </div>
      <div>{{ log.price }}</div>
      <div v-if="log.getSilver">{{ log.getSilver }}</div>
      <div v-else>-</div>
      <div>{{ formatTime(log.createdAt) }}</div>
    </div>
    
    <!-- Load More Button -->
    <div class="flex justify-center mt-4">
      <button v-if="hasMore" @click="loadMoreLogs" class="bg-indigo-500 hover:bg-indigo-700 w-full duration-500 text-white px-4 py-2 rounded">Load More</button>
    </div>
  </div>
</template>

<script>
import getUser from '@/composables/getUser';
import { projectFirestore } from '@/firebase/config';
import { ref } from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import relativeTime from 'dayjs/plugin/relativeTime';

export default {
  setup() {
    dayjs.extend(relativeTime);
    dayjs.locale('th'); // Set locale to Thai
    
    const { user } = getUser();
    const logs = ref([]);
    const lastVisible = ref(null); // Keeps track of the last document for pagination
    const hasMore = ref(true); // Keeps track if there are more logs to load

    const loadInitialLogs = () => {
      projectFirestore.collection('logs')
        .where('ownerUid', '==', user.value.uid)
        .where('type', '==', 'marketplace')
        .orderBy('createdAt', 'desc')
        .limit(10) // Limit to 10 logs per request
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            hasMore.value = false; // No more logs
            return;
          }

          lastVisible.value = snapshot.docs[snapshot.docs.length - 1]; // Track the last document

          snapshot.forEach(logDoc => {
            logs.value.push({ id: logDoc.id, ...logDoc.data() });
          });
        });
    };

    const loadMoreLogs = () => {
      if (!lastVisible.value) return;

      projectFirestore.collection('logs')
        .where('ownerUid', '==', user.value.uid)
        .where('type', '==', 'marketplace')
        .orderBy('createdAt', 'desc')
        .startAfter(lastVisible.value) // Start after the last loaded document
        .limit(10) // Load next 10 logs
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            hasMore.value = false; // No more logs to load
            return;
          }

          lastVisible.value = snapshot.docs[snapshot.docs.length - 1]; // Update last document

          snapshot.forEach(logDoc => {
            logs.value.push({ id: logDoc.id, ...logDoc.data() });
          });
        });
    };

    // Format timestamp function
    const formatTime = (timestamp) => {
      if (timestamp) {
        return dayjs(timestamp.toDate()).fromNow(); // Convert Firestore timestamp to Date
      }
      return '-';
    };

    loadInitialLogs(); // Load initial logs when the component is mounted

    return { formatTime, logs, loadMoreLogs, hasMore };
  },
};
</script>

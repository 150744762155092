import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'


const firebaseConfig = {
    apiKey: "AIzaSyDXQkm653rdbxsRrjKvaNNkPka3imwwheo",
    authDomain: "workerkey.firebaseapp.com",
    projectId: "workerkey",
    storageBucket: "workerkey.appspot.com",
    messagingSenderId: "891908181390",
    appId: "1:891908181390:web:05cde6b915e04693c2a8df"
  };
// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()
const projectFunctions = firebase.app().functions('asia-southeast1');

// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp

const FieldValue = firebase.firestore.FieldValue

export {FieldValue,projectFirestore, projectAuth, projectStorage, timestamp,projectFunctions }


<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-full pb-16 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-2xl">
        <div class="bg-gray-800 text-white p-3">
          <div class="grid grid-cols-1 h-64 overflow-auto" id="style-1">
            <div v-for="skill in skills" :key="skill">
              <div @click="skillSelected = skill" :class="[skillSelected.name === skill.name ? 'bg-gray-700' : 'bg-gray-900 hover:bg-gray-700']" class="flex items-center space-x-1 justify-between p-1 border-b border-gray-700 duration-300 cursor-pointer">
                <div class="flex items-center space-x-1 font-bold text-gray-100">
                  <div class=""><img :src="skill.img"  class="w-7 sm:w-10"></div>
                  <div>
                    <div class="flex items-center space-x-1 text-xs sm:text-sm">
                      <div :class="skill.textColor" >ทักษะ{{ skill.name }}</div>
                      <div v-if="!workerSkill.includes(skill.name)" class="text-red-500">(ไม่ได้ฝึกฝน)</div>
                      <div v-if="workerSkill.includes(skill.name)" class="text-green-700">(ฝึกฝนสำเร็จ)</div>
                    </div>
                    <div class="flex items-center space-x-1 text-xs sm:text-sm">
                      <div v-if="skill.health >= 1" class="flex items-center space-x-1">
                        <div class="font-normal">เพิ่มเลือด {{ skill.health }}</div>
                      </div>
                      <div v-if="skill.attack >= 1"  class="flex items-center space-x-1">
                        <div class="font-normal">เพิ่มพลังโจมตี {{ skill.attack }}</div>
                      </div>
                      <div v-if="skill.defend >= 1" class="flex items-center space-x-1">
                        <div class="font-normal">เพิ่มพลังป้องกัน {{ skill.defend }}</div>
                      </div>
                      <div v-if="skill.accuracy >= 1" class="flex items-center space-x-1">
                        <div class="font-normal">เพิ่มความแม่นยำ {{ skill.accuracy }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center text-gray-100 space-x-1 text-xs sm:text-sm">
                  <div class="text-xs sm:text-base">{{ skill.price.toLocaleString() }}</div>
                  <div><img src="../../assets/images/coin.png" class="w-3 sm:w-4"></div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="report" @click="report = ''" :class="[report === 'เรียนทักษะสำเร็จ' ? 'text-green-500' : 'text-red-500']" class="text-center text-sm">{{ report }}</div>
          <div class="mt-1 space-y-1">
            <button v-if="!isPending && !skillSelected.name" type="button" class="w-full justify-center rounded bg-red-900 px-3 py-2 text-sm font-semibold text-white shadow-sm  duration-300 cursor-not-allowed">กรุณาเลือกสกิล</button>
            <button v-if="!isPending && skillSelected.name" @click="handleLearnSkill" type="button" class="w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">เรียนรู้ทักษะ</button>
            <button v-if="isPending" type="button" class=" w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm     duration-300 cursor-not-allowed">Loading...</button>
            <button v-if="!isPending" @click="cancelModal" type="button" class=" w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
        </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
import { projectFirestore, projectFunctions } from '@/firebase/config'
  export default {
    props: ['id'],
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}
        const worker = ref({})
        const workerSkill = ref([])
        projectFirestore.collection('workers').doc(props.id).onSnapshot(workerDoc =>{
          worker.value = workerDoc.data()
          workerSkill.value = workerDoc.data().skills
        })
        const isPending = ref(false)
        const skills = ref([
          {name: 'หมัดเบา', price: 3000, health: 0,attack: 5, defend: 0, accuracy: 0, textColor: 'text-gray-100', img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Foldbook.png?alt=media&token=b692b2ca-ff45-4b60-8580-f10c02a7b36b'},
          {name: 'เตะเบา', price: 6000, health: 0,attack: 5, defend: 0, accuracy: 0, textColor: 'text-amber-500', img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Foldbook.png?alt=media&token=b692b2ca-ff45-4b60-8580-f10c02a7b36b'},
          {name: 'หมัดแย็บ', price: 9000, health: 0,attack: 5, defend: 0, accuracy: 0, textColor: 'text-lime-500',img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Foldbook.png?alt=media&token=b692b2ca-ff45-4b60-8580-f10c02a7b36b'},
          {name: 'เตะรัว', price: 12000, health: 0,attack: 5, defend: 0, accuracy: 0, textColor: 'text-green-500',img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Foldbook.png?alt=media&token=b692b2ca-ff45-4b60-8580-f10c02a7b36b'},
          {name: 'อัปเปอร์คัต', price: 15000, health: 0,attack: 5, defend: 0, accuracy: 0, textColor: 'text-cyan-500',img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Foldbook.png?alt=media&token=b692b2ca-ff45-4b60-8580-f10c02a7b36b'},
          {name: 'กระโดดถีบ', price: 18000, health: 0,attack: 5, defend: 0, accuracy: 0, textColor: 'text-sky-500',img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Foldbook.png?alt=media&token=b692b2ca-ff45-4b60-8580-f10c02a7b36b'},
          {name: 'ทำลายจุดอ่อน', price: 21000, health: 0,attack: 5, defend: 0, accuracy: 0, textColor: 'text-blue-500',img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Foldbook.png?alt=media&token=b692b2ca-ff45-4b60-8580-f10c02a7b36b'},
          {name: 'เพชฌฆาต', price: 24000, health: 0,attack: 5, defend: 0, accuracy: 0, textColor: 'text-indigo-500',img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Foldbook.png?alt=media&token=b692b2ca-ff45-4b60-8580-f10c02a7b36b'},          
        ])

        const skillSelected = ref({name: ''})
        const report = ref('')
        const handleLearnSkill = e =>{
          const learnSkill = projectFunctions.httpsCallable('learnSkill')
          isPending.value = true
          learnSkill({workerId: props.id,skillSelected: skillSelected.value}).then(result =>{
            isPending.value = false
            report.value = result.data
          }).catch(err =>{
            isPending.value = false
          })
        }
        

        return { report,handleLearnSkill,workerSkill,worker,cancelModal,skills,skillSelected,isPending }
    },
  }
  </script>
<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex h-full pb-16 items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
            <div class="bg-gray-800 text-white p-3">
              <ModalOpenWorkerBoxWithSilverPending v-if="isPending" />
              <div v-if="!isPending" class="grid grid-cols-3 gap-1">
                <div v-for="worker in workers" :key="worker.name" :class="['rounded', {'bg-yellow-900': worker.name === report, 'bg-gray-900': worker.name !== report}]">
                  <div class="flex justify-center"><img :src="worker.img" class="w-12"></div>
                  <div class="flex justify-center items-center space-x-1">
                    <div><img src="../../assets/images/star.png" class="w-2.5"></div>
                    <div class="font-bold text-xs">{{ worker.star }}</div>
                  </div>
                  <div :class="worker.textColor" class="text-center text-xs sm:text-sm">{{ worker.name }}</div>
                  <div class="flex items-center space-x-1 justify-center ">
                    <div class="flex justify-center items-center space-x-1">
                    <div class="text-xs">{{ worker.health }}</div>
                    <div><img src="../../assets/images/hearth.png" class="w-2.5"></div>
                  </div>
                  <div class="flex justify-center items-center space-x-1">
                    <div class="text-xs">{{ worker.attack }}</div>
                    <div><img src="../../assets/images/sword.png" class="w-2.5"></div>
                  </div>
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <div @click="report = ''" class="text-center text-lg font-bold text-yellow-500 animate-bounce">{{ report }}</div>
                <button v-if="!isPending" @click="handleCreateWorker()" type="submit" class="w-full flex justify-center items-center space-x-1 rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">
                    <div>เปิดกล่อง</div>
                    <div>10,000</div>
                    <div><img src="../../assets/images/coin.png" class="w-4 sm:w-5"></div>
                </button>
                  <button v-if="isPending" type="button" class="mt-1.5 w-full flex justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm animate-pulse duration-300 cursor-not-allowed">LOADING...</button>
                  <button v-if="!isPending" @click="cancelModal" type="button" class="mt-1.5 w-full flex justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-white duration-300">ยกเลิก</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { ref } from 'vue'
  import { projectFirestore, projectFunctions } from '@/firebase/config'
  import ModalOpenWorkerBoxWithSilverPending from './OpenWorkerBoxWithSilverPending.vue'
  export default {
      components: { ModalOpenWorkerBoxWithSilverPending },
      setup(props,context) {
          const cancelModal = e =>{context.emit('cancelModal')}
          const workers = ref([
          {name: 'ปีเตอร์',health: 100,star: 1, attack: 10, defend: 0, accuracy: 0,textColor: 'text-white', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fpeter.png?alt=media&token=58cd2ce7-ac69-4da0-96e9-82970737f3a2'},
            {name: 'ปีแยร์',health: 100,star: 2, attack: 11, defend: 0, accuracy: 0,textColor: 'text-amber-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fpierre.png?alt=media&token=b9f093b8-72e7-4a23-8f5d-41bb82d8f60e'},
            {name: 'มาร์ค',health: 100,star: 3, attack: 12, defend: 0, accuracy: 0,textColor: 'text-lime-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fmark.png?alt=media&token=2d684ac2-b85d-4ea0-9e88-a272117daf7f'},
            {name: 'นาธาน',health: 100,star: 4, attack:13, defend: 0, accuracy: 0,textColor: 'text-green-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fnathan.png?alt=media&token=1fe18fb4-43df-4bcb-b2d3-8d7fca18897e'},
            {name: 'จวน',health: 100,star: 5, attack: 14, defend: 0, accuracy: 0,textColor: 'text-cyan-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fjuan.png?alt=media&token=9222f167-03de-4d50-8ef6-fbaaafde7c83'},
            {name: 'ดาโกต้า',health: 100,star: 6, attack: 15, defend: 0, accuracy: 0,textColor: 'text-sky-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fdakota.png?alt=media&token=21a37af6-2cb6-4bdc-9668-9ae44a20d680'},
            {name: 'โคฟี่',health: 100,star: 7, attack: 16, defend: 0, accuracy: 0,textColor: 'text-blue-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fkofi.png?alt=media&token=fef78eb4-f520-4c0f-bc55-d8a2231abf17'},
            {name: 'หว่อง',health: 100,star: 8, attack: 17, defend: 0, accuracy: 0,textColor: 'text-indigo-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fwong.png?alt=media&token=6aa6e3b2-c61b-46f1-9aa4-e8e181425e22'},
            {name: 'แคทเธอรีน',health: 100,star: 9, attack: 18, defend: 0,accuracy: 0,textColor: 'text-violet-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fkatherine.png?alt=media&token=a05ca758-04e1-40a0-9814-27a8e507d841'},
            {name: 'ฮิวส์ตัน',health: 100,star: 10, attack: 19, defend: 0, accuracy: 0,textColor: 'text-pink-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fhouston.png?alt=media&token=4bcf5ded-6b02-4e65-8f6f-72325884d1ad'},
            {name: 'สมิธ',health: 100,star: 11, attack: 20, defend: 0, accuracy: 0,textColor: 'text-red-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fsmith.gif?alt=media&token=04ab543c-cb2c-4d59-8952-7c64316d6328'},
          ])
          
          const key = ref('')
          const isPending = ref(false)
          const report = ref('')
          const handleCreateWorker = e =>{
            const createWorker = projectFunctions.httpsCallable('openWOrkerBoxWithSilver')
            isPending.value = true
            setTimeout(() => {
              createWorker({password: key.value}).then(result =>{
                isPending.value = false
                report.value = result.data
                key.value = ''
              }).catch((err) =>{
                isPending.value = false
              })
            }, 500);
          }
  
  
          return { report,handleCreateWorker,cancelModal,workers,isPending,key }
      },
  }
  </script>
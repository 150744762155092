<template>
  <div @dblclick="removeWorkerItem" :class="[isPending ? 'animate-pulse bg-green-500' : ' bg-green-900 hover:bg-green-700 duration-300 cursor-pointer']" class="block  p-0 sm:p-2 items-center space-x-1 justify-center rounded">
    <div class="flex justify-center"><img :src="item.img" class="w-6 sm:w-7"></div>
    <div class="flex items-center space-x-1 justify-center">
      <div class="flex items-center space-x-1 rounded  border border-gray-400 px-1">
        <div><img src="../../assets/images/star.png" class="w-2.5"></div>
        <div class="text-xs">{{ item.star }}</div>
      </div>
      <div :class="item.textColor" class="text-xs sm:text-xs text-center">{{ item.name }}</div>
    </div>
    <div class="text-xs sm:text-sm text-center" v-if="item.level >= 1">(+{{ item.level }})</div>
  </div>
</template>
<script>
import { projectFirestore, projectFunctions } from '@/firebase/config'
import { ref } from 'vue'
export default {
    props:['itemId','id'],
    setup(props) {
      const item = ref({})
      projectFirestore.collection('items').doc(props.itemId).onSnapshot(itemDoc =>{
        item.value = itemDoc.data()
      })
      
      const isPending = ref(false)
      const report = ref('')
      const removeWorkerItem = e =>{
        const removeWorkerItem = projectFunctions.httpsCallable('removeWorkerItem')
        isPending.value = true
        removeWorkerItem({itemId: props.itemId, workerId: props.id}).then(result =>{
          isPending.value = false
          report.value = result.data
        }).catch(err =>{
          isPending.value = false
        })

      }

      return { isPending, item,removeWorkerItem }
    },
}
</script>
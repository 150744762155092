<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-4/5 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div v-if="logsLegnth >=1" :class="[logsLegnth >= 3 ? ' h-48' :'h-auto']" class="space-y-1  overflow-auto" id="style-1">
            <div v-for="log in logs" :key="log">
              <div class=" bg-gray-900 p-1 rounded ">
                <div class="flex justify-between items-center">
                  <div>
                    <div class="flex justify-center"><img :src="log.workerImg" class="w-12"></div>
                    <div class="text-center text-xs">{{log.workerHealthRemain}}/{{log.workerHealth}}</div>
                  </div>
                  <div class=" ">
                    <div class="text-xs sm:text-base font-bold">{{log.message}}</div>
                    <div class="text-xs text-center">{{ dayjs(log.createdAt.toDate()).locale('th').fromNow() }}</div>
                  </div>
                  <div>
                    <div><img :src="log.thiefImg" class="w-12"></div>
                    
                    <div class="text-center text-xs">{{log.thiefHealthRemain}}/{{log.thiefHealth}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="logsLegnth < 1" class="h-20 flex justify-center items-center border rounded border-gray-700">
            <div class="text-xl font-bold opacity-50">ยังไม่มีประวัติ</div>
          </div>
          <div class="mt-1.5">
            <button @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
         </div>
        </div>
      </div>
    </div>
  </div>
</div>

</template>
<script>
import { ref } from 'vue'
import { projectFirestore } from '@/firebase/config'
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import relativeTime from 'dayjs/plugin/relativeTime';
import getUser from '@/composables/getUser';
export default {
  props: ['thiefId'],
    setup(props,context) {
        dayjs.extend(relativeTime)
        const { user } = getUser()
        const cancelModal = e =>{context.emit('cancelModal')}
        const logs = ref([])
        const logsLegnth = ref(1)
        
        console.log(props.thiefId)

        projectFirestore.collection('logs').where('type','==','thief').where('thiefId','==',props.thiefId).orderBy('createdAt','desc').get().then(snapshot =>{
          snapshot.forEach(logDoc =>{
            logs.value.push(logDoc.data())
          })
          logsLegnth.value =  logs.value.length
        })
        
        return { user,dayjs,cancelModal,logs,logsLegnth }
    },
}
</script>
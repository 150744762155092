<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex  h-full pb-16 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div>
            <div class="grid grid-cols-1 gap-1">
              <div v-for="product in products" :key="product">
                <div @click="handleSelectProduct(product.name)" :class="[productSelected === product.name ? 'bg-indigo-900' : 'bg-gray-900']" class="p-1 flex items-center justify-between bg-gray-900 hover:bg-indigo-900 duration-500 rounded cursor-pointer">
                  <div class="flex items-center space-x-1 ">
                    <div><img :src="product.img" class="w-10"></div>
                    
                    <div class="text-sm">
                        <div class="flex items-center space-x-1">
                            <div class="flex items-center rounded border-gray-700 px-1 border space-x-1">
                            <div><img src="../../assets/images/star.png" class="w-2 sm:w-3"></div>
                            <div class="text-xs font-bold">{{ product.star }}</div>
                        </div>
                            <div class="text-sm font-bold">{{ product.name }}</div>
                            
                        </div>
                        <div class="flex items-center space-x-1">
                            <div>ราคา</div>
                            <div>{{ product.price }}</div>
                            <div><img src="../../assets/images/coin.png" class="w-3"></div>
                        </div>
                    </div>
                  </div>
                  <div class="flex items-center space-x-1">
                    <div v-if="product.health >= 1" class="flex items-center space-x-1">
                      <div class="font-bold">{{ product.health }}</div>
                      <div><img src="../../assets/images/hearth.png" class="w-5"></div>
                    </div>
                    <div v-if="product.attack >= 1" class="flex items-center space-x-1">
                      <div class="font-bold">{{ product.attack }}</div>
                      <div><img src="../../assets/images/sword.png" class="w-5"></div>
                    </div>
                    <div v-if="product.defend >= 1" class="flex items-center space-x-1">
                      <div class="font-bold">{{ product.defend }}</div>
                      <div><img src="../../assets/images/shield.png" class="w-5"></div>
                    </div>
                    <div v-if="product.accuracy >= 1" class="flex items-center space-x-1">
                      <div class="font-bold">{{ product.accuracy }}</div>
                      <div><img src="../../assets/images/target.png" class="w-5"></div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <div class="text-sm text-center" @click="report = ''" :class="[report === 'ซื้อสำเร็จ' ? 'text-green-500' : 'text-red-500']">{{ report }}</div>
          </div>
          <div class="mt-2">
            <button v-if="!productSelected && !isPending" type="button" class="w-full justify-center rounded bg-red-900 px-3 py-2 text-sm font-semibold text-white shadow-sm  cursor-not-allowed">กรุณาเลือกสินค้า</button>
          <button v-if="productSelected && !isPending" @click="handleBuyProduct()" type="button" class="w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">ซื้อ{{ productSelected }}</button>
          <div v-if="isPending" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm    text-center ">Loading...</div>
          <button v-if="!isPending" @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
        </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
import { projectFunctions } from '@/firebase/config'
  export default {
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}

        const products = ref([
          {name:'โกลเด้นรีทรีฟเวอร์',price: 50, health: 100, attack: 0, defend: 0, accuracy: 0,star: 0, textColor: 'text-white', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fgoldenretriever.png?alt=media&token=65cf0cbe-5def-44a4-89f3-29c9a89a0a61'},
          {name:'เข็มขัดนักล่า',price: 200, health: 30, attack: 0, defend: 0, accuracy: 0,star: 1, textColor: 'text-white', type:'waist', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fbelt.png?alt=media&token=da413dc1-21e8-4cb9-8d6f-24621e0997d2'},
          {name:'ถุงมือนักล่า',price: 300, health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, textColor: 'text-white', type:'glove', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fgloves.png?alt=media&token=a8f5dbcd-cf68-4f63-82b2-df962d311d1b'},
          {name:'แหวนนักล่า',price: 300, health: 0, attack: 0, defend: 0, accuracy: 1,star: 1, textColor: 'text-white', type:'ring', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fring.png?alt=media&token=08f298a6-fe77-49f2-9e7b-513a458070f7'},
          {name:'เกาะซองอาวุธ',price: 500, health: 0, attack: 0, defend: 1, accuracy: 0,star: 1, textColor: 'text-white', type:'armor', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fholster.png?alt=media&token=b537af7a-1fa2-4d0a-96df-385e51d3939a'},
          
        ])

        const productSelected = ref('')
        const report = ref('')
        const isPending = ref(false)
        const handleSelectProduct = product =>{
            productSelected.value = product
        }

        const handleBuyProduct = e =>{
            const buyItem = projectFunctions.httpsCallable('buyItem')
            isPending.value = true
            buyItem({itemSelected: productSelected.value}).then(reuslt =>{
                isPending.value = false
              report.value = reuslt.data
            }).catch(err =>{
                isPending.value = false
                report.value = err
            })
        }

        return { isPending,report,cancelModal,products,handleSelectProduct,productSelected,handleBuyProduct }
    },
  }
  </script>
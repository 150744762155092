<template>
    <ModalWorker v-if="toggleModal === 'ModalProfile'" :workerId="workerId" @cancelModal="toggleModal =''" />
    <div class="p-1 sm:p-4 pb-24 mb-auto h-full text-white  overflow-auto" id="style-1">
      <div class="space-y-2">
        <div class="p-3 bg-gray-900 rounded-lg flex items-center justify-center space-x-1 uppercase">
            <div><img :src="userDoc.profile" class="w-10 sm:w-12"></div>
            <div class="font-bold">{{ userDoc.name }}</div>
        </div>
        <div v-if="userDoc.instagram || userDoc.facebook" class=" grid grid-cols-2 gap-1">
            <div v-if="userDoc.instagram" class="bg-gray-900 rounded-lg p-3 flex justify-center items-center space-x-1 ">
                <div><img src="../assets/images/instgram.png" class="w-5"></div>
                <div class="font-bold">{{ userDoc.instagram }}</div>
            </div>
            <div v-if="userDoc.facebook" class="bg-gray-900 rounded-lg p-3 flex justify-center items-center space-x-1">
                <div><img src="../assets/images/facebook.png" class="w-5"></div>
                <div class="font-bold">{{ userDoc.facebook }}</div>
            </div>
        </div>
        <div v-if="userDoc.biography" class="p-3 bg-gray-900 rounded-lg flex items-center justify-center space-x-1 uppercase">
            <div class="">{{ userDoc.biography }}</div>
        </div>
        <div class="space-y-1">
            <div v-if="workerLength >= 1" :class="[workerLength > 4 ?  ' h-64 overflow-auto' : 'h-auto']"  class="grid grid-cols-1  gap-1 bg-gray-900 rounded-lg p-1" id="style-1">
            <div class=" text-gray-300 text-center text-base sm:text-lg">รายชื่อคนงาน</div>
            <div v-for="worker in workers" :key="worker">
                <div @click="handleModal('ModalProfile', worker.id)" :class="[worker.work ? 'bg-red-500' : '']" class="flex items-center justify-between space-x-2 py-1 hover:bg-indigo-900 rounded-lg duration-300 cursor-pointer border-gray-800 border px-1">
                <div class="flex items-center  space-x-2 text-xs sm:text-sm">
                    <div class="flex justify-center"><img :src="worker.img" class="w-8 sm:w-10"></div>
                    <div class="flex justify-center space-x-1 font-bold items-center text-xs border border-gray-400  rounded p-0.5 w-7 sm:w-10">
                        <div><img src="../assets/images//star.png" class="w-2 sm:w-3"></div>
                        <div>{{ worker.star }}</div>
                    </div>
                    <div :class="worker.textColor">{{ worker.name }}</div>
                    <div v-if="worker.vip.toDate() >= new Date() && admin"><img src="../assets/images/vip.png" class="w-4 sm:w-5"></div>
                    </div>
                    <div class="flex justify-center  text-xs sm:text-base space-x-1 items-center">
                        <div v-if="worker.health + worker.itemHealth + worker.skillHealth + worker.collectionHealth >= 1" class="flex space-x-1 bg-pink-800 p-0.5 px-2 w-15 rounded items-center">
                <div><img src="../assets/images/hearth.png" class="w-3 sm:w-4"></div>
                <div>{{ worker.health + worker.itemHealth + worker.skillHealth + worker.collectionHealth }}</div>
                </div>
            <div v-if="worker.attack + worker.itemAttack + worker.skillAttack + worker.collectionAttack >= 1" class="flex space-x-1 bg-red-800 p-0.5 px-2 rounded w-15 items-center">
                <div><img src="../assets/images/sword.png" class="w-3 sm:w-4"></div>
                <div>{{ worker.attack + worker.itemAttack + worker.skillAttack + worker.collectionAttack }}</div>
                </div>
                <div v-if="worker.defend + worker.itemDefend + worker.skillDefend + worker.collectionDefend >= 1" class="flex space-x-1 bg-blue-800 p-0.5 px-2 w-15 rounded items-center">
                <div><img src="../assets/images/shield.png" class="w-3 sm:w-4"></div>
                <div>{{ worker.defend + worker.itemDefend + worker.skillDefend + worker.collectionDefend }}</div>
                </div>
                <div v-if="worker.accuracy + worker.itemAccuracy + worker.skillAccuracy + worker.collectionAccuracy >= 1" class="flex space-x-1 bg-amber-900 p-0.5 w-15 px-2 rounded items-center">
                <div><img src="../assets/images/target.png" class="w-3 sm:w-4"></div>
                <div>{{ worker.accuracy + worker.itemAccuracy + worker.skillAccuracy + worker.collectionAccuracy }}</div>
                </div>
            </div>
                </div>
            </div>
            </div>
            <div v-if="workerLength < 1" class="bg-gray-900 h-14 rounded flex justify-center items-center">
            <div class="text-xl font-bold opacity-50">ไม่พบคนงาน</div>
            </div>
        </div>
        <div class="space-y-1">
            <div v-if="itemLength >= 1" :class="[items.length > 4 ?  ' h-64 overflow-auto' : 'h-auto']"  class="grid grid-cols-1  gap-1 bg-gray-900 rounded-lg p-1" id="style-1">
  <div class=" text-gray-300 text-center text-base sm:text-lg">ไอเทม</div>
  <div v-for="item in items" :key="item.id">
    <div :class="[item.work ? 'bg-red-500' : '']" class="flex items-center justify-between space-x-2 py-1 hover:bg-indigo-900 rounded-lg duration-300 cursor-pointer border-gray-800 border px-1">
      <div class="flex items-center space-x-2 text-xs sm:text-sm">
        <div class="flex justify-center"><img :src="item.img" class="w-8 sm:w-10"></div>
        <div class="flex justify-center space-x-1 font-bold items-center text-xs border border-gray-400 rounded p-0.5 w-7 sm:w-10">
          <div><img src="../assets/images/star.png" class="w-2 sm:w-3"></div>
          <div>{{ item.star }}</div>
        </div>
        <div :class="item.textColor" class="">{{ item.name }}</div>
      </div>
      <div class="flex justify-center text-xs sm:text-base sm:font-bold space-x-1 items-center">
        <div v-if="item.health >= 1" class="flex space-x-1 bg-pink-800 p-0.5 px-2 w-15 rounded items-center">
          <div><img src="../assets/images/hearth.png" class="w-3 sm:w-4"></div>
          <div>{{ item.health  }}</div>
        </div>
        <div v-if="item.attack >= 1" class="flex space-x-1 bg-red-800 p-0.5 px-2 w-15 rounded items-center">
          <div><img src="../assets/images/sword.png" class="w-3 sm:w-4"></div>
          <div>{{ item.attack  }}</div>
        </div>
        <div v-if="item.defend >= 1" class="flex space-x-1 bg-blue-800 p-0.5 px-2 w-15 rounded items-center">
          <div><img src="../assets/images/shield.png" class="w-3 sm:w-4"></div>
          <div>{{ item.defend }}</div>
        </div>
        <div v-if="item.accuracy >=1" class="flex space-x-1 bg-amber-900 p-0.5 w-15 px-2 rounded items-center">
          <div><img src="../assets/images/target.png" class="w-3 sm:w-4"></div>
          <div>{{ item.accuracy  }}</div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Add the Load More button -->
  <div class="text-center mt-2">
    <button v-if="lastVisibleItem && !loadingMore" @click="loadMoreItems" class="bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded text-sm w-full">Load More</button>
    <div v-if="loadingMore" class="text-gray-300">Loading...</div>
  </div>
</div>

            <div v-if="itemLength < 1" class="bg-gray-900 h-14 rounded flex justify-center items-center">
            <div class="text-xl font-bold opacity-50">ไม่พบไอเทม</div>
            </div>
        </div>
      </div>
    </div>
  </template>
  <script>
import { ref } from 'vue'
import { projectFirestore } from '@/firebase/config'
import ModalWorker from '../components/ModalWorker.vue'
import getUser from '@/composables/getUser'

export default {
  props: ['id'],
  components: { ModalWorker },
  setup(props) {
    const { admin } = getUser()
    const userDoc = ref({})
    const workers = ref([])
    const workerLength = ref(1)

    const items = ref([])
    const itemLength = ref(1)
    const lastVisibleItem = ref(null) // To keep track of the last item
    const loadingMore = ref(false) // To track loading state

    projectFirestore.collection('users').doc(props.id).get().then(snapshot => {
      userDoc.value = snapshot.data()

      projectFirestore.collection('workers')
        .where('ownerUid', '==', userDoc.value.uid)
        .orderBy('work', 'desc')
        .orderBy('star', 'desc')
        .get().then(snapshot => {
          workerLength.value = snapshot.size
          snapshot.forEach(workerDoc => {
            workers.value.push(workerDoc.data())
          })
        })

      // Fetch the first batch of items
      loadItems()
    })

    // Function to load items with pagination
    const loadItems = async () => {
  try {
    let query = projectFirestore.collection('items')
      .where('ownerUid', '==', userDoc.value.uid)
      .orderBy('star', 'desc')
      .orderBy('type')
      .limit(5) // Limit number of items fetched per request

    if (lastVisibleItem.value) {
      // If there is a last visible item, start after it for the next query
      query = query.startAfter(lastVisibleItem.value)
    }

    loadingMore.value = true

    const snapshot = await query.get()

    if (!snapshot.empty) {
      // Update lastVisibleItem to the last document fetched
      lastVisibleItem.value = snapshot.docs[snapshot.docs.length - 1]

      // Append new items to the existing items array
      snapshot.forEach(itemDoc => {
        items.value.push({ id: itemDoc.id, ...itemDoc.data() })
      })
    } else {
      // No more items to load, disable the load more button
      lastVisibleItem.value = null
    }

    loadingMore.value = false
  } catch (error) {
    console.error("Error fetching items: ", error)
    loadingMore.value = false
  }
}


    // Function to load more items when clicking the button
    const loadMoreItems = () => {
      loadItems()
    }

    const workerId = ref('')
    const toggleModal = ref('')
    const handleModal = (modal, id) => {
      toggleModal.value = modal
      workerId.value = id
    }

    return {
      admin, handleModal, toggleModal, workerId, userDoc,
      workers, workerLength, itemLength, items, loadMoreItems,
      lastVisibleItem, loadingMore
    }
  },
}

  </script>
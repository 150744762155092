<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex h-4/5 items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg rounded-lg">
            <div class="flex justify-center bg-gray-900 text-white p-4">
              <div class="flex items-center">
                <img :src="item.img" alt="item image" class="w-16 h-16 mr-4">
                <div>
                  <div :class="item.textColor + ' text-lg font-bold text-center'">{{ item.name }}</div>
                  <div class="flex items-center">
                    <span v-for="star in item.star" :key="star" class="text-yellow-400 text-xl">★</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gray-800 border-t border-gray-700 p-4 flex justify-center items-center space-x-1  text-white text-xl font-bold">
                <div class="p-3 px-5 flex items-center space-x-1 bg-pink-800 rounded">
                      <div>{{ item.health }}</div>
                      <img src="../../assets/images/hearth.png" class="w-6">
                    </div>
                    <div class="p-3 px-5 flex items-center space-x-1 bg-red-800 rounded ">
                      <div>{{ item.attack }}</div>
                      <img src="../../assets/images/sword.png" class="w-6">
                    </div>
                    <div class="p-3 px-5 flex items-center space-x-1 bg-blue-800 rounded">
                      <div>{{ item.defend }}</div>
                      <img src="../../assets/images/shield.png" class="w-6">
                    </div>
                    <div class="p-3 px-5 flex items-center space-x-1 bg-amber-900 rounded">
                      <div>{{ item.accuracy }}</div>
                      <img src="../../assets/images/target.png" class="w-6">
                    </div>
                  </div>
            <div class="bg-gray-800 text-white p-4">
              <button @click="cancelModal" type="button" class="w-full rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-white duration-300">ยกเลิก</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  
  export default {
    props: ['item'],
    setup(props, context) {
      const cancelModal = e => {
        context.emit('cancelModal')
      }
      return { cancelModal }
    },
  }
  </script>
  
  <style scoped>
  /* Add any custom styles here */
  </style>
  
<template>
  <ModalCreateWorker v-if="toggleModal === 'ModalCreateWorker'" @cancelModal="toggleModal = ''" />
  <ModalFreeWorker v-if=" toggleModal === 'ModalFreeWorker'" @cancelModal="toggleModal = ''" />
  <ModalGetFreeItem   v-if=" toggleModal === 'ModalGetFreeItem'" @cancelModal="toggleModal = ''"/>
  <div class="p-1 sm:px-4 sm:py-2  mb-auto h-full text-white overflow-auto" id="style-1">
    <div class="text-sm sm:text-base font-bold text-center p-1 bg-gray-900 border-b border-gray-700">จำนวนคนงานที่สามารถทำงานได้ {{ amountOfWorkerWorking }} / 10</div>
    <div class="space-y-2 pb-16">
      <div v-if="!getFreeWorker" @click="toggleModal = 'ModalFreeWorker'" class="flex items-center space-x-1 justify-center bg-lime-800 hover:bg-lime-700 p-2 text-center rounded cursor-pointer duration-500">
        <div><img src="../assets/worker/peter.png" class="w-14"></div>
        <div class="text-xl font-bold">รับฟรีคนงานปีเตอร์ฝึกหัด</div>
      </div>
      <div v-if="!getFreeItem" @click="toggleModal = 'ModalGetFreeItem'" class="flex items-center space-x-1 justify-center bg-lime-800 hover:bg-lime-700 p-2 text-center rounded cursor-pointer duration-500">
        <div><img src="../assets/images/body.png" class="w-14"></div>
        <div class="text-xl font-bold">รับไอเทมฟรีสำหรับมือใหม่</div>
      </div>
      <div class="grid grid-cols-2 text-center text-xs sm:text-sm rounded">
        <div @click="filterIsLive = true; filterOrder = { column: 'star', direction: 'desc' }" class="p-1 bg-gray-900 hover:bg-indigo-900 duration-300 cursor-pointer">มีชีวิต</div>
        <div @click="filterIsLive = false; filterOrder = { column: 'updated', direction: 'desc' }" class="p-1 bg-gray-900 hover:bg-indigo-900 duration-300 cursor-pointer">เสียชีวิต</div>
      </div>
      <div v-if="length < 1" class="h-20 flex justify-center border-4 border-opacity-50 border-dashed items-center">
        <div class="text-2xl font-bold opacity-50">ไม่พบคนงาน</div>
      </div>
      <div class="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 2xl:grid-cols-10 gap-1">
        <div v-for="worker in workers" :key="worker.id">
          <router-link :to="{ name: 'WorkerDetail', params: { id: worker.id }}">
            <div class="p-4 bg-gray-900 hover:bg-gray-700 rounded-lg duration-300 cursor-pointer shadow-md">
              <div v-if="worker.isLive">
                <div v-if="!worker.work" class="text-red-500 font-bold text-center text-xs">ไม่ได้ทำงาน</div>
                <div v-if="worker.work" class="text-green-500 font-bold text-center text-xs">ทำงานที่{{ worker.position }}</div>
              </div>
              <div v-if="!worker.isLive" class="text-red-500 font-bold text-center text-xs">
                <div>เสียชีวิต</div>
                <div>{{ dayjs(worker.updated.toDate()).locale('th').fromNow() }}</div>
              </div>

              <div class="flex items-center justify-center my-2">
                <img :src="worker.img" alt="" class="rounded-full border-2 border-gray-700">
              </div>

              <div class="flex justify-center my-2">
                <div class="text-center">
                  <div class="flex items-center justify-center space-x-1 border px-2 py-1 rounded border-gray-600">
                    <img src="../assets/images/star.png" class="w-4">
                    <div class="font-bold text-white">{{worker.star}}</div>
                  </div>
                </div>
              </div>

              <div class="flex items-center space-x-1 justify-center text-center mb-1">
                <div v-if="worker.vip.toDate() >= new Date()"><img src="../assets/images/vip.png" class="w-5"></div>
                <div :class="worker.textColor" class="text-sm sm:text-base md:text-lg font-bold">{{ worker.name }}</div>
              </div>

              <div class="flex justify-center items-center space-x-1 mb-1 text-white text-xs sm:text-sm font-bold">
                <div class="p-0.5 flex items-center space-x-1 bg-red-800 rounded">
                  <div>{{ worker.attack + worker.itemAttack + worker.skillAttack + worker.collectionAttack }}</div>
                  <img src="../assets/images/sword.png" class="w-3.5">
                </div>
                <div class="p-0.5 flex items-center space-x-1 bg-blue-800 rounded">
                  <div>{{ worker.defend + worker.itemDefend + worker.skillDefend + worker.collectionDefend }}</div>
                  <img src="../assets/images/shield.png" class="w-3.5">
                </div>
                <div class="p-0.5 flex items-center space-x-1 bg-amber-900 rounded">
                  <div>{{ worker.accuracy + worker.itemAccuracy + worker.skillAccuracy + worker.collectionAccuracy }}</div>
                  <img src="../assets/images/target.png" class="w-3.5">
                </div>
              </div>
              <div v-if="worker.isLive" class="relative w-full h-4 rounded bg-gray-400 text-xs text-gray-800 font-bold overflow-hidden mb-1">
                <div class="bg-red-600 h-full" :style="{ width: `${100-((worker.hit / (worker.health + worker.itemHealth + worker.skillHealth + worker.collectionHealth)) * 100)}%` }"></div>
                <div class="absolute inset-0 flex items-center justify-center text-white px-1">
                  {{ (worker.health + worker.itemHealth + worker.skillHealth + worker.collectionHealth) - worker.hit }}/{{ (worker.health + worker.itemHealth + worker.skillHealth + worker.collectionHealth) }}
                </div>
              </div>
              <div class="flex items-center justify-center space-x-1 text-xs sm:text-sm font-bold">
                <div>{{formatSilverPrize(worker.kill)}}</div>
                <div><img src="../assets/images/skull.png" class="w-3.5"></div>
                <div>{{formatSilverPrize(worker.dead)}}</div>
                <div><img src="../assets/images/rip.png" class="w-3.5"></div>
                <div>{{formatSilverPrize(worker.silverPrize) }}</div>
                <div><img src="../assets/images/coin.png" class="w-3.5"></div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div v-if="filterIsLive === false && length >= 20" class="flex justify-center mt-4">
        <button @click="loadMore" class="px-4 py-2 bg-indigo-900 hover:bg-indigo-700 rounded-lg text-white">Load More</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import ModalCreateWorker from '../components/ModalCreateWorker.vue';
import getUser from '../composables/getUser';
import { projectFirestore, projectFunctions } from '@/firebase/config';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import relativeTime from 'dayjs/plugin/relativeTime';
import ModalFreeWorker from '../components/ModalFreeWorker.vue'
import ModalGetFreeItem from '../components/ModalGetFreeItem.vue'
export default {
  components: { ModalCreateWorker,ModalFreeWorker,ModalGetFreeItem },
  setup() {
    dayjs.extend(relativeTime);
    const { user,getFreeWorker,getFreeItem } = getUser();
    const filterIsLive = ref(true);
    const filterOrder = ref({ column: 'star', direction: 'desc' });
    const toggleModal = ref('');
    const workers = ref([]);
    const length = ref(1);
    const lastVisible = ref(null);

    const loadWorkers = (loadMore = false) => {
      let query = projectFirestore.collection('workers')
        .where('ownerUid', '==', user.value.uid)
        .where('isLive', '==', filterIsLive.value)
        .orderBy('work', 'desc')
        .orderBy(filterOrder.value.column, filterOrder.value.direction);

      if (filterIsLive.value === false) {
        query = query.limit(20);
        if (loadMore && lastVisible.value) {
          query = query.startAfter(lastVisible.value);
        }
      }

      query.get().then(snapshot => {
        if (!loadMore) {
          workers.value = [];
        }
        length.value = snapshot.size;
        if (snapshot.docs.length > 0) {
          lastVisible.value = snapshot.docs[snapshot.docs.length - 1];
        }
        snapshot.forEach(workerDoc => {
          workers.value.push({ id: workerDoc.id, ...workerDoc.data() });
        });
      });
    };

    const loadMore = () => {
      loadWorkers(true);
    };

    const setFilter = (isLive) => {
      filterIsLive.value = isLive;
      lastVisible.value = null; // Reset pagination
      loadWorkers();
    };

    // Load workers initially and whenever filterIsLive or filterOrder changes
    watch([filterIsLive, filterOrder], () => {
      lastVisible.value = null; // Reset pagination
      loadWorkers();
    }, { immediate: true });

    const formatSilverPrize = (silverPrize) => {
          if (silverPrize >= 1000000) return (silverPrize / 1000000).toFixed(1) + 'm';
          if (silverPrize >= 100000) return (silverPrize / 1000).toFixed(0) + 'k';
          if (silverPrize >= 1000) return (silverPrize / 1000).toFixed(1) + 'k'; // Update this line
          return silverPrize;
        };



        const fetchIpAddress = async () => {
          try {
            const response = await fetch('https://ipinfo.io/json');
            const data = await response.json();
            console.log('User IP Address:', data.ip);
            const updateIp = projectFunctions.httpsCallable('updateIp');
            await updateIp({ ipAddress: data.ip });
          } catch (error) {
            console.error('Failed to fetch IP address:', error);
          }
        };

        onMounted(() => {
          fetchIpAddress();
        });

        const amountOfWorkerWorking = ref(0)
        projectFirestore.collection('workers').where('ownerUid','==',user.value.uid).where('position','!=','').get().then(snapshot =>{
          amountOfWorkerWorking.value = snapshot.size
        })



 
    return { getFreeItem,amountOfWorkerWorking,getFreeWorker,formatSilverPrize, dayjs, length, toggleModal, workers, filterIsLive, filterOrder, loadMore, setFilter };
  }
};
</script>

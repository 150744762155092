<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-4/5 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div>
            <div class="flex justify-center" :class="[isPending ? 'animate-pulse' : '']"><img src="../../assets/item/pet/dalmatian.png" class="w-20"></div>
            <div class="text-center text-red-500 font-bold">{{ report }}</div>
          </div>
          <div class="mt-1">
          <button v-if="!isPending" @click="handleCreatePetKey" type="button" class="w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">สร้างคีย์สัตว์เลี้ยง</button>
          <button v-if="!isPending" @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
        </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
  import { projectFirestore, projectFunctions } from '@/firebase/config'
  export default {
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}
        const isPending = ref(false)
        const report = ref('')
        const handleCreatePetKey = e =>{
          const createPetKey = projectFunctions.httpsCallable('createPetKey')
          isPending.value = true
          createPetKey({type:'pet'}).then(result =>{
            console.log(result)
            isPending.value = false
            report.value = result.data
            if(result.data === 'สร้างคีย์สำเร็จ'){
              context.emit('cancelModal')
            }
          }).catch(err =>{
            console.log(err)
            isPending.value = false
          })
        }
  
        return { report,cancelModal,isPending,handleCreatePetKey }
    },
  }
  </script>
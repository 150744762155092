<template>
  <ModalCancelOrder v-if="toggleModal === 'ModalCancelOrder'" :orderId="orderId" @cancelModal="toggleModal = ''" />
  
  <div class="p-1 pb-24 mb-auto h-full text-white  overflow-auto" id="style-1">
    <div v-if="orders.length === 0" class="text-center  p-4 text-xl font-bold bg-gray-900 rounded">
      คุณยังไม่มีออเดอร์
    </div>
    
    <div v-else>
      <div class="grid grid-cols-3 bg-gray-700 p-1 text-center text-xs sm:text-sm rounded-t">
        <div>ชื่อไอเทม</div>
        <div>ราคา</div>
        <div>ข้อมูล</div>
      </div>
      
      <div v-for="(order, index) in orders" :key="index" class="grid grid-cols-3 bg-gray-900 items-center p-1 text-center text-xs sm:text-sm rounded-t border-b border-gray-700">
        <div class="flex items-center justify-center space-x-1">
          <div><img :src="order.img" class="w-8 sm:w-10"></div>
          <div class="flex items-center space-x-1 border border-gray-500 rounded px-1">
            <div><img src="../../assets/images/star.png" class="w-2"></div>
            <div class="text-xs">{{order.star}}</div>
          </div>
          <div :class="[order.textColor]">{{order.name}}</div>
        </div>
        <div>{{order.price}}</div>
        <div class="flex justify-center items-center space-x-1">
          <div @click="handleOrder(order.id, 'ModalCancelOrder')" class="bg-red-800 hover:bg-red-700 duration-500 cursor-pointer p-1 px-2 rounded">ยกเลิก</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getUser from '@/composables/getUser';
import { projectFirestore } from '@/firebase/config';
import { ref, onUnmounted } from 'vue';
import ModalCancelOrder from './ModalCancelOrder.vue';

export default {
  components: { ModalCancelOrder },
  setup() {
    const { user } = getUser();
    const orders = ref([]);
    const toggleModal = ref('');
    const orderId = ref('');

    // Subscribe to Firestore collection
    const unsubscribe = projectFirestore
      .collection('orders')
      .where('ownerUid', '==', user.value.uid)
      .onSnapshot((snapshot) => {
        orders.value = []; // Clear the orders array first
        snapshot.forEach((orderDoc) => {
          orders.value.push({
            id: orderDoc.id,
            ...orderDoc.data(),
          });
        });
      });

    // Unsubscribe when component is unmounted
    onUnmounted(() => {
      unsubscribe();
    });

    const handleOrder = (id, modal) => {
      toggleModal.value = modal;
      orderId.value = id;
    };

    return { orders, handleOrder, orderId, toggleModal };
  },
};
</script>

<template>
    <div class="p-1 sm:p-4 pb-16 mb-auto h-full text-white overflow-auto">
      <div v-if="toggleModal === 'ModalCreateWorkerKey'" @cancelModal="toggleModal = ''">
        <ModalCreateWorkerKey @cancelModal="toggleModal = ''"/>
      </div>
      <div v-if="toggleModal === 'ModalCreatePetKey'" @cancelModal="toggleModal = ''">
        <ModalCreatePetKey @cancelModal="toggleModal = ''"/>
      </div>
      <div v-if="toggleModal === 'ModalCreateVipKey'" @cancelModal="toggleModal = ''">
        <ModalCreateVipKey @cancelModal="toggleModal = ''"/>
      </div>
      <div v-if="toggleModal === 'ModalCreateSilverKey'" @cancelModal="toggleModal = ''">
        <ModalCreateSilverKey @cancelModal="toggleModal = ''"/>
      </div>
  
      <div class="bg-gray-800 p-1 sm:p-4 rounded-lg  ">
        <div v-if="admin" class="grid grid-cols-1 sm:grid-cols-4 gap-1.5 mb-4">
          <div @click="toggleModal = 'ModalCreateWorkerKey'" class="flex items-center justify-between bg-indigo-500 hover:bg-indigo-400 text-white font-bold py-2 px-4 rounded-lg cursor-pointer transition-colors duration-300">
            <div class="flex items-center space-x-2">
              <img src="../assets/worker/peter.png" class="w-8">
              <span>สร้างคีย์สุ่มคนงาน</span>
            </div>
          </div>
          <div @click="toggleModal = 'ModalCreatePetKey'" class="flex items-center justify-between bg-indigo-500 hover:bg-indigo-400 text-white font-bold py-2 px-4 rounded-lg cursor-pointer transition-colors duration-300">
            <div class="flex items-center space-x-2">
              <img src="../assets/item/pet/boxer.png" class="w-8">
              <span>สร้างคีย์สุ่มสัตว์เลี้ยง</span>
            </div>
          </div>
          <div @click="toggleModal = 'ModalCreateVipKey'" class="flex items-center justify-between bg-indigo-500 hover:bg-indigo-400 text-white font-bold py-2 px-4 rounded-lg cursor-pointer transition-colors duration-300">
            <div class="flex items-center space-x-2">
              <img src="../assets/images/vip.png" class="w-8">
              <span>สร้างคีย์ VIP</span>
            </div>
          </div>
          <div @click="toggleModal = 'ModalCreateSilverKey'" class="flex items-center justify-between bg-indigo-500 hover:bg-indigo-400 text-white font-bold py-2 px-4 rounded-lg cursor-pointer transition-colors duration-300">
            <div class="flex items-center space-x-2">
              <img src="../assets/images/coin.png" class="w-8">
              <span>สร้างคีย์ Silver</span>
            </div>
          </div>
        </div>
  
        <div class="bg-gray-900 p-4 rounded-lg">
          <div class="text-center font-bold mb-2">คีย์ของคุณ</div>
          <KeyList />
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import { ref, onMounted } from 'vue';
  import ModalCreateWorkerKey from '../components/key/ModalCreateWorkerKey.vue';
  import ModalCreatePetKey from '../components/key/ModalCreatePetKey.vue';
  import ModalCreateVipKey from '../components/key/ModalCreateVipKey.vue';
  import ModalCreateSilverKey from '../components/key/ModalCreateSilverKey.vue';
  import KeyList from '@/components/key/KeyList.vue';
  import getUser from '@/composables/getUser';
  import dayjs from 'dayjs';
  import 'dayjs/locale/th';
  import relativeTime from 'dayjs/plugin/relativeTime';
  
  dayjs.locale('th');
  dayjs.extend(relativeTime);
  
  export default {
    components: { ModalCreateSilverKey, KeyList, ModalCreateWorkerKey, ModalCreatePetKey, ModalCreateVipKey },
    setup() {
      const { user,admin } = getUser();
      const toggleModal = ref('');
  
  
  
      return { toggleModal,admin };
    },
  };
  </script>
  
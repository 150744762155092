<template>
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
<div class="fixed inset-0 z-10 w-screen overflow-y-auto">
  <div class="flex h-4/5 items-end justify-center p-4 text-center sm:items-center sm:p-0">
    <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
      <div class="bg-gray-800 text-gray-300 p-3">
        <div class="p-3 space-y-1">
          <div class="flex justify-center"><img src="../assets/worker/peter.png" class="w-20"></div>
          <div class="flex justify-center items-center space-x-1">
            <div class="flex items-center space-x-1 border rounded w-10 justify-center border-gray-600 ">
              <div><img src="../assets/images/star.png" class="w-3 sm:w-4"></div>
              <div>1</div>
            </div>
            <div class="text-center font-bold">ปีเตอร์ฝึกหัด</div>
          </div>
          <div class="flex justify-center items-center space-x-1">
            <div class="flex items-center justify-center space-x-1 bg-pink-700 w-14 p-1 rounded-lg">
              <div class="font-bold">100</div>
              <div><img src="../assets/images/hearth.png" class="w-5"></div>
            </div>
            <div class="flex items-center justify-center space-x-1 bg-red-700 w-14 p-1 rounded-lg">
              <div class="font-bold">10</div>
              <div><img src="../assets/images/sword.png" class="w-5"></div>
            </div>
            <div class="flex items-center justify-center space-x-1 bg-blue-700 w-14 p-1 rounded-lg">
              <div class="font-bold">0</div>
              <div><img src="../assets/images/shield.png" class="w-5"></div>
            </div>
            <div class="flex items-center justify-center space-x-1 bg-yellow-700 w-14 p-1 rounded-lg">
              <div class="font-bold">0</div>
              <div><img src="../assets/images/target.png" class="w-5"></div>
            </div>
          </div>
        </div>
        <div >
        <button v-if="!isPending" @click="handleGetFreeWorker()" type="button" class="w-full justify-center rounded bg-lime-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-lime-700 duration-300">รับฟรีคนงานปีเตอร์ฝึกหัด</button>
        <button v-if="isPending"  class="mt-1.5 w-full justify-center rounded bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   ">Loading...</button>
        <button v-if="!isPending" @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
      </div>
      </div>
    </div>
  </div>
</div>
</div>

</template>
<script>
import { ref } from 'vue'
import { projectFirestore, projectFunctions } from '@/firebase/config'
export default {
  setup(props,context) {
      const cancelModal = e =>{context.emit('cancelModal')}
      const isPending = ref(false)
      const report = ref('')
      const handleGetFreeWorker = e =>{
        const getFreeWorker = projectFunctions.httpsCallable('getFreeWorker')
        isPending.value = true
        getFreeWorker().then(result =>{
          isPending.value = false
          report.value = result.data
          if(result.data === 'คุณได้รับปีเตอร์ฝึกหัด'){
            context.emit('cancelModal')
            location.reload()
          }
        }).catch(err =>{
          isPending.value = false
        })
      }

      return { isPending,handleGetFreeWorker,cancelModal }
  },
}
</script>
<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-4/5 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div  class="h-24 flex justify-center items-center border-4 border-dashed">
            <div v-if="!keySelected.used" class="text-center">
               <div class="text-3xl font-bold">{{ keySelected.password }}</div>
              <div v-if="expireDateDay >= 0 && expireDateHour >= 0 && expireDateMinute >= 0 && expireDateSecond>= 0">
                <div>โค้ดกำลังหมดอายุภายใน</div>
                <div>{{ expireDateDay }} วัน {{ expireDateHour }} ชั่วโมง {{ expireDateMinute }} นาที {{ expireDateSecond }} วินาที</div>
              </div>
              <div v-if="expireDateDay < 0 && expireDateHour < 0 && expireDateMinute < 0 && expireDateSecond < 0">
                <div>โค้ดนี้หมดอายุแล้ว</div>
              </div>
            </div>
            <div v-if="keySelected.used">
              <div class="text-3xl font-bold">คีย์ถูกใช้งานแล้ว</div>
            </div>
          </div>
          <div v-if="report" class="text-center text-sm">{{ report }}</div>
          <div class="mt-1">
            <button v-if="!isPending" @click="handleMarkKey" type="button" class="mt-1.5 w-full justify-center rounded bg-indigo-700 px-3 py-2 text-sm font-semibold text-gray-100 shadow-sm   hover:bg-indigo-600  duration-300">ทำเครื่องหมายว่าแจกคนอื่นแล้ว</button>
          <button v-if="!isPending" @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
        </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
import { projectFirestore, projectFunctions } from '@/firebase/config'
  export default {
    props:['keySelected'],
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}
        const expireDateDay = ref(0)
        const expireDateHour = ref(0)
        const expireDateMinute = ref(0)
        const expireDateSecond = ref(0)

        projectFirestore.collection('keys').doc(props.keySelected.id).get().then(keyDoc =>{

            var x = setInterval(function() {

            var now = new Date().getTime();

            var distance = keyDoc.data().expireDate.toDate().getTime() - now;

            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            expireDateDay.value = days
            expireDateHour.value = hours
            expireDateMinute.value = minutes
            expireDateSecond.value = seconds
            if (distance < 0) {
                clearInterval(x);
            }
            }, 0);
            })

            const isPending = ref(false)
            const  report = ref('')
            const handleMarkKey = e =>{
              const markKey = projectFunctions.httpsCallable('markKey')
              isPending.value = true
              markKey({keyId: props.keySelected.id}).then(result =>{
                isPending.value = false
                context.emit('cancelModal')
                report.value = result.data
              }).catch(err =>{
                context.emit('cancelModal')
                isPending.value = false
              })

            }


        return { report,isPending,handleMarkKey,cancelModal,expireDateDay,
expireDateHour,
expireDateMinute,
expireDateSecond }
    },
  }
  </script>
<template>
    <div class="grid grid-cols-3 gap-1">
       <div v-for="worker in workers" :key="worker">
         <div :class="[worker.name === workerSelected.name ? 'bg-yellow-900' : 'bg-gray-900']" class="bg-gray-900 rounded">
           <div class="flex justify-center"><img :src="worker.img" class="w-12"></div>
             <div class="flex justify-center items-center space-x-1">
               <div><img src="../assets/images/star.png" class="w-2.5"></div>
               <div class="font-bold text-xs">{{ worker.star }}</div>
             </div>
             <div :class="worker.textColor" class="text-center text-xs sm:text-sm ">{{ worker.name }}</div>
             <div class="flex items-center space-x-1 justify-center">
              <div class="flex justify-center items-center space-x-1">
                  <div class="text-xs">{{ worker.health }}</div>
                  <div><img src="../assets/images/hearth.png" class="w-2.5"></div>
                </div>
                <div class="flex justify-center items-center space-x-1">
                  <div class="text-xs">{{ worker.attack }}</div>
                  <div><img src="../assets/images/sword.png" class="w-2.5"></div>
                </div>
                <div class="flex justify-center items-center space-x-1">
                  <div class="text-xs">{{ worker.defend }}</div>
                  <div><img src="../assets/images/shield.png" class="w-2.5"></div>
                </div>
                <div class="flex justify-center items-center space-x-1">
                  <div class="text-xs">{{ worker.accuracy }}</div>
                  <div><img src="../assets/images/target.png" class="w-2.5"></div>
                </div>
              </div>
           </div>
         </div>
       </div>   
   </template>
   
   <script>
   import { ref } from 'vue'
   export default {
       setup() {
         const workers = ref([
         {name: 'ปีเตอร์',health: 250,star: 6, attack: 15, defend: 2, accuracy: 5,textColor: 'text-white', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fpeter.png?alt=media&token=58cd2ce7-ac69-4da0-96e9-82970737f3a2'},
          {name: 'ปีแยร์',health: 250,star: 7, attack: 16, defend: 2, accuracy: 5,textColor: 'text-amber-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fpierre.png?alt=media&token=b9f093b8-72e7-4a23-8f5d-41bb82d8f60e'},
          {name: 'มาร์ค',health: 250,star: 8, attack: 17, defend: 2, accuracy: 5,textColor: 'text-lime-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fmark.png?alt=media&token=2d684ac2-b85d-4ea0-9e88-a272117daf7f'},
          {name: 'นาธาน',health: 250,star: 9, attack:18, defend: 2, accuracy: 5,textColor: 'text-green-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fnathan.png?alt=media&token=1fe18fb4-43df-4bcb-b2d3-8d7fca18897e'},
          {name: 'จวน',health: 250,star: 10, attack: 19, defend: 2, accuracy: 5,textColor: 'text-cyan-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fjuan.png?alt=media&token=9222f167-03de-4d50-8ef6-fbaaafde7c83'},
          {name: 'ดาโกต้า',health: 250,star: 11, attack: 20, defend: 2, accuracy: 5,textColor: 'text-sky-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fdakota.png?alt=media&token=21a37af6-2cb6-4bdc-9668-9ae44a20d680'},
          {name: 'โคฟี่',health: 250,star: 12, attack: 21, defend: 2, accuracy: 5,textColor: 'text-blue-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fkofi.png?alt=media&token=fef78eb4-f520-4c0f-bc55-d8a2231abf17'},
          {name: 'หว่อง',health: 250,star: 13, attack: 22, defend: 2, accuracy: 5,textColor: 'text-indigo-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fwong.png?alt=media&token=6aa6e3b2-c61b-46f1-9aa4-e8e181425e22'},
          {name: 'แคทเธอรีน',health: 250,star: 14, attack: 23, defend: 2,accuracy: 5,textColor: 'text-violet-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fkatherine.png?alt=media&token=a05ca758-04e1-40a0-9814-27a8e507d841'},
          {name: 'ฮิวส์ตัน',health: 250,star: 15, attack: 24, defend: 2, accuracy: 5,textColor: 'text-pink-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fhouston.png?alt=media&token=4bcf5ded-6b02-4e65-8f6f-72325884d1ad'},
          {name: 'สมิธ',health: 250,star: 16, attack: 25, defend: 2, accuracy: 5,textColor: 'text-red-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fsmith.gif?alt=media&token=04ab543c-cb2c-4d59-8952-7c64316d6328'},
         ])
   
         const workerSelected = ref({
           name: '',
           health: 0,
           star: 0,
           attack: 0,
           defend: 0,
           img: ''
           })
   
           setInterval(() => {
           let random = Math.floor(Math.random() * workers.value.length);
           workerSelected.value = workers.value[random];
           }, 100);
   
   
         return { workers,workerSelected }
       },
   }
   </script>
v<template>
    <div class="p-1 pb-16 mb-auto h-full text-white  overflow-auto" id="style-1">
      <div class="m-auto max-w-screen-xl bg-gray-900 p-3 rounded">
        <div class=" space-y-2">
          <div class="text-xl font-bold underline">เงื่อนไขการใช้เว็บไซต์</div>
          <div class="text-lg font-bold">1. การยอมรับเงื่อนไข</div>
          <div>1.1 การเข้าใช้งานเว็บไซต์นี้ถือว่าผู้ใช้ยอมรับเงื่อนไขการใช้งานทั้งหมดที่ระบุไว้ในที่นี้</div>
          <div>1.2 หากคุณไม่ยอมรับเงื่อนไขเหล่านี้ กรุณาอย่าใช้เว็บไซต์นี้</div>
          <div class="text-lg font-bold">2. การลงทะเบียนและบัญชีผู้ใช้</div>
          <div>2.1 ผู้ใช้ต้องลงทะเบียนบัญชีเพื่อเข้าถึงและใช้บริการต่างๆ บนเว็บไซต์</div>
          <div>2.2 ข้อมูลที่ใช้ในการลงทะเบียนต้องเป็นความจริงและถูกต้อง หากพบว่ามีการให้ข้อมูลเท็จ เว็บไซต์มีสิทธิ์ในการยกเลิกบัญชีผู้ใช้โดยไม่ต้องแจ้งล่วงหน้า</div>
          <div>2.3 ผู้ใช้ต้องรักษาความลับของข้อมูลบัญชีและรหัสผ่านของตนเอง และต้องรับผิดชอบต่อกิจกรรมทั้งหมดที่เกิดขึ้นภายใต้บัญชีของตน</div>
          <div class="text-lg font-bold">3. การใช้งานข้อมูลคนงานและการเล่นเกม</div>
          <div>3.1 ผู้เล่นตกลงที่จะไม่ใช้โปรแกรมหรือวิธีการใดๆ ที่ไม่ได้รับอนุญาตเพื่อแทรกแซงหรือพยายามแทรกแซงการทำงานของเกม</div>
          <div class="text-lg font-bold">4. การแลกเปลี่ยนและการซื้อขายไอเทม</div>
          <div>4.1 ผู้เล่นสามารถแลกเปลี่ยนไอเทมและคีย์กับผู้เล่นคนอื่นได้</div>
          <div>4.2 การแลกเปลี่ยนและการซื้อขายต้องเป็นไปตามกฎระเบียบของเว็บไซต์ โดยเว็บไซต์ไม่รับผิดชอบต่อการซื้อขายหรือแลกเปลี่ยนที่เกิดขึ้นระหว่างผู้เล่น</div>
          <div>4.3 เว็บไซต์ไม่รับผิดชอบการซื้อขายทุกกรณี ถ้าเว็บไซต์มีการปิดให้บริการ</div>
          <div class="text-lg font-bold">5. การจัดการและการแก้ไขข้อมูล</div>
          <div>5.1 เว็บไซต์มีสิทธิ์ในการแก้ไขหรืออัพเดตข้อมูลใดๆ บนเว็บไซต์โดยไม่ต้องแจ้งให้ทราบล่วงหน้า</div>
          <div>5.2 เว็บไซต์มีสิทธิ์ในการยกเลิกหรือระงับการใช้งานบัญชีของผู้ใช้ที่ฝ่าฝืนเงื่อนไขการใช้งาน</div>
          <div class="text-lg font-bold">6. ความรับผิดชอบและข้อจำกัดความรับผิด</div>
          <div>6.1 เว็บไซต์ไม่รับประกันว่าการบริการจะปราศจากข้อผิดพลาดหรือการขัดข้องใดๆ          </div>
          <div>6.2 ผู้ใช้ยอมรับว่าเว็บไซต์จะไม่รับผิดชอบต่อความเสียหายใดๆ ที่เกิดขึ้นจากการใช้งานเว็บไซต์หรือบริการต่างๆ บนเว็บไซต์</div>
          <div class="text-lg font-bold">7. การเปลี่ยนแปลงเงื่อนไขการใช้งาน</div>
          <div>7.1 เว็บไซต์มีสิทธิ์ในการยกเลิกหรือระงับบัญชีของผู้เล่นที่ฝ่าฝืนเงื่อนไขการใช้งานโดยไม่ต้องแจ้งล่วงหน้า</div>
          <div class="text-lg font-bold">8. การแก้ไขและเปลี่ยนแปลงเงื่อนไขการใช้งาน</div>
          <div>8.1 เว็บไซต์ขอสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไขการใช้งานโดยไม่ต้องแจ้งให้ทราบล่วงหน้า</div>
          <div>8.2 ผู้ใช้ควรตรวจสอบเงื่อนไขการใช้งานเป็นประจำเพื่อรับทราบถึงการเปลี่ยนแปลงใดๆ          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
      setup() {
          
      },
  }
  </script>
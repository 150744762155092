<template>
    <div class="p-1 sm:p-4 pb-24 mb-auto h-full text-white  overflow-auto" id="style-1">
      <div class="space-y-3">
        <div class="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-8 gap-2">
          <!-- <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ stat.amountOfWorker }}</div>
            <div class="text-gray-400">คนงาน</div>
          </div> -->
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ stat.workerKeyDrop }}</div>
            <div class="text-gray-400">คีย์คนงานดรอป</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ stat.openWorkerBox }}</div>
            <div class="text-gray-400">เปิดกล่องคนงาน</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ stat.petKeyDrop }}</div>
            <div class="text-gray-400">คีย์สัตว์เลี้ยงดรอป</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ stat.openPetBox }}</div>
            <div class="text-gray-400">เปิดกล่องสัตว์เลี้ยง</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ stat.tranferWorker }}</div>
            <div class="text-gray-400">โอนคนงาน</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ stat.tranferItem }}</div>
            <div class="text-gray-400">โอนไอเทม</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ formatSilverPrize(stat.tranferSilver) }}</div>
            <div class="text-gray-400">โอนเหรียญ({{ stat.tranferSilverAmount }})</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ formatSilverPrize(stat.killThief) }}</div>
            <div class="text-gray-400">สังหารโจร</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ stat.itemDrop }}</div>
            <div class="text-gray-400">ไอเทมดรอป</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ stat.collectionDrop }}</div>
            <div class="text-gray-400">ของสะสมดรอป</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ formatSilverPrize(stat.coinDrop) }}</div>
            <div class="text-gray-400">เหรียญดรอป</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ formatSilverPrize(stat.openSilverWorkerBox) }}</div>
            <div class="text-gray-400">ซื้อกล่องคนงาน</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ formatSilverPrize(stat.learnSkill) }}</div>
            <div class="text-gray-400">เรียนรู้ทักษะ</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ formatSilverPrize(stat.buyItem) }}</div>
            <div class="text-gray-400">ซื้อไอเทม</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ formatSilverPrize(stat.workerDead) }}</div>
            <div class="text-gray-400">คนงานเสียชีวิต</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ formatSilverPrize(stat.workerBorn) }}</div>
            <div class="text-gray-400">คนงานเกิดใหม่</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ stat.successUpgrade }}</div>
            <div class="text-gray-400">อัพเกรดสำเร็จ</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ stat.failUpgrade }}</div>
            <div class="text-gray-400">อัพเกรดล้มเหลว</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ stat.successDisassemble }}</div>
            <div class="text-gray-400">แยกชิ้นส่วนสำเร็จ</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ stat.failDisassemble }}</div>
            <div class="text-gray-400">แยกชิ้นส่วนไม่สำเร็จ</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ formatSilverPrize(stat.buyTicket) }}</div>
            <div class="text-gray-400">ซื้อตั๋ว</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ formatSilverPrize(stat.buyMarketplace) }}({{ stat.amountBuyMarketplace }})</div>
            <div class="text-gray-400">ซื้อของในตลาด</div>
          </div>
          <div class="bg-gray-900 p-3 text-center rounded-lg shadow-lg">
            <div class="text-xl font-bold">{{ formatSilverPrize(stat.marketplaceFee) }}</div>
            <div class="text-gray-400">ภาษีตลาด</div>
          </div>
        </div>
        <StatList />
      </div>
    </div>
  </template>
  <script>
import { ref } from 'vue'
import { projectFirestore } from '@/firebase/config'
import StatList from '../components/StatList.vue'
  export default {
    components : { StatList },
      setup() {
        const stat = ref({})
        projectFirestore.collection('serverPublic').doc('stat').get().then(snapshot =>{
          stat.value = snapshot.data()
        })

        const formatSilverPrize = (silverPrize) => {
          if (silverPrize >= 1000000) return (silverPrize / 1000000).toFixed(1) + 'm';
          if (silverPrize >= 100000) return (silverPrize / 1000).toFixed(0) + 'k';
          if (silverPrize >= 1000) return (silverPrize / 1000).toFixed(1) + 'k'; // Update this line
          return silverPrize;
        };



        return { stat,formatSilverPrize }
      },
  }
  </script>
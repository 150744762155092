<template>
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
<div class="fixed inset-0 z-10 w-screen overflow-y-auto">
  <div class="flex h-full pb-16 items-end justify-center p-4 text-center sm:items-center sm:p-0">
    <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
      <div class="bg-gray-800 text-white p-3">
        <div class="bg-gray-900 rounded">
      <div v-if="logs.length >= 1">
        <div :class="[logs.length >= 4 ? 'h-40' : 'h-auto']" ref="logContainer" class="grid grid-cols-1 gap-1 h-40 overflow-auto" id="style-1">
          <transition-group name="slide-fade" tag="div">
            <div v-for="log in logs" :key="log.id" class="flex items-center justify-between space-x-1 border-b border-gray-700 text-gray-400 bg-gray-900 p-2 ">
              <div class="flex items-center space-x-1">
                <div><img :src="log.img" class="w-5"></div>
              <div>{{ log.message }}</div>
              </div>
              <div >
                <div >{{ dayjs(log.createdAt.toDate()).locale('th').fromNow() }}</div>
              </div>
            </div>
          </transition-group>
        </div>
        <div class="flex justify-center mt-2">
          <button @click="loadMore" class="w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300" :disabled="loadingMore">
            โหลดเพิ่มเติม
          </button>
        </div>
      </div>
      <div v-else>
        <div class="flex items-center justify-center h-20">
          <div class="text-xl font-bold opacity-50">ไม่พบประวัติการแลกเปลี่ยน</div>
        </div>
      </div>
    </div>
        <div >
        <button @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
      </div>
      </div>
    </div>
  </div>
</div>
</div>
  </template>
  
  <script>
  import { ref, nextTick } from 'vue';
  import getUser from '@/composables/getUser';
  import { projectFirestore } from '@/firebase/config';
  import dayjs from 'dayjs';
  import 'dayjs/locale/th';
  import relativeTime from 'dayjs/plugin/relativeTime';
  
  export default {
    props: ['id'],
    setup(props,context) {
      const cancelModal = e =>{context.emit('cancelModal')}
      dayjs.extend(relativeTime);
      const { user } = getUser();
      const logs = ref([]);
      const limit = ref(30);
      const lastVisible = ref(null);
      const loadingMore = ref(false);
      const logContainer = ref(null);
  
      const fetchLogs = async (initial = false) => {
        if (initial) logs.value = [];
  
        let query = projectFirestore.collection('logs')
          .where('workerId', '==', props.id)
          .where('type', '==', 'changeWorkerOwnerLog')
          .orderBy('createdAt', 'desc')
          .limit(initial ? limit.value : 6); // Adjust the limit for loading more
  
        if (lastVisible.value && !initial) {
          query = query.startAfter(lastVisible.value);
        }
  
        const snapshot = await query.get();
        if (snapshot.docs.length > 0) {
          lastVisible.value = snapshot.docs[snapshot.docs.length - 1];
          const newLogs = [];
          snapshot.forEach(logDoc => {
            newLogs.push({ id: logDoc.id, ...logDoc.data() });
          });
          logs.value.push(...newLogs);
          if (!initial) {
            nextTick(() => {
              smoothScrollToBottom(logContainer.value);
            });
          }
        }
      };
  
      const loadMore = () => {
        if (!loadingMore.value) {
          loadingMore.value = true;
          fetchLogs().then(() => {
            loadingMore.value = false;
          });
        }
      };
  
      const smoothScrollToBottom = (element) => {
        const targetScrollTop = element.scrollHeight - element.clientHeight;
        const initialScrollTop = element.scrollTop;
        const distance = targetScrollTop - initialScrollTop;
        const duration = 500; // duration in milliseconds
        let start = null;
  
        const step = (timestamp) => {
          if (!start) start = timestamp;
          const progress = timestamp - start;
          const percent = Math.min(progress / duration, 1);
          element.scrollTop = initialScrollTop + distance * percent;
          if (progress < duration) {
            window.requestAnimationFrame(step);
          }
        };
  
        window.requestAnimationFrame(step);
      };
  
      fetchLogs(true); // Fetch initial logs
  
      return { cancelModal,logs, dayjs, loadMore, loadingMore, logContainer };
    }
  };
  </script>
  
  <style scoped>
  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all 0.5s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
    transform: translateY(10px);
    opacity: 0;
  }
  </style>
  
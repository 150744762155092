<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-full pb-16 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div class="text-gray-300 text-center text-xl mb-1">แผนที่คนงาน</div>
          <div  class=" overflow-auto">
            <div v-for="collection in collections" :key="collection">
              <div  class="flex  justify-between bg-gray-900 mb-0.5 rounded p-1">
                <div class="flex items-center space-x-1">
                  <div  class="flex justify-center"><img src="../../assets/images/map.png" class="w-8 "></div>
                  <div class="flex justify-center items-center space-x-1 border border-gray-700 px-1 rounded">
                              <div><img src="../../assets/images/star.png" class="w-3"></div>
                              <div class=" font-bold text-sm">{{ collection.star }}</div>
                            </div>
                  <div :class="[workerMaps.includes(collection.name) ? 'text-green-500' : 'text-red-500']" class="text-gray-300 text-sm">{{ collection.name }}</div>
                </div>
                <div class="flex items-center space-x-1 font-bold  text-xs sm:text-sm">
                  <div v-if="collection.health >= 1" class="flex items-center space-x-1">
                          <div>{{ collection.health }}</div>
                          <div><img src="../../assets/images/hearth.png" class="w-4 sm:w-5"></div>
                        </div>
                      <div v-if="collection.attack >= 1" class="flex  items-center space-x-1">
                        <div>{{ collection.attack }}</div>
                        <div><img src="../../assets/images/sword.png" class="w-4 sm:w-5"></div>
                      </div>
                      <div v-if="collection.defend >= 1" class="flex  items-center space-x-1">
                        <div>{{ collection.defend }}</div>
                        <div><img src="../../assets/images/shield.png" class="w-4 sm:w-5"></div>
                      </div>
                      <div v-if="collection.accuracy >= 1" class="flex  items-center space-x-1">
                        <div>{{ collection.accuracy }}</div>
                        <div><img src="../../assets/images/target.png" class="w-4 sm:w-5"></div>
                      </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-1">
          <button  @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
        </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
import { projectFirestore, projectFunctions } from '@/firebase/config'
import { useRouter } from 'vue-router'
  export default {
    props: ['id'],
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}
        const worker = ref()
        const workerMaps = ref([])
        projectFirestore.collection('workers').doc(props.id).onSnapshot(snapshot =>{
          worker.value = snapshot.data()
          workerMaps.value = snapshot.data().maps
        })

        const collections = [
          {name:'แผนที่เหมืองเดอะฮิวจ์',price: 3000, health: 50, attack: 3, defend: 1, accuracy: 1,star: 1, textColor: 'text-white', type:'map', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fmap.png?alt=media&token=7edafd76-ba65-46cc-bd3a-733e1756e387'},
          {name:'แผนที่ป่าซานฮวน',health: 50, attack: 3, defend: 1, accuracy: 1,star: 2, type: 'map', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fmap.png?alt=media&token=7edafd76-ba65-46cc-bd3a-733e1756e387'},
          {name:'แผนที่ป่าดิบชื้นเดดวูด',price: 6000, health: 50, attack: 3, defend: 1, accuracy: 1,star: 3, textColor: 'text-lime-500', type:'map', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fmap.png?alt=media&token=7edafd76-ba65-46cc-bd3a-733e1756e387'},
          {name:'แผนที่ทะเลทรายแห่งความตาย',price: 9000, health: 50, attack: 3, defend: 1, accuracy: 1,star: 5, textColor: 'text-cyan-500', type:'map', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fmap.png?alt=media&token=7edafd76-ba65-46cc-bd3a-733e1756e387'},
          {name:'แผนที่หมู่บ้านโจรสลัด',price: 12000, health: 50, attack: 3, defend: 1, accuracy: 1,star: 7, textColor: 'text-blue-500', type:'map', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fmap.png?alt=media&token=7edafd76-ba65-46cc-bd3a-733e1756e387'},
        ]

        return { workerMaps,collections, cancelModal }
    },
  }
  </script>
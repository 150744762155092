<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-full pb-16 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div class="space-y-1">
            <div class="flex justify-center"><img :src="item.img" class="w-28"></div>
            <div class="flex items-center justify-center  space-x-1">
              <div class="flex justify-center items-center space-x-1 border border-gray-700 px-1 rounded">
                <div><img src="../../assets/images/star.png" class="w-3"></div>
                <div class=" font-bold text-sm">{{ item.star }}</div>
              </div>
              <div class="text-center font-bold text-sm">{{item.name}}</div>
              </div>
              <div class="flex justify-center space-x-1 items-center text-sm font-bold">
                <div v-if="item.health >= 1" class="flex items-center space-x-1">
                  <div><img src="../../assets/images/hearth.png" class="w-4"></div>
                  <div>{{ item.health  }}</div>
                </div>
                <div v-if="item.attack >= 1" class="flex items-center space-x-1">
                  <div><img src="../../assets/images/sword.png" class="w-4"></div>
                  <div>{{ item.attack }}</div>
                </div>
                <div v-if="item.defend >= 1" class="flex items-center space-x-1">
                  <div><img src="../../assets/images/shield.png" class="w-4"></div>
                  <div>{{ item.defend  }}</div>
                </div>
                <div v-if="item.accuracy >= 1" class="flex items-center space-x-1">
                  <div><img src="../../assets/images/target.png" class="w-4"></div>
                  <div>{{ item.accuracy  }}</div>
                </div>
              </div>
            <div v-if="!tab">
                
            </div>
            <div v-if="tab === 'ChangeOwner'">
              <div v-if="report" :class="[report === 'เปลี่ยนเจ้าของสำเร็จแล้ว' ? 'text-green-500' : 'text-red-500']" class="text-center font-bold text-sm">{{ report }}</div>
              <input v-if="!isPending &&  !item.used" v-model="newOwnerUid" type="text" class="w-full p-3 text-lg bg-gray-700 rounded border border-gray-600 focus:border-indigo-500 focus:outline-none text-center" placeholder="กรอก UID ของผู้เล่นที่ต้องการให้">
            </div>
            <div v-if="tab === 'sell'">
              <div class="text-center text-sm mb-2 bg-red-100 text-red-500 rounded p-1 border-red-500 border">
                <div class="font-bold">เงื่อนไข</div>
                <div>1.ต้องตั้งราคามากกว่า 100 เหรียญ</div>
                <div>2.เสียภาษี 5% เมื่อขายสำเร็จ</div>
              </div>
              <input v-model="price" type="number" class="w-full p-3 text-lg bg-gray-700 rounded border border-gray-600 focus:border-indigo-500 focus:outline-none text-center" placeholder="กรอกราคา">
              <div class="text-center text-sm text-red-500">{{ report }}</div>
            </div>
            <div v-if="tab === 'log'">
              <itemLog :item="item" />
            </div>
          </div>
          <div class="space-y-1 mt-2">
            <router-link v-if="item.workerId" router-link :to="{ name: 'WorkerDetail', params: { id: item.workerId }}">
              <div class="w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300 text-center">คนงานที่กำลังใส่ไอทมนี้อยู่</div>
            </router-link>
            
            <button v-if="!isPending &&  !item.used && tab ==='sell'" @click="handleCreateOrder" type="button" class="w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">สร้างออเดอร์</button>
            <button v-if="!isPending &&  !item.used && tab ==='ChangeOwner'" @click="handleChangeItemOwner" type="button" class="w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">เปลี่ยนเจ้าของ</button>
            <button v-if="!isPending && !item.used && tab ==='destroyTrash'" @click="handleDestroyTrash" type="button" class="w-full justify-center rounded bg-red-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 duration-300">ทำลายขยะ</button>            
            <button v-if="!isPending && !tab" @click="tab ='sell'" type="button" class="w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ขายไอเทม</button>
            <button v-if="!isPending && !tab" @click="tab ='ChangeOwner'" type="button" class="w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">เปลี่ยนเจ้าของ</button>
            <button v-if="!isPending && !tab" @click="tab ='destroyTrash'" type="button" class="w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ทำลายไอเทม</button>
            <button v-if="!isPending && !tab" @click="tab ='log'" type="button" class="w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ประวัติไอเทม</button>
            <button v-if="!isPending && tab" @click="tab =''" type="button" class="w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ย้อนกลับ</button>
            <button v-if="!isPending && !tab" @click="cancelModal" type="button" class="w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
import { projectFirestore, projectFunctions } from '@/firebase/config'
import itemLog from './ItemLog.vue'
  export default {
    props: ['item'],
    components: { itemLog },
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}
        const tab = ref('')
        const newOwnerUid = ref('')
        const isPending = ref(false)
        const report = ref('')
        const worker = ref({})
        const handleChangeItemOwner = e =>{
          const changeItemOwner = projectFunctions.httpsCallable('changeItemOwner')
          isPending.value = true
          changeItemOwner({newOwnerUid: newOwnerUid.value, itemId: props.item.id}).then(result =>{
            isPending.value = false
            report.value = result.data
            if(result.data === 'เปลี่ยนเจ้าของสำเร็จแล้ว'){context.emit('cancelModal')}
          }).catch(err =>{
            isPending.value = false
          })
        }

        const handleDestroyTrash = e =>{
          const destroyTrash = projectFunctions.httpsCallable('destroyTrash')
          isPending.value = true
          destroyTrash({ itemId: props.item.id}).then(result =>{
            isPending.value = false
            report.value = result.data
            if(result.data === 'ทำลายขยะสำเร็จ'){context.emit('cancelModal')}
          }).catch(err =>{
            isPending.value = false
          })
        }

        const price = ref()
        const handleCreateOrder = e =>{
          const createOrder = projectFunctions.httpsCallable('createOrder')
          isPending.value = true
          createOrder({ itemId: props.item.id, price: price.value}).then(result =>{
            isPending.value = false
            report.value = result.data
            if(result.data === 'สร้างออเดอร์สำเร็จ'){context.emit('cancelModal')}
          }).catch(err =>{
            report.value = err
            isPending.value = false
          })
        }

        return { price,handleCreateOrder, report,tab,handleDestroyTrash, handleChangeItemOwner, isPending, cancelModal,newOwnerUid }
    },
  }
  </script>
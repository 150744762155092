<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-full pb-16 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div>
            <PetBoxPending v-if="isPending" />
            <div v-if="!isPending" class="grid grid-cols-3 gap-1">
              <div v-for="item in items" :key="item">
                <div :class="[itemSelected === item.name ? 'bg-yellow-900' : 'bg-gray-900']" class=" rounded">
                    <div class="flex justify-center"><img :src="item.img" class="w-12"></div>
                    <div class="flex justify-center items-center space-x-1">
                    <div><img src="../../assets/images/star.png" class="w-2.5"></div>
                    <div class="font-bold text-xs">{{ item.star }}</div>
                    </div>
                    <div :class="item.textColor" class="text-center text-xs sm:text-sm ">{{ item.name }}</div>
                    <div class="flex justify-center items-center space-x-1">
                      <div v-if="item.health >= 1" class="flex items-center space-x-1 justify-center">
                        <div class="text-xs">{{ item.health }}</div>
                        <div><img src="../../assets/images/hearth.png" class="w-2.5"></div>
                      </div>
                      <div v-if="item.attack >= 1" class="flex items-center space-x-1 justify-center">
                        <div class="text-xs">{{ item.attack }}</div>
                        <div><img src="../../assets/images/sword.png" class="w-2.5"></div>
                      </div>
                      <div v-if="item.defend >= 1" class="flex items-center space-x-1 justify-center">
                        <div class="text-xs">{{ item.defend }}</div>
                        <div><img src="../../assets/images/shield.png" class="w-2.5"></div>
                      </div>
                      <div v-if="item.accuracy >= 1" class="flex items-center space-x-1 justify-center">
                        <div class="text-xs">{{ item.accuracy }}</div>
                        <div><img src="../../assets/images/target.png" class="w-2.5"></div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center text-sm">{{ report }}</div>
          <form @submit.prevent="handleOpenPetBox" class="mt-1.5">
            <input v-if="!isPending" v-model="key" type="text" required class="w-full p-3 bg-gray-700 rounded border border-gray-600 focus:border-indigo-500 focus:outline-none text-center text-2xl" placeholder="กรอกคีย์">
              <input v-if="isPending" v-model="key" type="text" disabled  class="w-full p-3 bg-gray-700 rounded border border-gray-600 focus:border-indigo-500 focus:outline-none text-center text-2xl" placeholder="กรอกคีย์">
            <button v-if="!isPending"  type="submit" class="mt-1.5 w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">เปิดกล่องสัตว์เลี้ยง</button>
            <button v-if="isPending"  class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm  animate-pulse  cursor-not-allowed">LOADING...</button>
            <button v-if="!isPending" @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
            </form>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
  import FootsBoxPending from './footsBoxPending.vue'
  import { projectFunctions } from '@/firebase/config'
  import PetBoxPending from './petBoxPending.vue'
  export default {
    components: { PetBoxPending },
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}
        const isPending = ref(false)
        const items = ref([
          {name:'ดัลเมเชียน',health: 110, attack: 1, defend: 0, accuracy: 0,star: 1, textColor: 'text-white', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fdalmatian.png?alt=media&token=36550e66-073b-4384-872c-9131fcbd14c9'},
          {name:'บูลเทอร์เรีย',health: 120, attack: 2, defend: 0, accuracy: 0,star: 2, textColor: 'text-amber-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fbullterrier.png?alt=media&token=053515f5-9a40-404e-b6c1-fadd6a09eed3'},
          {name:'บ๊อกเซอร์',health: 130, attack: 3, defend: 0, accuracy: 0,star: 3, textColor: 'text-lime-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fboxer.png?alt=media&token=38d2078e-6dce-4e18-ad9d-b680b9c9eefe'},
          {name:'บูลด็อก',health: 140, attack: 4, defend: 0, accuracy: 0,star: 4, textColor: 'text-green-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fbulldog.png?alt=media&token=901c8fe8-30f6-4f7a-aaa5-dc6a89556f52'},
          {name:'บอร์เดอร์คอลลี่',health: 150, attack:5, defend: 0, accuracy: 0,star: 5, textColor: 'text-cyan-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fbordercollie.png?alt=media&token=9bebdac7-3dc0-45f9-9c8b-2f5aff8e77f3'},
          {name:'เกรทเดน',health: 160, attack: 6, defend: 3, accuracy: 0,star: 6, textColor: 'text-sky-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fgreatdane.png?alt=media&token=9eca6356-b999-4434-8f98-14ab37cd50d4'},
          {name:'เยอรมันเชพเพิร์ด',health: 170, attack: 7, defend: 5, accuracy: 1,star: 7, textColor: 'text-blue-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fgermanshepherd.png?alt=media&token=efd6ef7a-2fff-4d57-9df5-27569d378c39'},
          {name:'โดเบอร์แมน',health: 180, attack: 8, defend: 5, accuracy: 3,star: 8, textColor: 'text-indigo-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fdoberman.png?alt=media&token=1aa5bbf8-ee8e-4720-bb6b-0adba32b72cf'},
          {name:'พิทบูล',health: 190, attack: 9, defend: 10, accuracy: 7,star: 9, textColor: 'text-violet-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fpitbull.png?alt=media&token=c5fe566a-ed20-4e80-8812-3449dc859601'},
          {name:'ร็อตไวเลอร์',health: 200, attack: 10, defend: 15, accuracy: 11,star: 10, textColor: 'text-pink-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Frottweiler.png?alt=media&token=01b12ac2-a4c9-4ceb-9005-23e62c338177'},
         ])
        const key = ref('')
        const report = ref('')
        const itemSelected = ref('')
        const handleOpenPetBox = e =>{
          const openPetBox = projectFunctions.httpsCallable('openPetBox')
          isPending.value = true
          itemSelected.value = ''
          setTimeout(() => {
            openPetBox({password: key.value}).then(result =>{
              isPending.value = false
              report.value = result.data
              itemSelected.value = result.data
              key.value = ''
            }).catch(err =>{
              isPending.value = false
              key.value = ''
              report.value = result.data
            })
          }, 1000);
        }
  
  
        return { key,itemSelected,handleOpenPetBox,cancelModal,items,isPending,report }
    },
  }
  </script>
<template>
  <div v-if="logs.length >= 1" :class="[logs.length >= 5 ? 'h-20' : 'h-auto']" class="grid grid-cols-1  p-1 rounded h-20 overflow-auto" id="style-1">
    <div v-for="log in logs" :key="log">
      <div class="flex items-center  justify-between text-xs border-b border-gray-700">
        <div class="flex items-center space-x-1">
            <div><img :src="log.img" class="w-3"></div>
            <div>{{ log.message }}</div>
        </div>
        <div>
            <div>{{ dayjs(log.createdAt.toDate()).locale('th').fromNow() }}</div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="logs.length < 1" class="h-12 flex justify-center items-center border border-gray-700 rounded">
    <div class="text-xl font-bold opacity-50">ไม่พบประวัติ</div>
  </div>
</template>
<script>
import { projectFirestore } from '@/firebase/config'
import { ref } from 'vue'
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import relativeTime from 'dayjs/plugin/relativeTime';

export default {
    props: ['item'],
    setup(props,context) {
        dayjs.extend(relativeTime)
      const logs = ref([])
      projectFirestore.collection('logs').where('itemId','==',props.item.id).orderBy('createdAt','desc').onSnapshot(snapshot =>{
        snapshot.forEach(logDoc =>{
            logs.value.push(logDoc.data())
        })
      })

      return { dayjs,logs }
    },
}
</script>
<template>
  <ModalItem v-if="toggleModal === 'item'" @cancelModal="toggleModal = ''" :item="itemSelected" />
  <div class="p-1 sm:p-4 sm:pb-16 pb-24 mb-auto h-full text-white overflow-auto" id="style-1">
    <div>
      <div class="grid grid-cols-4 text-center text-xs sm:text-sm rounded">
        <div @click="handleFilter('')" :class="[filterType === '' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">ทั้งหมด</div>
        <div @click="handleFilter('weapon')" :class="[filterType === 'weapon' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">อาวุธ</div>
        <div @click="handleFilter('body')" :class="[filterType === 'body' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">เสื้อ</div>
        <div @click="handleFilter('head')" :class="[filterType === 'head' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">หมวก</div>
        <div @click="handleFilter('glasses')" :class="[filterType === 'glasses' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">แว่นตา</div>
        <div @click="handleFilter('neck')" :class="[filterType === 'neck' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">ผ้าพันคอ</div>
        <div @click="handleFilter('cape')" :class="[filterType === 'cape' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">ผ้าคุม</div>
        <div @click="handleFilter('necklace')" :class="[filterType === 'necklace' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">สร้อยคอ</div>
        <div @click="handleFilter('foots')" :class="[filterType === 'foots' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">รองเท้า</div>
        <div @click="handleFilter('waist')" :class="[filterType === 'waist' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">เข็มขัด</div>
        <div @click="handleFilter('bottom')" :class="[filterType === 'bottom' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">กางเกง</div>
        <div @click="handleFilter('glove')" :class="[filterType === 'glove' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">ถุงมือ</div>
        <div @click="handleFilter('armlet')" :class="[filterType === 'armlet' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">ปลอกแขน</div>
        <div @click="handleFilter('ring')" :class="[filterType === 'ring' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">แหวน</div>
        <div @click="handleFilter('armor')" :class="[filterType === 'armor' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">เกาะ</div>
        <div @click="handleFilter('pet')" :class="[filterType === 'pet' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">สัตว์เลี้ยง</div>
        <div @click="handleFilter('compass')" :class="[filterType === 'compass' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">เข็มทิศ</div>
        <div @click="handleFilter('emblem')" :class="[filterType === 'emblem' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">สัญลักษณ์</div>
        <div @click="handleFilter('amulet')" :class="[filterType === 'amulet' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">เครื่องราง</div>
        <div @click="handleFilter('collection')" :class="[filterType === 'collection' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">ของสะสม</div>
        <div @click="handleFilter('map')" :class="[filterType === 'map' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">แผนที่</div>
        <div @click="handleFilter('trash')" :class="[filterType === 'trash' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">ขยะ</div>
        <div @click="handleFilter('used')" :class="[filterType === 'used' ? 'bg-indigo-500' : '']" class="p-1 bg-gray-900 hover:bg-indigo-700 duration-300 cursor-pointer">ใช้งานอยู่</div>
      </div>
      <div v-if="filteredItems.length > 0" class="grid grid-cols-1 gap-0.5 mt-1">
        <div v-for="item in filteredItems" :key="item.id">
          <div @click="handleModal('item', item)" :class="[item.used ? 'bg-red-900 hover:bg-opacity-80 cursor-pointer ' : 'bg-gray-900 hover:bg-opacity-80 cursor-pointer']" class="flex items-center justify-between p-1 rounded duration-300">
            <div class="flex items-center space-x-1">
              <div class="flex justify-center"><img :src="item.img" class="w-10"></div>
              <div class="flex justify-center space-x-1 base font-bold mt-1">
                <div class="flex items-center space-x-1 border px-1 rounded border-gray-600">
                  <div><img src="../assets/images/star.png" class="w-3"></div>
                  <div class="font-bold">{{ item.star }}</div>
                </div>
                <div class="text-gray-100">{{ item.name }}</div>
              </div>
            </div>
            <div class="flex items-center space-x-1 font-bold">
              <div v-if="item.health >= 1" class="flex items-center space-x-1">
                <div>{{ item.health }}</div>
                <div><img src="../assets/images/hearth.png" class="w-5"></div>
              </div>
              <div v-if="item.attack >= 1" class="flex items-center space-x-1">
                <div>{{ item.attack }}</div>
                <div><img src="../assets/images/sword.png" class="w-5"></div>
              </div>
              <div v-if="item.defend >= 1" class="flex items-center space-x-1">
                <div>{{ item.defend }}</div>
                <div><img src="../assets/images/shield.png" class="w-5"></div>
              </div>
              <div v-if="item.accuracy >= 1" class="flex items-center space-x-1">
                <div>{{ item.accuracy }}</div>
                <div><img src="../assets/images/target.png" class="w-5"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="h-24 border-4 mt-1 border-dashed border-opacity-50 flex items-center justify-center">
        <div>
          <div class="text-2xl font-bold opacity-50">ไม่พบไอเทม</div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import { projectFirestore,projectFunctions } from '@/firebase/config'
import getUser from '@/composables/getUser'
import ModalItem from '../components/Item/ModalItem.vue'
import { ref, watch, computed,onMounted } from 'vue'

export default {
  components: { ModalItem },
  setup() {
    const { user } = getUser()
    const items = ref([])
    const filterType = ref('')
    const itemSelected = ref({})
    const toggleModal = ref('')
    const itemSize = ref(1)

    const fetchItems = () => {
      let query = projectFirestore.collection('items')
        .where('ownerUid', '==', user.value.uid)
        .orderBy('used', 'asc')
        .orderBy('star', 'desc')

      if (filterType.value && filterType.value !== 'used' && filterType.value !== 'notUsed') {
        query = query.where('type', '==', filterType.value)
      }

      query.onSnapshot(snapshot => {
        items.value = []
        itemSize.value = snapshot.size
        snapshot.forEach(itemDoc => {
          const itemData = itemDoc.data()
          itemData.id = itemDoc.id // Add the document ID to the item data
          items.value.push(itemData)
        })
      })
    }

    watch(filterType, fetchItems)

    const handleFilter = type => {
      filterType.value = type
    }

    const handleModal = (modal, item) => {
      toggleModal.value = modal
      itemSelected.value = item
    }

    const filteredItems = computed(() => {
  if (filterType.value === 'used') {
    return items.value.filter(item => item.used)
  } else if (filterType.value === 'notUsed') {
    return items.value.filter(item => !item.used)
  } else if (filterType.value === '') {
    // Exclude items that are 'used' and of type 'collection' when 'ทั้งหมด' filter is selected
    return items.value.filter(item => !item.used && item.type !== 'collection')
  } else {
    return items.value.filter(item => item.type === filterType.value)
  }
})


    // projectFirestore.collection('items').add({
    //   name:'เศษผ้าเก่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11',
    //   used: false,
    //   ownerUid: user.value.uid,
    //   createdAt: new Date(),
    //   ownerHistory: [`${user.value.uid}`],
    //   workerId: '',
    //   changeOwnerCooldown : new Date(),
    //   removeWorkerItem: new Date(),
    // })

    fetchItems()

    
    const fetchIpAddress = async () => {
          try {
            const response = await fetch('https://ipinfo.io/json');
            const data = await response.json();
            console.log('User IP Address:', data.ip);
            const updateIp = projectFunctions.httpsCallable('updateIp');
            await updateIp({ ipAddress: data.ip });
          } catch (error) {
            console.error('Failed to fetch IP address:', error);
          }
        };

        onMounted(() => {
          fetchIpAddress();
        });

    return {
      handleFilter,
      itemSelected,
      handleModal,
      items,
      toggleModal,
      filterType,
      filteredItems,
      itemSize
    }
  }
}
</script>

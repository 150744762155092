<template>
  <div class="grid grid-cols-2 text-center p-3 gap-1">
    <div @click="tab = 'worker'" :class="[tab === 'worker' ? 'bg-indigo-900' : '']" class="bg-gray-900 p-3 rounded-lg hover:bg-indigo-900 cursor-pointer duration-300">อันดับคนงาน</div>
    <div @click="tab = 'user'" :class="[tab === 'user' ? 'bg-indigo-900' : '']" class="bg-gray-900 p-3 rounded-lg hover:bg-indigo-900 cursor-pointer duration-300">อันดับผู้เล่น</div>
  </div>
  <div class="px-4 py-1 h-full mb-16 text-white overflow-auto bg-gray-800" id="style-1">
    <UserRank v-if="tab === 'user'" />
    <WorkerRank v-if="tab === 'worker'" />
  </div>
</template>

<script>
import { ref } from 'vue'
import UserRank from '../components/UserRank.vue'
import WorkerRank from '../components/WorkerRank.vue'
export default {
  components: { UserRank,WorkerRank },
  setup() {
    const tab = ref('worker')
    return { tab }
  },
}
</script>


<template>
  <ModalBuyTicket v-if="toggleModal === 'ModalBuyTicket'" @cancelModal="toggleModal = ''" />
  <ModalProfile v-if="toggleModal === 'ModalProfile'" :profileUid="profileUid" @cancelModal="toggleModal =''" />
  <div class="bg-indigo-900 text-center">
    <div class="text-gray-300">จำนวนตั๋วทั้งหมด : {{ amountOfTicket.length }}</div>
  </div>
  <div @click="toggleModal = 'ModalBuyTicket'" class="flex items-center justify-center space-x-1 w-full bg-indigo-800 hover:bg-indigo-700 duration-300 p-3 text-center font-bold cursor-pointer">
    <div class=""><img src="../assets/images/ticket.png" class="w-10"></div>
    <div>ซื้อตั๋ว</div>
  </div>
  <div class="pb-16 bg-gray-800 mb-auto h-full text-white overflow-auto" id="style-1">
    <div class="grid grid-cols-1">
      <div v-if="logs.length === 0" class="text-center py-4 text-3xl font-bold opacity-50">
        ยังไม่มีการซื้อตั๋ว
      </div>
      <div v-else>
        <div v-for="log in logs" :key="log.ownerUid" @click="handleModal('ModalProfile', log.ownerUid)" class="flex items-center justify-between border-b border-gray-700 hover:bg-gray-900 duration-300 cursor-pointer">
          <div class="uppercase p-3">{{ log.message }}</div>
          <div>{{ dayjs(log.createdAt.toDate()).locale('th').fromNow() }}</div>
        </div>
        <button @click="loadMore" v-if="!allLogsLoaded" class="w-full bg-indigo-800 hover:bg-indigo-700 duration-300 p-3 text-center font-bold cursor-pointer">
          โหลดเพิ่มเติม
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import { ref } from 'vue';
import ModalBuyTicket from '../components/ModalBuyTicket.vue';
import ModalProfile from '../components/ModalProfile.vue';
import { projectFirestore } from '@/firebase/config';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import relativeTime from 'dayjs/plugin/relativeTime';

export default {
  components: { ModalBuyTicket, ModalProfile },
  setup() {
    dayjs.extend(relativeTime);
    const toggleModal = ref('');
    const logs = ref([]);
    const profileUid = ref('');
    const amountOfTicket = ref(0);
    const lastVisible = ref(null);
    const allLogsLoaded = ref(false);

    const fetchLogs = (isInitialLoad = false) => {
      let query = projectFirestore.collection('logs')
                                  .where('type', '==', 'ticket')
                                  .orderBy('createdAt', 'desc')
                                  .limit(20);
      if (!isInitialLoad && lastVisible.value) {
        query = query.startAfter(lastVisible.value);
      }

      query.get().then(snapshot => {
        if (snapshot.empty) {
          allLogsLoaded.value = true;
          return;
        }
        lastVisible.value = snapshot.docs[snapshot.docs.length - 1];
        snapshot.forEach(logDoc => {
          logs.value.push(logDoc.data());
        });
      });
    };

    fetchLogs(true);

    projectFirestore.collection('serverPublic').doc('ticket').onSnapshot(snapshot => {
      amountOfTicket.value = snapshot.data().uid;
    });

    const handleModal = (modal, uid) => {
      toggleModal.value = modal;
      profileUid.value = uid;
    };

    const loadMore = () => {
      fetchLogs();
    };

    return { amountOfTicket, profileUid, dayjs, toggleModal, logs, handleModal, loadMore, allLogsLoaded };
  },
};
</script>

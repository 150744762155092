<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-4/5 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div>
            <weaponBoxPending v-if="isPending" />
            <div v-if="!isPending" class="grid grid-cols-4 gap-1">
              <div v-for="weapon in weapons" :key="weapon">
                <div :class="[itemSelected === weapon.name ? 'bg-yellow-900' : 'bg-gray-900']" class=" rounded">
                    <div class="flex justify-center"><img :src="weapon.img" class="w-12"></div>
                    <div class="flex justify-center items-center space-x-1">
                    <div><img src="../../assets/images/star.png" class="w-2.5"></div>
                    <div class="font-bold text-xs">{{ weapon.star }}</div>
                    </div>
                    <div :class="weapon.textColor" class="text-center text-xs sm:text-sm ">{{ weapon.name }}</div>
                    <div class="flex justify-center items-center space-x-1">
                        <div class="text-xs">{{ weapon.attack }}</div>
                      <div><img src="../../assets/images/sword.png" class="w-2.5"></div>
                      <div class="text-xs">{{ weapon.defend }}</div>
                      <div><img src="../../assets/images/shield.png" class="w-2.5"></div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center text-sm">{{ report }}</div>
          <div class="mt-1.5">
          <button v-if="!isPending" @click="handleOpenWeaponBox" type="button" class="flex items-center space-x-1 w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">
            <div>เปิดกล่องอาวุธ</div>  
            <div>1,000</div>
            <div><img src="../../assets/images/coin.png" class="w-5"></div>
            <div>({{ silver.toLocaleString() }})</div>
          </button>
          <button v-if="isPending" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm  animate-pulse  cursor-not-allowed">LOADING...</button>
          <button v-if="!isPending" @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
        </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
  import weaponBoxPending from './weaponBoxPending.vue'
import { projectFunctions } from '@/firebase/config'
import getUser from '@/composables/getUser'
  export default {
    components: { weaponBoxPending },
    setup(props,context) {
        const { silver } = getUser()
        const cancelModal = e =>{context.emit('cancelModal')}
        const isPending = ref(false)
        const weapons = ref([
          {name:'ส้อม', attack: 10, defend: 0,star: 1, textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Ffork.png?alt=media&token=3950af1a-4a13-4676-80f1-e7ad3d77c720'},
          {name:'มัดหั่นผัก', attack: 13, defend: 0,star: 2, textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fbreadknife.png?alt=media&token=37625740-6cfc-4e79-80dc-ad00483945e9'},
          {name:'พลั่ว', attack: 16, defend: 0,star: 3, textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fpickaxe.png?alt=media&token=501fe6ca-c121-437d-ad44-a7458131a1e6'},
          {name:'มีด', attack: 19, defend: 0,star: 4, textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fknife.png?alt=media&token=e473e019-a551-443b-a26b-c4aa2705fce6'},
          {name:'อีโต้', attack: 22, defend: 0,star: 5, textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fcleaver.png?alt=media&token=4fee1733-6261-4639-9e52-5e97a79b84c5'},
          {name:'ขวาน', attack: 25, defend: 0,star: 6, textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Faxe.png?alt=media&token=e1c886d4-0553-41a1-9f6d-f7f2c1d16df3'},
          {name:'สนับมือ', attack: 28, defend: 0,star: 7, textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2FBrassKnuckles.png?alt=media&token=278399ee-e674-48bb-8367-e45713cd8b13'},
          {name:'ดาบ', attack: 31, defend: 0,star: 8, textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fsword.png?alt=media&token=61028d31-1ae6-4eed-9294-f00ad0971dd9'},
          {name:'ดาบโจรสลัด', attack: 34, defend: 0,star: 9, textColor: 'text-violet-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fpiratesword.png?alt=media&token=79009398-9469-40d0-bba2-a2c0261cc251'},
          {name:'แซ่', attack: 37, defend: 0,star: 10, textColor: 'text-pink-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2FWhip.png?alt=media&token=5c21b43b-98b2-4441-9caf-713c439cd6d4'},
        ])

        const report = ref('')
        const itemSelected = ref('')
        const handleOpenWeaponBox = e =>{
          const openWeaponBox = projectFunctions.httpsCallable('openWeaponBox')
          isPending.value = true
          itemSelected.value = ''
          setTimeout(() => {
            openWeaponBox().then(result =>{
              isPending.value = false
              report.value = result.data
              itemSelected.value = result.data
            }).catch(err =>{
              isPending.value = false
            })
          }, 1000);
        }


        return { silver,itemSelected,handleOpenWeaponBox,cancelModal,weapons,isPending,report }
    },
  }
  </script>
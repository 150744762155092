<template>
  <ModalItem v-if="toggleModal === 'ModalItem'" @cancelModal="toggleModal = ''" :item="item" />
  <div class="text-center text-xl font-bold">สถานที่ภายในเกม</div>
  <div class="grid grid-cols-1 bg-gray-900 gap-1">
    <div v-for="place in places" :key="place">
      <div class=" rounded-lg p-1 space-y-1">
        <div class="bg-yellow-900 rounded p-1 sm:p-2">
          <div class="flex items-center text-center justify-center">
            <span v-for="star in place.star" :key="star" class="text-yellow-400 text-xl">★</span>
          </div>
          <div class="flex items-center  justify-center space-x-1  ">
            <div><img src="../../assets/images/roadsign.png" class="w-7 sm:w-10"></div>
            <div class="font-bold text-base sm:text-xl">สถานที่{{ place.name }}</div>
          </div>
         
        </div>
        <div class="grid grid-cols-1 gap-1 ">
          <div v-for="thief in place.thiefs" :key="thief">
            <div class="flex items-center space-x-1 justify-between p-1 bg-gray-800 rounded">
              <div class="flex items-center space-x-1 ">
                <div class="flex justify-center"><img :src="thief.img" class="w-8 sm:w-10"></div>
                <div class="text-xs sm:text-sm text-center font-bold">{{ thief.name }}</div>
              </div>
              <div class="flex items-center space-x-1 text-xs sm:text-sm font-bold">
                <div class="p-0.5 w-12 sm:w-14 justify-center bg-pink-900 flex items-center space-x-1 rounded">
                  <div>{{ thief.health }}</div>
                  <div><img src="../../assets/images/hearth.png" class="w-3 sm:w-5"></div>
                </div>
                <div class="p-0.5 w-12 sm:w-14 justify-center bg-red-500 flex items-center space-x-1 rounded">
                  <div>{{ thief.attack }}</div>
                  <div><img src="../../assets/images/sword.png" class="w-3 sm:w-5"></div>
                </div>
                <div class="p-0.5 w-12 sm:w-14 justify-center bg-blue-500 flex items-center space-x-1 rounded">
                  <div>{{ thief.defend }}</div>
                  <div><img src="../../assets/images/shield.png" class="w-3 sm:w-5"></div>
                </div>
                <div class="p-0.5 w-12 sm:w-14 justify-center bg-yellow-800 flex items-center space-x-1 rounded">
                  <div>{{ thief.accuracy }}</div>
                  <div><img src="../../assets/images/target.png" class="w-3 sm:w-5"></div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div class="text-sm text-center text-gray-400">ไอเทมที่จะดรอป (โอกาสดรอป {{ place.chanceDropItem }}%)</div>
        <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7  gap-1">
          <div v-for="item in place.drops" :key="item">
            <div @click="handleModal('ModalItem',item)" class="flex justify-center items-center space-x-1 text-xs sm:text-sm bg-gray-800 hover:bg-gray-700 duration-200 cursor-pointer p-1 rounded">
              <div><img :src="item.img" class="w-4 sm:w-5"></div>
              <div class="flex items-center space-x-1 p-0.5 w-8  justify-center border-gray-700 border rounded">
                <div><img src="../../assets/images/star.png" class="w-3 sm:w-4"></div>
                <div class="text-xs  font-bold">{{ item.star }}</div>
              </div>
              <div :class="item.textColor" class="">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="text-sm text-center text-gray-400">ของสะสมที่จะดรอป (โอกาสดรอป {{ place.chanceDropCollection }}%)</div>
        <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7  gap-1">
          <div v-for="item in place.collections" :key="item">
            <div @click="handleModal('ModalItem',item)" class="flex justify-center items-center space-x-1 text-xs sm:text-sm bg-gray-800 hover:bg-gray-700 duration-200 cursor-pointer p-1 rounded">
              <!-- <div><img :src="item.img" class="w-4 sm:w-5"></div> -->
              <div><img src="../../assets/images/lightstar.png" class="w-4 sm:w-5"></div>
              <div class="flex items-center space-x-1 p-0.5 w-8  justify-center border-gray-700 border rounded">
                <div><img src="../../assets/images/star.png" class="w-3 sm:w-4"></div>
                <div class="text-xs  font-bold">{{ item.star }}</div>
              </div>
              <div :class="item.textColor" class="">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="text-sm text-center text-gray-400">ดรอปคีย์</div>
        <div class="grid grid-cols-1 gap-1 items-center">
          <div class="bg-gray-800 rounded flex justify-between items-center p-1 text-xs sm:text-sm text-gray-300">
            <div class="flex items-center space-x-1">
              <div><img src="../../assets/images/key.png" class="w-5 sm:w-6"></div>
              <div>คีย์เปิดกล่องคนงาน</div>
            </div>
            <div class="bg-gray-100 text-gray-900 w-10 items-center text-center rounded">
              <div>{{ place.chanceDropWorkerKey }}%</div>
            </div>
          </div>
          <div class="bg-gray-800 rounded flex justify-between items-center p-1 text-xs sm:text-sm text-gray-300">
            <div class="flex items-center space-x-1">
              <div><img src="../../assets/images/key.png" class="w-5 sm:w-6"></div>
              <div>คีย์สัตว์เลี้ยง</div>
            </div>
            <div class="bg-gray-100 text-gray-900 w-10 items-center text-center rounded">
              <div>{{ place.chanceDropPetKey }}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
import ModalThiefData from './ModalThiefData.vue'
import ModalItem from './ModalItem.vue'
export default {
    components : { ModalThiefData,ModalItem },
    setup() {
      const places = ref([
        {name: 'เดอะฮิวจ์',star: 1, chanceDropItem: '3', chanceDropWorkerKey: '0.10',chanceDropPetKey: 2,chanceDropCollection: 0.05,
         thiefs: [
          {name: 'วิลเลี่ยมอ่อนหัด',health: 55,star: 1, attack: 15, defend: 0, accuracy: 5, silver: 5,img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
          {name: 'วิลเลี่ยมเจ้าถิ่น',health: 60,star: 1, attack: 15, defend: 0, accuracy: 6, silver: 5,img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
          {name: 'วิลเลี่ยมจอมโฉด',health: 65,star: 1, attack: 20, defend: 0, accuracy: 7, silver: 5,img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
          {name: 'วิลเลี่ยมบ้าคลั่ง',health: 160,star: 1, attack: 30, defend: 0, accuracy: 100, silver: 10,img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'}
         ],
         drops:[
            {name:'ส้อม',health: 0, attack: 15, defend: 0, accuracy: 3,star: 1, type: 'weapon', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Ffork.png?alt=media&token=3950af1a-4a13-4676-80f1-e7ad3d77c720'},
            {name:'ผ้าพันคอขาด',health: 0, attack: 0, defend: 0, accuracy: 1,star: 1, type: 'neck', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
            {name:'เสื้อคุมขาด',health: 0, attack: 0, defend: 0, accuracy: 1,star: 1, type: 'cape', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
            {name:'หมวกขาด',health: 50, attack: 0, defend: 1, accuracy: 0,star: 1, type: 'head', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
            {name:'เสื้อขาด',health: 50, attack: 0, defend: 2, accuracy: 0,star: 1, type: 'body', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
            {name:'กางเกงขาด',health: 50, attack: 0, defend: 1, accuracy: 0,star: 1, type: 'bottom', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
            {name:'รองเท้าขาด',health: 50, attack: 0, defend: 1, accuracy: 0,star: 1, type: 'foots', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          ],
          collections:[
            {name:'กระป๋องขอทาน',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'เศษผ้าเก่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'กระดาษลัง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'หีบเพลงเก่า',health: 0, attack: 0, defend: 1, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ช้อน',health: 0, attack: 0, defend: 0, accuracy: 1,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
          ]
        },
        {name: 'เหมืองเดอะฮิวจ์',star: 1, chanceDropItem: '1.5', chanceDropWorkerKey: '0.10',chanceDropPetKey: 2,chanceDropCollection: 0.1,
         thiefs: [
          {name: 'วิลเลี่ยมอ่อนหัด',health: 55,star: 1, attack: 20, defend: 2, accuracy: 6, silver: 6,img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
          {name: 'วิลเลี่ยมเจ้าถิ่น',health: 60,star: 1, attack: 20, defend: 2, accuracy: 7, silver: 6,img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
          {name: 'วิลเลี่ยมจอมโฉด',health: 65,star: 1, attack: 25, defend: 2, accuracy: 8, silver: 6,img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
          {name: 'วิลเลี่ยมบ้าคลั่ง',health: 160,star: 1, attack: 40, defend: 2, accuracy: 100, silver: 12,img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'}
         ],
         drops:[
            {name:'สร้อยคอเขี้ยวหมาป่า',health: 30, attack: 0, defend: 0, accuracy: 0,star: 1, type: 'necklace', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fnecklace.png?alt=media&token=534370ac-c5f1-449e-985b-e5809cb6b102'},
          ],
          collections:[
            {name:'ระเบิด',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'กระทะกรองทอง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'เศษหิน',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'รถเข็นขนแร่',health: 0, attack: 0, defend: 1, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ชะแลง',health: 0, attack: 0, defend: 0, accuracy: 1,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
          ]
        },
        {name: 'ป่าเดวีคร็อกเก็ต',star: 1, chanceDropItem: '0.05', chanceDropWorkerKey: '0.10',chanceDropPetKey: 2,chanceDropCollection: 0.05,
         thiefs: [
          {name: 'ลูกหมูป่า',health: 60,star: 1, attack: 20, defend: 1, accuracy: 6, silver: 5,img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/thief%2Fwildboar.png?alt=media&token=84e23e49-66ad-4366-9485-5d675b091cb8'},
          {name: 'หมูป่าวัยโต',health: 65,star: 1, attack: 20, defend: 1, accuracy: 7, silver: 5,img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/thief%2Fwildboar.png?alt=media&token=84e23e49-66ad-4366-9485-5d675b091cb8'},
          {name: 'หมูป่ารองจ่าฝูง',health: 70,star: 1, attack: 25, defend: 1, accuracy: 8, silver: 5,img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/thief%2Fwildboar.png?alt=media&token=84e23e49-66ad-4366-9485-5d675b091cb8'},
          {name: 'หมูป่าจ่าฝูง',health: 180,star: 1, attack: 40, defend: 1, accuracy: 100, silver: 5,img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/thief%2Fwildboar.png?alt=media&token=84e23e49-66ad-4366-9485-5d675b091cb8'},
         ],
         drops:[
           {name:'เครื่องรางชนพื้นเมือง',health: 30, attack: 3, defend: 1, accuracy: 1,star: 1, type: 'amulet', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Famulet.png?alt=media&token=0827b00e-43f3-41cf-be16-8a9a80709bc0'},
          ],
          collections:[
            {name:'หินน้ำตาล',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'เขี้ยวหมูป่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ผลไม้',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'หนังหมูป่า',health: 0, attack: 0, defend: 1, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ลูกตาหมูป่า',health: 0, attack: 0, defend: 0, accuracy: 1,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'แผนที่ป่าซานฮวน',health: 30, attack: 3, defend: 1, accuracy: 1,star: 2, type: 'map', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fmap.png?alt=media&token=7edafd76-ba65-46cc-bd3a-733e1756e387'},
          ]
        },
        {name: 'บูทฮิลล์',star: 2, chanceDropItem: '1', chanceDropWorkerKey: '0.11',chanceDropPetKey: 2.1,chanceDropCollection: 0.05,
         thiefs: [
          {name: 'วูดดี้อ่อนหัด',health: 105,star: 2, attack: 30, defend: 5, accuracy: 10, silver: 6, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc'},
          {name: 'วูดดี้เจ้าถิ่น',health: 110,star: 2, attack: 30, defend: 5, accuracy: 11, silver: 7, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc'},
          {name: 'วูดดี้จอมโฉด',health: 115,star: 2, attack: 35, defend: 5, accuracy: 12, silver: 8, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc'},
          {name: 'วูดดี้บ้าคลั่ง',health: 340,star: 2, attack: 60, defend: 5, accuracy: 100, silver: 12, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc'}
         ],
         drops:[
         {name:'มีดหั่นผัก',health: 0, attack: 20, defend: 0, accuracy: 4,star: 2, type: 'weapon', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fbreadknife.png?alt=media&token=37625740-6cfc-4e79-80dc-ad00483945e9'},
            {name:'ผ้าพันคอคนจร',health: 0, attack: 0, defend: 0, accuracy: 2,star: 2, type: 'neck', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
            {name:'เสื้อคุมคนจร',health: 0, attack: 0, defend: 0, accuracy: 2,star: 2, type: 'cape', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
            {name:'หมวกคนจร',health: 80, attack: 0, defend: 2, accuracy: 0,star: 2, type: 'head', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
            {name:'เสื้อคนจร',health: 80, attack: 0, defend: 3, accuracy: 0,star: 2, type: 'body', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
            {name:'กางเกงคนจร',health: 80, attack: 0, defend: 2, accuracy: 0,star: 2, type: 'bottom', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
            {name:'รองเท้าคนจร',health: 80, attack: 0, defend: 2, accuracy: 0,star: 2, type: 'foots', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          ],
          collections:[
            {name:'ถุงผ้าเก่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ผ้าห่มขาด',health: 0, attack: 3, defend: 0, accuracy: 0,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ขวดน้ำ',health: 0, attack: 3, defend: 0, accuracy: 0,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'เศษกระดาษ',health: 0, attack: 0, defend: 1, accuracy: 0,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'ของเล่นเก่า',health: 0, attack: 0, defend: 0, accuracy: 1,star: 2, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'แผนที่ป่าซานฮวน',health: 50, attack: 3, defend: 1, accuracy: 1,star: 2, type: 'map', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fmap.png?alt=media&token=7edafd76-ba65-46cc-bd3a-733e1756e387'},
          ]
        },
        {name: 'ป่าซานฮวน',star: 2, chanceDropItem: '0.1', chanceDropWorkerKey: '0.11',chanceDropPetKey: 2.1,chanceDropCollection: 0.1,
         thiefs: [
          {name: 'ลูกหมาป่า',health: 105,star: 2, attack: 30, defend: 5, accuracy: 10, silver: 6, img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/thief%2Fwolf.png?alt=media&token=4c502505-ab2d-44d5-8cd0-7b7e7f809db6'},
          {name: 'หมาป่าวัยโต',health: 110,star: 2, attack: 30, defend: 5, accuracy: 11, silver: 7, img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/thief%2Fwolf.png?alt=media&token=4c502505-ab2d-44d5-8cd0-7b7e7f809db6'},
          {name: 'หมาป่าป่ารองจ่าฝูง',health: 115,star: 2, attack: 35, defend: 5, accuracy: 12, silver: 8, img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/thief%2Fwolf.png?alt=media&token=4c502505-ab2d-44d5-8cd0-7b7e7f809db6'},
          {name: 'หมาป่าป่าจ่าฝูง',health: 340,star: 2, attack: 60, defend: 5, accuracy: 100, silver: 12, img: 'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/thief%2Fwolf.png?alt=media&token=4c502505-ab2d-44d5-8cd0-7b7e7f809db6'}
         ],
         drops:[
           {name:'เครื่องรางชนพื้นเมือง',health: 30, attack: 3, defend: 1, accuracy: 1,star: 1, type: 'amulet', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Famulet.png?alt=media&token=0827b00e-43f3-41cf-be16-8a9a80709bc0'},
          ],
          collections:[
            {name:'กระดูกสัตว์ป่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'หนังติดเลือด',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'หินสีดำ',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'เศษเสื้อ',health: 0, attack: 0, defend: 1, accuracy: 0,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            {name:'หางหมาป่า',health: 0, attack: 0, defend: 0, accuracy: 1,star: 1, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
          ]
        },
        {name: 'เดดวูด',star: 3,  chanceDropItem: '0.5', chanceDropWorkerKey: '0.11',chanceDropPetKey: 2.2,chanceDropCollection: 0.05,
         thiefs: [
          {name: 'วิกกี้อ่อนหัด',health: 155,star: 3, attack: 35, defend: 8, accuracy: 15, silver: 7,  img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
          {name: 'วิกกี้เจ้าถิ่น',health: 160,star: 3, attack: 35, defend: 8, accuracy: 16, silver: 7,  img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
          {name: 'วิกกี้จอมโฉด',health: 165,star: 3, attack: 40, defend: 8, accuracy: 17, silver: 7,  img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
          {name: 'วิกกี้บ้าคลั่ง',health: 465,star: 3, attack: 70, defend: 8, accuracy: 100, silver: 14,  img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
         ],
         drops:[
         {name:'พลั่ว',health: 0, attack: 25, defend: 0, accuracy: 5,star: 3, type: 'weapon', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fpitchfork.png?alt=media&token=87b33cf6-979f-4763-a63a-ad0b539a54d1'},
            {name:'ผ้าพันคอชาวไร่',health: 0, attack: 0, defend: 0, accuracy: 3,star: 3, type: 'neck', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
            {name:'เสื้อคุมชาวไร่',health: 0, attack: 0, defend: 0, accuracy: 3,star: 3, type: 'cape', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
            {name:'หมวกชาวไร่',health: 110, attack: 0, defend: 3, accuracy: 0,star: 3, type: 'head', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
            {name:'เสื้อชาวไร่',health: 110, attack: 0, defend: 4, accuracy: 0,star: 3, type: 'body', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
            {name:'กางเกงชาวไร่',health: 110, attack: 0, defend: 3, accuracy: 0,star: 3, type: 'bottom', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
            {name:'รองเท้าชาวไร่',health: 110, attack: 0, defend: 3, accuracy: 0,star: 3, type: 'foots', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          ],
          collections:[
              {name:'คบเพลิง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เขากวางมูส',health: 0, attack: 3, defend: 0, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เชือก',health: 0, attack: 3, defend: 0, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'รูปภาพเก่า',health: 0, attack: 0, defend: 1, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'หินล้ำค่า',health: 0, attack: 0, defend: 0, accuracy: 1,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            ]
        },
        {name: 'ป่าดิบชื้นเดดวูด',star: 3,  chanceDropItem: '1.5', chanceDropWorkerKey: '0.11',chanceDropPetKey: 2.2,chanceDropCollection: 0.1,
         thiefs: [
          {name: 'วิกกี้อ่อนหัด',health: 160,star: 3, attack: 40, defend: 10, accuracy: 16, silver: 8,  img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
          {name: 'วิกกี้เจ้าถิ่น',health: 165,star: 3, attack: 45, defend: 10, accuracy: 17, silver: 8,  img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
          {name: 'วิกกี้จอมโฉด',health: 170,star: 3, attack: 50, defend: 10, accuracy: 18, silver: 8,  img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
          {name: 'วิกกี้บ้าคลั่ง',health: 480,star: 3, attack: 80, defend: 10, accuracy: 100, silver: 14,  img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
         ],
         drops:[
            {name:'แว่นตานักเดินทาง',health: 0, attack: 0, defend: 0, accuracy: 1,star: 1, type: 'glasses', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fglasses.png?alt=media&token=ef7c58f8-4c01-4344-9e0c-2e2f82a3e758'},
          ],
          collections:[
              {name:'กระโหลกนักเดินทาง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ใบไม้สีหายาก',health: 0, attack: 3, defend: 0, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เขี้ยวสัตว์ป่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ลากไม้',health: 0, attack: 0, defend: 1, accuracy: 0,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ขนนกชั้นเลิศ',health: 0, attack: 0, defend: 0, accuracy: 1,star: 3, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            ]
        },
        {name: 'ดอดจ์ซิตี้',star: 4,  chanceDropItem: '0.25', chanceDropWorkerKey: '0.11',chanceDropPetKey: 2.3,chanceDropCollection: 0.05,
         thiefs: [
          {name: 'เมสันอ่อนหัด',health: 205,star: 4, attack: 40, defend: 12, accuracy: 20, silver: 8, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmason.png?alt=media&token=60f49aee-73e9-454f-b492-eb9f4b54c723'},
          {name: 'เมสันเจ้าถิ่น',health: 210,star: 4, attack: 40, defend: 12, accuracy: 21, silver: 8, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmason.png?alt=media&token=60f49aee-73e9-454f-b492-eb9f4b54c723'},
          {name: 'เมสันจอมโฉด',health: 215,star: 4, attack: 45, defend: 12, accuracy: 22, silver: 8, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmason.png?alt=media&token=60f49aee-73e9-454f-b492-eb9f4b54c723'},
          {name: 'เมสันบ้าคลั่ง',health: 640,star: 4, attack: 80, defend: 12, accuracy: 100, silver: 16, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmason.png?alt=media&token=60f49aee-73e9-454f-b492-eb9f4b54c723'},
         ],
         drops:[
            {name:'มีด',health: 0, attack: 30, defend: 0, accuracy: 6,star: 4, type: 'weapon', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fknife.png?alt=media&token=e473e019-a551-443b-a26b-c4aa2705fce6'},
            {name:'ผ้าพันคอนักเลง',health: 0, attack: 0, defend: 0, accuracy: 4,star: 4, type: 'neck', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
            {name:'เสื้อคุมนักเลง',health: 0, attack: 0, defend: 0, accuracy: 4,star: 4, type: 'cape', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
            {name:'หมวกนักเลง',health: 140, attack: 0, defend: 4, accuracy: 0,star: 4, type: 'head', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
            {name:'เสื้อนักเลง',health: 140, attack: 0, defend: 5, accuracy: 0,star: 4, type: 'body', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
            {name:'กางเกงนักเลง',health: 140, attack: 0, defend: 4, accuracy: 0,star: 4, type: 'bottom', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
            {name:'รองเท้านักเลง',health: 140, attack: 0, defend: 4, accuracy: 0,star: 4, type: 'foots', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          ],
          collections:[
              {name:'ตะเกียง',health: 0, attack: 3, defend: 0, accuracy: 0,star: 4, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กิ่งไม้',health: 0, attack: 3, defend: 0, accuracy: 0,star: 4, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'สายจูงสัตว์',health: 0, attack: 3, defend: 0, accuracy: 0,star: 4, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เข็มขัดบาง',health: 0, attack: 0, defend: 1, accuracy: 0,star: 4, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ใบมีด',health: 0, attack: 0, defend: 0, accuracy: 1,star: 4, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            ]
        },
        {name: 'ทูมสโตน',star: 5,  chanceDropItem: '0.12', chanceDropWorkerKey: '0.12',chanceDropPetKey: 2.4,chanceDropCollection: 0.05,
         thiefs: [
          {name: 'มูนอ่อนหัด',health: 265,star: 5, attack: 45, defend: 15, accuracy: 26, silver: 9, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
          {name: 'มูนเจ้าถิ่น',health: 270,star: 5, attack: 45, defend: 15, accuracy: 27, silver: 9, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
          {name: 'มูนจอมโฉด',health: 275,star: 5, attack: 50, defend: 15, accuracy: 28, silver: 9, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
          {name: 'มูนบ้าคลั่ง',health: 800,star: 5, attack: 90, defend: 15, accuracy: 100, silver: 18, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
         ],
         drops:[
         {name:'อีโต้',health: 0, attack: 35, defend: 0, accuracy: 7,star: 5, type: 'weapon', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fcleaver.png?alt=media&token=4fee1733-6261-4639-9e52-5e97a79b84c5'},
            {name:'ผ้าพันคอโจร',health: 0, attack: 0, defend: 0, accuracy: 5,star: 5, type: 'neck', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
            {name:'เสื้อคุมโจร',health: 0, attack: 0, defend: 0, accuracy: 5,star: 5, type: 'cape', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
            {name:'หมวกโจร',health: 170, attack: 0, defend: 5, accuracy: 0,star: 5, type: 'head', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
            {name:'เสื้อโจร',health: 170, attack: 0, defend: 6, accuracy: 0,star: 5, type: 'body', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
            {name:'กางเกงโจร',health: 170, attack: 0, defend: 5, accuracy: 0,star: 5, type: 'bottom', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
            {name:'รองเท้าโจร',health: 170, attack: 0, defend: 5, accuracy: 0,star: 5, type: 'foots', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          ],
          collections:[
              {name:'นาฬิกาทราย',health: 0, attack: 3, defend: 0, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แผนที่ล้ำค่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เกือกม้า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'หมายประกาศจับ',health: 0, attack: 0, defend: 1, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เอกสารลับ',health: 0, attack: 0, defend: 0, accuracy: 1,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            ]
        },
        {name: 'ทะเลทรายแห่งความตาย',star: 5,  chanceDropItem: '1.5', chanceDropWorkerKey: '0.12',chanceDropPetKey: 2.4,chanceDropCollection: 0.1,
         thiefs: [
          {name: 'มูนอ่อนหัด',health: 270,star: 5, attack: 45, defend: 17, accuracy: 26, silver: 9, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
          {name: 'มูนเจ้าถิ่น',health: 275,star: 5, attack: 45, defend: 17, accuracy: 27, silver: 9, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
          {name: 'มูนจอมโฉด',health: 280,star: 5, attack: 50, defend: 17, accuracy: 28, silver: 9, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
          {name: 'มูนบ้าคลั่ง',health: 810,star: 5, attack: 90, defend: 17, accuracy: 100, silver: 18, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
         ],
         drops:[
            {name:'เข็มทิศที่สูญหาย',health: 0, attack: 3, defend: 0, accuracy: 0,star: 1, type: 'compass', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcompass.png?alt=media&token=2d890498-ca56-4ddc-b815-4766c43aa4f5'},
          ],
          collections:[
              {name:'เศษทรายหายาก',health: 0, attack: 3, defend: 0, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ขวดเหล้าเปล่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เศษซากกระดูก',health: 0, attack: 3, defend: 0, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กระติกน้ำ',health: 0, attack: 0, defend: 1, accuracy: 0,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แผนที่ขาด',health: 0, attack: 0, defend: 0, accuracy: 1,star: 5, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            ]
        },
        {name: 'เดเซิร์ทโรส',star: 6, chanceDropItem: '0.06', chanceDropWorkerKey: '0.12',chanceDropPetKey: 2.5,chanceDropCollection: 0.05,
         thiefs: [
          {name: 'ลูคัสอ่อนหัด',health: 300,star: 6, attack: 50, defend: 18, accuracy: 31, silver: 10, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Flucas.png?alt=media&token=77e7d979-3680-415f-a2d4-3588cae83e91'},
          {name: 'ลูคัสเจ้าถิ่น',health: 305,star: 6, attack: 50, defend: 18, accuracy: 32, silver: 10, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Flucas.png?alt=media&token=77e7d979-3680-415f-a2d4-3588cae83e91'},
          {name: 'ลูคัสจอมโฉด',health: 310,star: 6, attack: 55, defend: 18, accuracy: 33, silver: 10, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Flucas.png?alt=media&token=77e7d979-3680-415f-a2d4-3588cae83e91'},
          {name: 'ลูคัสหน้าเลือด',health: 1000,star: 6, attack: 100, defend: 18, accuracy: 100, silver: 20, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Flucas.png?alt=media&token=77e7d979-3680-415f-a2d4-3588cae83e91'},
         ],
         drops:[
           {name:'ขวาน',health: 0, attack: 40, defend: 0, accuracy: 8,star: 6, type: 'weapon', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Faxe.png?alt=media&token=e1c886d4-0553-41a1-9f6d-f7f2c1d16df3'},
            {name:'ผ้าพันคอพ่อค้า',health: 0, attack: 0, defend: 0, accuracy: 6,star: 6, type: 'neck', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
            {name:'เสื้อคุมพ่อค้า',health: 0, attack: 0, defend: 0, accuracy: 6,star: 6, type: 'cape', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
            {name:'หมวกพ่อค้า',health: 200, attack: 0, defend: 6, accuracy: 0,star: 6, type: 'head', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
            {name:'เสื้อพ่อค้า',health: 200, attack: 0, defend: 7, accuracy: 0,star: 6, type: 'body', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
            {name:'กางเกงพ่อค้า',health: 200, attack: 0, defend: 6, accuracy: 0,star: 6, type: 'bottom', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
            {name:'รองเท้าพ่อค้า',health: 200, attack: 0, defend: 6, accuracy: 0,star: 6, type: 'foots', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          ],
          collections:[
              {name:'ธนบัตรเก่า',health: 0, attack: 3, defend: 0, accuracy: 0,star: 6, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'เครื่องลายคราม',health: 0, attack: 3, defend: 0, accuracy: 0,star: 6, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ผ้าไหม',health: 0, attack: 3, defend: 0, accuracy: 0,star: 6, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'สมุดบัญชี',health: 0, attack: 0, defend: 1, accuracy: 0,star: 6, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แผนที่รถไฟ',health: 0, attack: 0, defend: 0, accuracy: 1,star: 6, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            ]
        },
        {name: 'วิสกี้ริเวอร์',star: 7,  chanceDropItem: '0.03', chanceDropWorkerKey: '0.12',chanceDropPetKey: 2.6,chanceDropCollection: 0.05,
         thiefs: [
          {name: 'เบนจามินอ่อนหัด',health: 350,star: 7, attack: 55, defend: 21, accuracy: 36, silver: 11, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
          {name: 'เบนจามินเจ้าถิ่น',health: 355,star: 7, attack: 55, defend: 21, accuracy: 37, silver: 11, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
          {name: 'เบนจามินจอมโฉด',health: 360,star: 7, attack: 60, defend: 21, accuracy: 38, silver: 11, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
          {name: 'เบนจามินบ้าคลั่ง',health: 1120,star: 7, attack: 110, defend: 21, accuracy: 100, silver: 22, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
         ],
         drops:[
         {name:'ดาบโจรสลัด',health: 0, attack: 45, defend: 0, accuracy: 9,star: 7, type: 'weapon', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fpiratesword.png?alt=media&token=79009398-9469-40d0-bba2-a2c0261cc251'},
            {name:'ผ้าพันคอโจรสลัด',health: 0, attack: 0, defend: 0, accuracy: 7,star: 7, type: 'neck', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
            {name:'เสื้อคุมโจรสลัด',health: 0, attack: 0, defend: 0, accuracy: 7,star: 7, type: 'cape', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
            {name:'หมวกโจรสลัด',health: 230, attack: 0, defend: 7, accuracy: 0,star: 7, type: 'head', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
            {name:'เสื้อโจรสลัด',health: 230, attack: 0, defend: 8, accuracy: 0,star: 7, type: 'body', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
            {name:'กางเกงโจรสลัด',health: 230, attack: 0, defend: 7, accuracy: 0,star: 7, type: 'bottom', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
            {name:'รองเท้าโจรสลัด',health: 230, attack: 0, defend: 7, accuracy: 0,star: 7, type: 'foots', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          ],
          collections:[
              {name:'แผนที่สมบัติ',health: 0, attack: 3, defend: 0, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ถ้วยเงิน',health: 0, attack: 3, defend: 0, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กาน้ำ',health: 0, attack: 3, defend: 0, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ธงโจรสลัด',health: 0, attack: 0, defend: 1, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ผ้าโพกหัว',health: 0, attack: 0, defend: 0, accuracy: 1,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            ]
        },
        {name: 'หมู่บ้านโจรสลัด',star: 7,  chanceDropItem: '1.5', chanceDropWorkerKey: '0.12',chanceDropPetKey: 2.6,chanceDropCollection: 0.1,
         thiefs: [
          {name: 'เบนจามินอ่อนหัด',health: 360,star: 7, attack: 60, defend: 23, accuracy: 36, silver: 11, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
          {name: 'เบนจามินเจ้าถิ่น',health: 365,star: 7, attack: 60, defend: 23, accuracy: 37, silver: 11, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
          {name: 'เบนจามินจอมโฉด',health: 370,star: 7, attack: 65, defend: 23, accuracy: 38, silver: 11, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
          {name: 'เบนจามินบ้าคลั่ง',health: 1065,star: 7, attack: 110, defend: 23, accuracy: 100, silver: 22, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
         ],
         drops:[
           {name:'ปลอกแขนเหล็ก',health: 0, attack: 0, defend: 1, accuracy: 0,star: 1, type: 'armlet', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Farmlet.png?alt=media&token=96693594-83d5-4d68-9812-d3befd2733f6'},
          ],
          collections:[
              {name:'แจกันโจรสลัด',health: 0, attack: 3, defend: 0, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'จานแตก',health: 0, attack: 3, defend: 0, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แกวน้ำโจรสลัด',health: 0, attack: 3, defend: 0, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แผ่นไม้',health: 0, attack: 0, defend: 1, accuracy: 0,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ตะปู',health: 0, attack: 0, defend: 0, accuracy: 1,star: 7, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            ]
        },
        {name: 'เอาท์ลอว์ส์เฮเว่น',star: 8, chanceDropItem: '0.02', chanceDropWorkerKey: '0.13',chanceDropPetKey: 2.7,chanceDropCollection: 0.05,
         thiefs: [
          {name: 'เจคอบอ่อนหัด',health: 400,star: 8, attack: 60, defend: 24, accuracy: 41, silver: 12, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce'},
          {name: 'เจคอบเจ้าถิ่น',health: 405,star: 8, attack: 60, defend: 24, accuracy: 42, silver: 12, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce'},
          {name: 'เจคอบจอมโฉด',health: 410,star: 8, attack: 65, defend: 24, accuracy: 43, silver: 12, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce'},
          {name: 'เจคอบบ้าคลั่ง',health: 1280,star: 8, attack: 120, defend: 24, accuracy: 100, silver: 12, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce'},
         ],
         drops:[
         {name:'ดาบเหล็กกล้า',health: 0, attack: 50, defend: 0, accuracy: 10,star: 8, type: 'weapon', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fsword.png?alt=media&token=61028d31-1ae6-4eed-9294-f00ad0971dd9'},
            {name:'ผ้าพันคอคนรวย',health: 0, attack: 0, defend: 0, accuracy: 8,star: 8, type: 'neck', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
            {name:'เสื้อคุมคนรวย',health: 0, attack: 0, defend: 0, accuracy: 8,star: 8, type: 'cape', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
            {name:'หมวกคนรวย',health: 260, attack: 0, defend: 8, accuracy: 0,star: 8, type: 'head', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
            {name:'เสื้อคนรวย',health: 260, attack: 0, defend: 9, accuracy: 0,star: 8, type: 'body', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
            {name:'กางเกงคนรวย',health: 260, attack: 0, defend: 8, accuracy: 0,star: 8, type: 'bottom', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
            {name:'รองเท้าคนรวย',health: 260, attack: 0, defend: 8, accuracy: 0,star: 8, type: 'foots', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          ],
          collections:[
              {name:'นาฬิกาสร้อยคอ',health: 0, attack: 3, defend: 0, accuracy: 0,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'แหวนอัญมนี',health: 0, attack: 3, defend: 0, accuracy: 0,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ภาพวาดยุโรป',health: 0, attack: 3, defend: 0, accuracy: 0,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กำไลทองคำ',health: 0, attack: 0, defend: 1, accuracy: 0,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'หนังสือพิมพ์',health: 0, attack: 0, defend: 0, accuracy: 1,star: 8, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            ]
        },
        {name: 'โอกลาโฮม่า',star: 9, chanceDropItem: '0.02', chanceDropWorkerKey: '0.13',chanceDropPetKey: 2.8,chanceDropCollection: 0.05,
         thiefs: [
          {name: 'ทาคูม่าอ่อนหัด',health: 480,star: 9, attack: 75, defend: 30, accuracy: 48, silver: 13, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fdakota.png?alt=media&token=21a37af6-2cb6-4bdc-9668-9ae44a20d680'},
          {name: 'ทาคูม่าเจ้าถิ่น',health: 485,star: 9, attack: 80, defend: 30, accuracy: 49, silver: 13, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fdakota.png?alt=media&token=21a37af6-2cb6-4bdc-9668-9ae44a20d680'},
          {name: 'ทาคูม่าจอมโฉด',health: 490,star: 9, attack: 85, defend: 30, accuracy: 50, silver: 13, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fdakota.png?alt=media&token=21a37af6-2cb6-4bdc-9668-9ae44a20d680'},
          {name: 'ทาคูม่าบ้าคลั่ง',health: 1440,star: 9, attack: 150, defend: 30, accuracy: 100, silver: 13, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fdakota.png?alt=media&token=21a37af6-2cb6-4bdc-9668-9ae44a20d680'},
         ],
         drops:[
            {name:'หอกชนเผ่า',health: 0, attack: 55, defend: 0, accuracy: 10,star: 9, type: 'weapon', textColor: 'text-violet-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fspear.png?alt=media&token=b9bb1d3e-9cf6-444b-a85f-988e31cef53b'},
            {name:'โล่ชนเผ่า',health: 50, attack: 5, defend: 3, accuracy: 0,star: 9, type: 'offhand', textColor: 'text-violet-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fwoodshield.png?alt=media&token=58ddf24a-a138-48c1-b6d1-90241a7e2542'},
            {name:'ซิการ์',health: 50, attack: 5, defend: 0, accuracy: 3,star: 9, type: 'offhand', textColor: 'text-violet-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcigar.png?alt=media&token=a0c2f963-e1de-4125-aa60-42766ede91c9'},
            {name:'หมวกคนรวย',health: 260, attack: 0, defend: 8, accuracy: 0,star: 8, type: 'head', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
            {name:'เสื้อคนรวย',health: 260, attack: 0, defend: 9, accuracy: 0,star: 8, type: 'body', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
            {name:'กางเกงคนรวย',health: 260, attack: 0, defend: 8, accuracy: 0,star: 8, type: 'bottom', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
            {name:'รองเท้าคนรวย',health: 260, attack: 0, defend: 8, accuracy: 0,star: 8, type: 'foots', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
            
          ],
          collections:[
              {name:'ขนนก',health: 0, attack: 3, defend: 0, accuracy: 0,star: 9, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'ลูกธนู',health: 0, attack: 3, defend: 0, accuracy: 0,star: 9, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'งานปั้นชั้นเลิศ',health: 0, attack: 3, defend: 0, accuracy: 0,star: 9, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'กลอง',health: 0, attack: 0, defend: 1, accuracy: 0,star: 9, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
              {name:'โทเท็ม',health: 0, attack: 0, defend: 0, accuracy: 1,star: 9, type: 'collection', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fno.png?alt=media&token=18d49ae1-8fd4-4499-86f6-5ec9be090e11'},
            ]
        }
      ])



      const toggleModal = ref('')
      const item = ref('')

      const handleModal = (modal,data) =>{
        toggleModal.value = modal
        item.value = data
      }

      return { places,toggleModal,item, handleModal }
    },
}
</script>
<template>
  <div class="p-1 pb-16 mb-auto h-full text-white overflow-auto" id="style-1">
    <div class="space-y-1 max-w-screen-xl m-auto">
      <div class="p-3 bg-gray-900 rounded-lg space-y-0.5">
        <div class="text-2xl font-bold">WorkerKey คือเกมประเภทไหน?</div>
        <div>เป็นเกมออโต้ที่ผู้เล่นไม่จำเป็นต้องเล่นเองเหมาะสำหรับวัยทำงาน เพียงแค่ผู้เล่นมีคนงานและสั่งให้คนงานทำงานในสถานที่ต่าง ๆ และรอรับสิ่งที่คนงานของคุณจะหามาให้ จากนั้นคุณสามารถนำสิ่งของเหล่านั้นไปแลกเปลี่ยนกับผู้เล่นคนอื่น ๆ ได้ หรือ จะนำไอเทมเหล่านั้นมาใส่ให้คนงานของตัวเองเพื่อที่จะได้เก่งขึ้นและล่าโจรระดับสูงๆได้</div>
      </div>
      <div class="p-3 bg-gray-900 rounded-lg space-y-1">
        <div class="text-xl font-bold">คลิปวิดีโออธิบายวิธีการใช้งาน</div>
        <iframe class="w-full h-72" src="https://www.youtube.com/embed/qJ9jxZw6wSk?si=WErfhF3EShuweChc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
      <!-- <div class="p-3 bg-gray-900 rounded-lg space-y-0.5">
        <div class="text-2xl font-bold ">ระบบคีย์</div>
        <div class="font-bold">1.คีย์คนงาน</div>
        <div>คีย์คนงานคือคีย์ที่สามารถนำไปสุ่มกล่องคนงานภายในเกมได้เมื่อสุ่มสำเร็จจะได้รับ 1 คนงาน คีย์คนงานจะดรอปจากการสังหารโจรเพียงวันละ 1 ครั้งเท่านั้น และดรอปจากการซื้อตั๋วคนงานอาทิตย์ละ 1 ครั้งเท่านั้น</div>
        <div class="font-bold">2.คีย์ไอเทมสัตว์เลี้ยง</div>
        <div>คีย์ไอเทมสัตว์เสี้ยงคือคีย์ที่สามารถนำไปสุ่มกล่องสัตว์เลี้ยงในเกมได้เมื่อสุ่มสำเร็จจะได้รับ 1 ไอเทมสัตว์เลี้ยง คีย์สัตว์เลี้ยงจะดรอปจากการสังหารโจรเพียงวันละ 50 ครั้งเท่านั้น</div>
      </div> -->
      <div class="p-3 bg-gray-900 rounded-lg space-y-0.5">
        <div class="text-2xl font-bold ">ระบบคนงาน</div>
        <div>คนงานจะมีทั้งหมด 11 แบบให้ผู้เล่นได้สะสม</div>
        <div class="grid grid-cols-3 sm:grid-cols-5 lg:grid-cols-7 xl:grid-cols-9 gap-1">
          <div v-for="worker in workers" :key="worker">
            <div class="bg-gray-800 rounded p-1">
              <div class="flex justify-center"><img :src="worker.img" class="w-16"></div>
              <div class="flex items-center space-x-1 justify-center">
                <div class="flex items-center space-x-1 justify-center border-gray-600 border rounded px-1">
                  <img src="../assets/images/star.png" class="w-3">
                    <div class="text-xs font-bold text-white">{{worker.star}}</div>
                </div>
                <div class="font-bold text-center">{{ worker.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-2xl font-bold ">ค่าพลังคนงาน</div>
        <div class="grid grid-cols-1 gap-1">
          <div class="flex items-center space-x-1 bg-gray-800 rounded p-1">
            <div class="flex justify-center"><img src="../assets/images/hearth.png" class="w-10"></div>
            <div class="w-full">
              <div class="font-bold p-0.5">พลังชีวิต</div>
              <div class="text-sm bg-gray-300 rounded p-0.5 text-gray-900">ยิ่งคุณมีพลังชีวิตมากเท่าไหร่คนงานของคุณจะออกล่าโจรได้มากขึ้น</div>
            </div>
          </div>
          <div class="flex items-center space-x-1 bg-gray-800 rounded p-1">
            <div class="flex justify-center"><img src="../assets/images/sword.png" class="w-10"></div>
            <div class="w-full">
              <div class="font-bold p-0.5">พลังโจมตี</div>
              <div class="text-sm bg-gray-300 rounded p-0.5 text-gray-900">ยิ่งคุณมีพลังโจมตีมากเท่าไหร่คุณยิ่งสังหารโจรได้เร็วขึ้น</div>
            </div>
          </div>
          <div class="flex items-center space-x-1 bg-gray-800 rounded p-1">
            <div class="flex justify-center"><img src="../assets/images/shield.png" class="w-10"></div>
            <div class="w-full">
              <div class="font-bold p-0.5">พลังป้องกัน</div>
              <div class="text-sm bg-gray-300 rounded p-0.5 text-gray-900">ถ้าพลังป้องกันของคุณมากกว่าความแม่นยำของโจร คนงานของคุณจะไม่ได้รับบาดเจ็บจากการโจมตีของโจร</div>
            </div>
          </div>
          <div class="flex items-center space-x-1 bg-gray-800 rounded p-1">
            <div class="flex justify-center"><img src="../assets/images/target.png" class="w-10"></div>
            <div class="w-full">
              <div class="font-bold p-0.5">ความแม่นยำ</div>
              <div class="text-sm bg-gray-300 rounded p-0.5 text-gray-900">คนงานของคุณมีความแม่นยำมากเท่าไหร่ คนงานของคุณจะสามารถออกล่าโจรเลเวลระดับดาวสูงๆได้ จะสามารถหาของที่ดีมากกว่าเดิมได้</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="p-3 bg-gray-900 rounded-lg space-y-1">
        <div class="font-bold text-center">ไอเทมตราสัญลักษณ์</div>
        <div class="grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-5 gap-1">
          <div v-for="emblem in emblems" :key="emblem">
            <div class="bg-gray-800 p-1 rounded-lg">
              <div class="flex justify-center"><img :src="emblem.img" class="w-10 bg-amber-600 rounded-full p-1"></div>
                <div class="text-xs sm:text-base text-center ">{{ emblem.name }}</div>
              <div class=" flex justify-center items-center space-x-1 rounded">
                  <div class="flex items-center space-x-1 bg-red-500 px-0.5 p-1 rounded">
                    <div class="text-xs sm:text-sm font-bold">{{ emblem.attack }}</div>
                    <div><img src="../assets/images/sword.png" class="w-3 sm:w-4"></div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import ThiefData from '../components/thief/ThiefData.vue'
export default {
  components: { ThiefData },
  setup() {
    
    const workers = ref([
        {name: 'ปีเตอร์',health: 100,star: 1, attack: 10, defend: 0, accuracy: 0,textColor: 'text-white', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fpeter.png?alt=media&token=58cd2ce7-ac69-4da0-96e9-82970737f3a2'},
        {name: 'ปีแยร์',health: 100,star: 2, attack: 11, defend: 0, accuracy: 0,textColor: 'text-amber-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fpierre.png?alt=media&token=b9f093b8-72e7-4a23-8f5d-41bb82d8f60e'},
        {name: 'มาร์ค',health: 100,star: 3, attack: 12, defend: 0, accuracy: 0,textColor: 'text-lime-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fmark.png?alt=media&token=2d684ac2-b85d-4ea0-9e88-a272117daf7f'},
        {name: 'นาธาน',health: 100,star: 4, attack:13, defend: 0, accuracy: 0,textColor: 'text-green-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fnathan.png?alt=media&token=1fe18fb4-43df-4bcb-b2d3-8d7fca18897e'},
        {name: 'จวน',health: 100,star: 5, attack: 14, defend: 0, accuracy: 0,textColor: 'text-cyan-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fjuan.png?alt=media&token=9222f167-03de-4d50-8ef6-fbaaafde7c83'},
        {name: 'ดาโกต้า',health: 100,star: 6, attack: 15, defend: 0, accuracy: 0,textColor: 'text-sky-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fdakota.png?alt=media&token=21a37af6-2cb6-4bdc-9668-9ae44a20d680'},
        {name: 'โคฟี่',health: 100,star: 7, attack: 16, defend: 0, accuracy: 0,textColor: 'text-blue-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fkofi.png?alt=media&token=fef78eb4-f520-4c0f-bc55-d8a2231abf17'},
        {name: 'หว่อง',health: 100,star: 8, attack: 17, defend: 0, accuracy: 0,textColor: 'text-indigo-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fwong.png?alt=media&token=6aa6e3b2-c61b-46f1-9aa4-e8e181425e22'},
        {name: 'แคทเธอรีน',health: 100,star: 9, attack: 18, defend: 0,accuracy: 0,textColor: 'text-violet-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fkatherine.png?alt=media&token=a05ca758-04e1-40a0-9814-27a8e507d841'},
        {name: 'ฮิวส์ตัน',health: 100,star: 10, attack: 19, defend: 0, accuracy: 0,textColor: 'text-pink-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fhouston.png?alt=media&token=4bcf5ded-6b02-4e65-8f6f-72325884d1ad'},
        {name: 'สมิธ',health: 100,star: 11, attack: 20, defend: 0, accuracy: 0,textColor: 'text-red-500', img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/worker%2Fsmith.gif?alt=media&token=04ab543c-cb2c-4d59-8952-7c64316d6328'},
      ])

      const emblems = ref([
        {name:'ตราสัญลักษณ์วิลเลี่ยม',health: 0, attack: 1, defend: 0,accuracy: 0,star: 1, textColor: 'text-white', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
        {name:'ตราสัญลักษณ์วูดดี้',health: 0, attack: 3, defend: 0,accuracy: 0,star: 2, textColor: 'text-amber-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc'},
        {name:'ตราสัญลักษณ์วิกกี้',health: 0, attack: 5, defend: 0,accuracy: 0,star: 3, textColor: 'text-lime-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
        {name:'ตราสัญลักษณ์เมสัน',health: 0, attack: 7, defend: 0,accuracy: 0,star: 4, textColor: 'text-green-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmason.png?alt=media&token=60f49aee-73e9-454f-b492-eb9f4b54c723'},
        {name:'ตราสัญลักษณ์มูน',health: 0, attack: 9, defend: 0,accuracy: 0,star: 5, textColor: 'text-cyan-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
        {name:'ตราสัญลักษณ์ลูคัส',health: 0, attack: 11, defend: 0,accuracy: 0,star: 6, textColor: 'text-sky-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Flucas.png?alt=media&token=77e7d979-3680-415f-a2d4-3588cae83e91'},
        {name:'ตราสัญลักษณ์เบนจามิน',health: 0, attack: 13, defend: 0,accuracy: 0,star: 7, textColor: 'text-blue-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
        {name:'ตราสัญลักษณ์เจคอบ',health: 0, attack: 15, defend: 0,accuracy: 0,star: 8, textColor: 'text-indigo-500', type:'emblem', img:'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce'},
      ])

      return { workers,emblems }
  },
}
</script>

<style scoped>
#style-1 {
  /* Custom scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: #2d2d2d #1a1a1a;
}

#style-1::-webkit-scrollbar {
  width: 8px;
}

#style-1::-webkit-scrollbar-track {
  background: #1a1a1a;
}

#style-1::-webkit-scrollbar-thumb {
  background-color: #2d2d2d;
  border-radius: 10px;
}
</style>

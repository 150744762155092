<template>
  <div class="bg-gray-900 rounded">
    <div v-if="logs.length >= 1">
      <div class="text-center text-gray-400 text-sm">ประวัติของคุณ</div>
      <div ref="logContainer" class="grid grid-cols-1 gap-1 h-96 overflow-auto" id="style-1">
        <transition-group name="slide-fade" tag="div">
          <div v-for="log in logs" :key="log.id" class="flex items-center space-x-1 bg-gray-800 p-2 rounded">
            <div><img :src="log.img" class="w-14"></div>
            <div class="w-full">
              <div>{{ log.message }}</div>
              <div class="text-xs">{{ dayjs(log.createdAt.toDate()).locale('th').fromNow() }}</div>
            </div>
          </div>
        </transition-group>
      </div>
      <div class="flex justify-center mt-2">
        <button @click="loadMore" class="w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300" :disabled="loadingMore">
          โหลดเพิ่มเติม
        </button>
      </div>
    </div>
    <div v-else>
      <div class="flex items-center justify-center h-40">
        <div class="text-xl font-bold opacity-50">ไม่พบประวัติของคุณ</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, nextTick } from 'vue';
import getUser from '@/composables/getUser';
import { projectFirestore } from '@/firebase/config';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import relativeTime from 'dayjs/plugin/relativeTime';

export default {
  setup() {
    dayjs.extend(relativeTime);
    const { user } = getUser();
    const logs = ref([]);
    const limit = ref(30);
    const lastVisible = ref(null);
    const loadingMore = ref(false);
    const logContainer = ref(null);

    const fetchLogs = async (initial = false) => {
      if (initial) logs.value = [];

      let query = projectFirestore.collection('logs')
        .where('ownerUid', '==', user.value.uid)
        .where('type', '==', 'account')
        .orderBy('createdAt', 'desc')
        .limit(initial ? limit.value : 6); // Adjust the limit for loading more

      if (lastVisible.value && !initial) {
        query = query.startAfter(lastVisible.value);
      }

      const snapshot = await query.get();
      if (snapshot.docs.length > 0) {
        lastVisible.value = snapshot.docs[snapshot.docs.length - 1];
        const newLogs = [];
        snapshot.forEach(logDoc => {
          newLogs.push({ id: logDoc.id, ...logDoc.data() });
        });
        logs.value.push(...newLogs);
        if (!initial) {
          nextTick(() => {
            smoothScrollToBottom(logContainer.value);
          });
        }
      }
    };

    const loadMore = () => {
      if (!loadingMore.value) {
        loadingMore.value = true;
        fetchLogs().then(() => {
          loadingMore.value = false;
        });
      }
    };

    const smoothScrollToBottom = (element) => {
      const targetScrollTop = element.scrollHeight - element.clientHeight;
      const initialScrollTop = element.scrollTop;
      const distance = targetScrollTop - initialScrollTop;
      const duration = 500; // duration in milliseconds
      let start = null;

      const step = (timestamp) => {
        if (!start) start = timestamp;
        const progress = timestamp - start;
        const percent = Math.min(progress / duration, 1);
        element.scrollTop = initialScrollTop + distance * percent;
        if (progress < duration) {
          window.requestAnimationFrame(step);
        }
      };

      window.requestAnimationFrame(step);
    };

    fetchLogs(true); // Fetch initial logs

    return { logs, dayjs, loadMore, loadingMore, logContainer };
  }
};
</script>

<style scoped>
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>

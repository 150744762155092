<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex h-full pb-16 items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
            <div class="bg-gray-800 text-white p-3">
              <div class="space-y-1">
                <input v-model="receiverUid" @change="checkReceiver" required placeholder="กรอก UID ผู้รับเหรียญเงิน" class="w-full p-1 bg-gray-700 rounded border border-gray-600 focus:border-indigo-500 focus:outline-none text-center" type="text">
                <input v-model.number="amount" required placeholder="กรอกจำนวนเหรียญเงิน" class="w-full p-3 bg-gray-700 rounded border border-gray-600 focus:border-indigo-500 focus:outline-none text-center text-xl font-bold" type="number" min="1">
                <div v-if="errorMessage" class="text-red-500 text-center">{{ errorMessage }}</div>
                <button @click="handleTransferSilver" :disabled="loading || !receiverExists" type="button" class="w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">
                  <span v-if="loading">กำลังส่ง...</span>
                  <span v-else>ส่งเหรียญเงิน</span>
                </button>
                <button v-if="!loading" @click="cancelModal" type="button" class="w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-white duration-300">ยกเลิก</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  import { projectFirestore, projectFunctions } from '@/firebase/config'
  
  export default {
    setup(props, context) {
      const cancelModal = () => { context.emit('cancelModal') }
      const amount = ref(0)
      const receiverUid = ref('')
      const errorMessage = ref('')
      const loading = ref(false)
      const receiverExists = ref(false)
  
      const checkReceiver = async () => {
        if (receiverUid.value) {
          const receiverRef = projectFirestore.collection('users').doc(receiverUid.value);
          const doc = await receiverRef.get();
          receiverExists.value = doc.exists;
          if (!receiverExists.value) {
            errorMessage.value = 'ไม่พบผู้รับที่มี UID นี้';
          } else {
            errorMessage.value = '';
          }
        } else {
          receiverExists.value = false;
          errorMessage.value = 'กรุณากรอก UID ผู้รับเหรียญเงิน';
        }
      }
  
      const handleTransferSilver = async () => {
        // Validate inputs
        if (!receiverUid.value || amount.value <= 0 || !Number.isInteger(amount.value)) {
          errorMessage.value = 'กรุณากรอก UID และจำนวนเงินให้ถูกต้อง (จำนวนเงินต้องเป็นจำนวนเต็มบวก)';
          return;
        }
  
        loading.value = true;
        const transferSilver = projectFunctions.httpsCallable('transferSilver');
  
        try {
          const result = await transferSilver({ amount: amount.value, receiverUid: receiverUid.value });
          console.log(result.data); // Handle successful response
          cancelModal(); // Close the modal on success
        } catch (error) {
          errorMessage.value = error.message || 'การโอนเงินล้มเหลว';
        } finally {
          loading.value = false;
        }
      }
  
      return { cancelModal, amount, receiverUid, handleTransferSilver, errorMessage, loading, receiverExists, checkReceiver }
    },
  }
  </script>
  
  <style scoped>
  /* Add any additional styling here */
  </style>
  
<template>
  <div class="sm:col-span-3 bg-gray-900 p-1 rounded">
    <div class="text-center text-gray-400">ประวัติคนงาน</div>
    <div class="grid grid-cols-1 sm:grid-cols-3 gap-1">
      <div v-for="log in logs" :key="log.createdAt">
        <div class="flex space-x-1 p-1 items-center border border-gray-700 rounded">
          <div><img :src="log.img" class="w-10"></div>
          <div>
            <div>{{ log.message }}</div>
            <div class="text-xs">{{ dayjs(log.createdAt.toDate()).locale('th').fromNow() }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-2">
      <button @click="loadMore" class="w-full px-4 py-2 bg-indigo-600 hover:bg-indigo-500 font-bold duration-300 text-white rounded">Load More</button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { projectFirestore } from '@/firebase/config'
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import relativeTime from 'dayjs/plugin/relativeTime';

export default {
  props: ['id'],
  setup(props) {
    dayjs.extend(relativeTime)
    const logs = ref([])
    const lastVisible = ref(null)
    const initialBatchSize = 6
    const subsequentBatchSize = 3

    const fetchLogs = (batchSize) => {
      let query = projectFirestore.collection('logs')
        .where('type', '==', 'worker')
        .where('workerId', '==', props.id)
        .orderBy('createdAt', 'desc')
        .limit(batchSize)

      if (lastVisible.value) {
        query = query.startAfter(lastVisible.value)
      }

      query.get().then(snapshot => {
        if (!snapshot.empty) {
          lastVisible.value = snapshot.docs[snapshot.docs.length - 1]
          snapshot.forEach(logDoc => {
            logs.value.push(logDoc.data())
          })
        }
      })
    }

    fetchLogs(initialBatchSize)

    const loadMore = () => {
      fetchLogs(subsequentBatchSize)
    }

    return { logs, dayjs, loadMore }
  }
}
</script>

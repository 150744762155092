<template>
  <div class="p-1 pb-24 mb-auto h-full text-white overflow-auto" id="style-1">
    <div class="max-w-screen-xl m-auto">
        <div class="grid grid-cols-1 bg-gray-900 gap-1">
    <div v-for="place in places" :key="place">
      <div class=" rounded-lg p-1 space-y-1">
        <div class="bg-yellow-900 rounded p-1 sm:p-2">
          <div class="flex items-center text-center justify-center">
            <span v-for="star in place.star" :key="star" class="text-yellow-400 text-xl">★</span>
          </div>
          <div class="flex items-center  justify-center space-x-1  ">
            <div><img src="../assets/images/roadsign.png" class="w-7 sm:w-10"></div>
            <div class="font-bold text-base sm:text-xl">สถานที่{{ place.name }}</div>
          </div>
         
        </div>
        <div class="grid grid-cols-1 gap-1 ">
          <div v-for="thief in place.thiefs" :key="thief">
            <div class="flex items-center space-x-1 justify-between p-1 bg-gray-800 rounded">
              <div class="flex items-center space-x-1 ">
                <div class="flex justify-center"><img :src="thief.img" class="w-8 sm:w-10"></div>
                <div class="text-xs sm:text-sm text-center font-bold">{{ thief.name }}</div>
              </div>
              <div class="flex items-center space-x-1 text-xs sm:text-sm font-bold">
                <div class="p-0.5 w-12 sm:w-14 justify-center bg-yellow-800 flex items-center space-x-1 rounded">
                  <div>เก่า</div>
                  <div>{{ thief.accuracy }}</div>
                  <div><img src="../assets/images/target.png" class="w-3 sm:w-5"></div>
                </div>
                <div class="flex items-center space-x-1 bg-yellow-900 rounded p-1">
                    <div>ใหม่</div>
                    <div>{{ thief.newAccuracy }}</div>
                    <div><img src="../assets/images/target.png" class="w-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1  gap-1">
          <div v-for="item in place.drops" :key="item">
            <div class="flex justify-center items-center space-x-1 text-xs sm:text-sm bg-gray-900 bg-opacity-80  duration-200  p-1 rounded">
              <div class="flex justify-center items-center space-x-1">
                <div><img :src="item.img" class="w-4 sm:w-5"></div>
              <div class="flex  items-center space-x-1 p-0.5 w-8  justify-center border-gray-700 border rounded">
                <div><img src="../assets/images/star.png" class="w-3 sm:w-4"></div>
                <div class="text-xs  font-bold">{{ item.star }}</div>
              </div>
              <div :class="item.textColor" class="text-center">{{ item.name }}</div>
              </div>
            </div>
            <div class="flex justify-center items-center space-x-1 bg-red-900 bg-opacity-50 p-1 rounded text-xs sm:text-sm">
                <div>เก่า</div>
                <div class="flex items-center space-x-1 bg-pink-900 rounded p-1">
                    <div>{{ item.health }}</div>
                    <div><img src="../assets/images/hearth.png" class="w-3"></div>
                </div>
                <div class="flex items-center space-x-1 bg-red-900 rounded p-1">
                    <div>{{ item.attack }}</div>
                    <div><img src="../assets/images/sword.png" class="w-3"></div>
                </div>
                <div class="flex items-center space-x-1 bg-blue-900 rounded p-1">
                    <div>{{ item.defend }}</div>
                    <div><img src="../assets/images/shield.png" class="w-3"></div>
                </div>
                <div class="flex items-center space-x-1 bg-yellow-900 rounded p-1">
                    <div>{{ item.accuracy }}</div>
                    <div><img src="../assets/images/target.png" class="w-3"></div>
                </div>
              </div>
            <div class="flex justify-center items-center space-x-1 bg-green-900 bg-opacity-50 p-1 rounded text-xs sm:text-sm">
                <div>ใหม่</div>
                <div class="flex items-center space-x-1 bg-pink-900 rounded p-1">
                    <div>{{ item.health }}</div>
                    <div><img src="../assets/images/hearth.png" class="w-3"></div>
                </div>
                <div class="flex items-center space-x-1 bg-red-900 rounded p-1">
                    <div>{{ item.newAttack }}</div>
                    <div><img src="../assets/images/sword.png" class="w-3"></div>
                </div>
                <div class="flex items-center space-x-1 bg-blue-900 rounded p-1">
                    <div>{{ item.newDefend }}</div>
                    <div><img src="../assets/images/shield.png" class="w-3"></div>
                </div>
                <div class="flex items-center space-x-1 bg-yellow-900 rounded p-1">
                    <div>{{ item.newAccuracy }}</div>
                    <div><img src="../assets/images/target.png" class="w-3"></div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-gray-900 rounde p-1 mt-2 rounded">
    <div class="text-center">ปรับใหม่</div>
    <div class="grid grid-cols-1 gap-1 ">
        <div v-for="item in itemPet" :key="item">
            <div class="flex items-center justify-between bg-gray-800">
                <div class="flex items-center space-x-1">
                    <div><img :src="item.img" class="w-10"></div>
                    <div class="text-xs sm:text-sm">{{ item.name }}</div>
                </div>
                <div class="flex items-center space-x-1 ">
                    <div class="flex items-center justify-center space-x-1 bg-pink-900 rounded p-1 w-12">
                    <div>{{ item.health }}</div>
                    <div><img src="../assets/images/hearth.png" class="w-3"></div>
                </div>
                <div class="flex items-center justify-center space-x-1 bg-red-900 rounded p-1 w-12">
                    <div>{{ item.attack }}</div>
                    <div><img src="../assets/images/sword.png" class="w-3"></div>
                </div>
                <div class="flex items-center justify-center space-x-1 bg-blue-900 rounded p-1 w-12">
                    <div>{{ item.defend }}</div>
                    <div><img src="../assets/images/shield.png" class="w-3"></div>
                </div>
                <div class="flex items-center justify-center space-x-1 bg-yellow-900 rounded p-1 w-12">
                    <div>{{ item.accuracy }}</div>
                    <div><img src="../assets/images/target.png" class="w-3"></div>
                </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  
    </div>

    
    
  </div>
  
</template>

<script>
import { ref } from 'vue'
export default {
  setup(){
    const places = ref([
        {name: 'เดอะฮิวจ์',star: 1, chanceDropItem: '1.5', chanceDropWorkerKey: '0.10',chanceDropPetKey: 1,
         thiefs: [
          {name: 'วิลเลี่ยมอ่อนหัด',health: 45,star: 1, attack: 20, defend: 0, accuracy: 4, newAccuracy: 5, silver: 5,img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
          {name: 'วิลเลี่ยมเจ้าถิ่น',health: 50,star: 1, attack: 20, defend: 0, accuracy: 5, newAccuracy: 6, silver: 5,img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
          {name: 'วิลเลี่ยมจอมโฉด',health: 55,star: 1, attack: 20, defend: 0, accuracy: 6, newAccuracy: 7, silver: 5,img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'},
          {name: 'วิลเลี่ยมบ้าคลั่ง',health: 160,star: 1, attack: 40, defend: 0, accuracy: 100, newAccuracy: 100, silver: 10,img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654'}
         ],
         drops:[
            {name:'ส้อม',health: 0, attack: 15, defend: 0, accuracy: 3, newAttack: 15, newDefend: 0, newAccuracy: 3,star: 1, type: 'weapon', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Ffork.png?alt=media&token=3950af1a-4a13-4676-80f1-e7ad3d77c720'},
            {name:'ผ้าพันคอขาด',health: 0, attack: 0, defend: 0, accuracy: 1, newAttack: 0, newDefend: 1, newAccuracy: 1,star: 1, type: 'neck', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
            {name:'เสื้อคุมขาด',health: 0, attack: 0, defend: 0, accuracy: 1, newAttack: 0, newDefend: 1, newAccuracy: 1,star: 1, type: 'cape', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
            {name:'หมวกขาด',health: 50, attack: 0, defend: 1, accuracy: 1, newAttack: 0, newDefend: 1, newAccuracy: 0,star: 1, type: 'head', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
            {name:'เสื้อขาด',health: 50, attack: 0, defend: 2, accuracy: 2, newAttack: 0, newDefend: 2, newAccuracy: 0,star: 1, type: 'body', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
            {name:'กางเกงขาด',health: 50, attack: 0, defend: 1, accuracy: 1, newAttack: 0, newDefend: 1, newAccuracy: 0,star: 1, type: 'bottom', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
            {name:'รองเท้าขาด',health: 50, attack: 0, defend: 1, accuracy: 1, newAttack: 0, newDefend: 1, newAccuracy: 0,star: 1, type: 'foots', textColor: 'text-white', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          ]
        },
        {name: 'บูทฮิลล์',star: 2, chanceDropItem: '0.5', chanceDropWorkerKey: '0.11',chanceDropPetKey: 1.2,
         thiefs: [
          {name: 'วูดดี้อ่อนหัด',health: 85,star: 2, attack: 25, defend: 5, accuracy: 8, newAccuracy: 11, silver: 6, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc'},
          {name: 'วูดดี้เจ้าถิ่น',health: 90,star: 2, attack: 25, defend: 5, accuracy: 9, newAccuracy: 12, silver: 6, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc'},
          {name: 'วูดดี้จอมโฉด',health: 95,star: 2, attack: 25, defend: 5, accuracy: 10, newAccuracy: 13, silver: 6, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc'},
          {name: 'วูดดี้บ้าคลั่ง',health: 340,star: 2, attack: 50, defend: 5, accuracy: 100, newAccuracy: 100, silver: 12, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwoody.png?alt=media&token=ec36bc93-cf08-4567-b5f9-fb510ac48cbc'}
         ],
         drops:[
           {name:'มีดหั่นผัก',health: 0, attack: 20, defend: 0, accuracy: 4, newAttack: 20, newDefend: 0, newAccuracy: 4,star: 2, type: 'weapon', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fbreadknife.png?alt=media&token=37625740-6cfc-4e79-80dc-ad00483945e9'},
            {name:'ผ้าพันคอคนจร',health: 0, attack: 0, defend: 0, accuracy: 2, newAttack: 0, newDefend: 2, newAccuracy: 2,star: 2, type: 'neck', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
            {name:'เสื้อคุมคนจร',health: 0, attack: 0, defend: 0, accuracy: 2, newAttack: 0, newDefend: 2, newAccuracy: 2,star: 2, type: 'cape', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
            {name:'หมวกคนจร',health: 80, attack: 0, defend: 0, accuracy: 0, newAttack: 0, newDefend: 2, newAccuracy: 0,star: 2, type: 'head', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
            {name:'เสื้อคนจร',health: 80, attack: 0, defend: 0, accuracy: 0, newAttack: 0, newDefend: 3, newAccuracy: 0,star: 2, type: 'body', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
            {name:'กางเกงคนจร',health: 80, attack: 0, defend: 0, accuracy: 0, newAttack: 0, newDefend: 2, newAccuracy: 0,star: 2, type: 'bottom', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
            {name:'รองเท้าคนจร',health: 80, attack: 0, defend: 0, accuracy: 0, newAttack: 0, newDefend: 2, newAccuracy: 0,star: 2, type: 'foots', textColor: 'text-amber-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          ]
        },
        {name: 'เดดวูด',star: 3,  chanceDropItem: '0.25', chanceDropWorkerKey: '0.11',chanceDropPetKey: 1.4,
         thiefs: [
          {name: 'วิกกี้อ่อนหัด',health: 125,star: 3, attack: 30, defend: 8, accuracy: 12, newAccuracy: 17, silver: 7,  img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
          {name: 'วิกกี้เจ้าถิ่น',health: 130,star: 3, attack: 30, defend: 8, accuracy: 13, newAccuracy: 18, silver: 7,  img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
          {name: 'วิกกี้จอมโฉด',health: 135,star: 3, attack: 30, defend: 8, accuracy: 14, newAccuracy: 19, silver: 7,  img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
          {name: 'วิกกี้บ้าคลั่ง',health: 480,star: 3, attack: 60, defend: 8, accuracy: 100, newAccuracy: 100, silver: 14,  img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwicky.png?alt=media&token=778cf123-fdce-4325-856d-583e39f1a3e0'},
         ],
         drops:[
         {name:'พลั่ว',health: 0, attack: 25, defend: 0, accuracy: 5, newAttack: 25, newDefend: 0, newAccuracy: 5,star: 3, type: 'weapon', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fpitchfork.png?alt=media&token=87b33cf6-979f-4763-a63a-ad0b539a54d1'},
            {name:'ผ้าพันคอชาวไร่',health: 0, attack: 0, defend: 0, accuracy: 3, newAttack: 0, newDefend: 3, newAccuracy: 3,star: 3, type: 'neck', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
            {name:'เสื้อคุมชาวไร่',health: 0, attack: 0, defend: 0, accuracy: 3, newAttack: 0, newDefend: 3, newAccuracy: 3,star: 3, type: 'cape', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
            {name:'หมวกชาวไร่',health: 110, attack: 0, defend: 3, accuracy: 0, newAttack: 0, newDefend: 3, newAccuracy: 0,star: 3, type: 'head', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
            {name:'เสื้อชาวไร่',health: 110, attack: 0, defend: 4, accuracy: 0, newAttack: 0, newDefend: 4, newAccuracy: 0,star: 3, type: 'body', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
            {name:'กางเกงชาวไร่',health: 110, attack: 0, defend: 3, accuracy: 0, newAttack: 0, newDefend: 3, newAccuracy: 0,star: 3, type: 'bottom', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
            {name:'รองเท้าชาวไร่',health: 110, attack: 0, defend: 3, accuracy: 0, newAttack: 0, newDefend: 3, newAccuracy: 0,star: 3, type: 'foots', textColor: 'text-lime-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          ]
        },
        {name: 'ดอดจ์ซิตี้',star: 4,  chanceDropItem: '0.12', chanceDropWorkerKey: '0.12',chanceDropPetKey: 1.6,
         thiefs: [
          {name: 'เมสันอ่อนหัด',health: 165,star: 4, attack: 35, defend: 11, accuracy: 16, newAccuracy: 23, silver: 8, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmason.png?alt=media&token=60f49aee-73e9-454f-b492-eb9f4b54c723'},
          {name: 'เมสันเจ้าถิ่น',health: 170,star: 4, attack: 35, defend: 11, accuracy: 17, newAccuracy: 24, silver: 8, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmason.png?alt=media&token=60f49aee-73e9-454f-b492-eb9f4b54c723'},
          {name: 'เมสันจอมโฉด',health: 175,star: 4, attack: 35, defend: 11, accuracy: 18, newAccuracy: 25, silver: 8, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmason.png?alt=media&token=60f49aee-73e9-454f-b492-eb9f4b54c723'},
          {name: 'เมสันบ้าคลั่ง',health: 640,star: 4, attack: 70, defend: 11, accuracy: 100, newAccuracy: 100, silver: 16, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmason.png?alt=media&token=60f49aee-73e9-454f-b492-eb9f4b54c723'},
         ],
         drops:[
            {name:'มีด',health: 0, attack: 30, defend: 0, accuracy: 6,star: 4, newAttack: 30, newDefend: 0, newAccuracy: 6, type: 'weapon', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fknife.png?alt=media&token=e473e019-a551-443b-a26b-c4aa2705fce6'},
            {name:'ผ้าพันคอนักเลง',health: 0, attack: 0, defend: 0, accuracy: 4, newAttack: 0, newDefend: 4, newAccuracy: 4,star: 4, type: 'neck', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
            {name:'เสื้อคุมนักเลง',health: 0, attack: 0, defend: 0, accuracy: 4, newAttack: 0, newDefend: 4, newAccuracy: 4,star: 4, type: 'cape', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
            {name:'หมวกนักเลง',health: 140, attack: 0, defend: 4, accuracy: 0, newAttack: 0, newDefend: 4, newAccuracy: 0,star: 4, type: 'head', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
            {name:'เสื้อนักเลง',health: 140, attack: 0, defend: 5, accuracy: 0, newAttack: 0, newDefend: 5, newAccuracy: 0,star: 4, type: 'body', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
            {name:'กางเกงนักเลง',health: 140, attack: 0, defend: 4, accuracy: 0, newAttack: 0, newDefend: 4, newAccuracy: 0,star: 4, type: 'bottom', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
            {name:'รองเท้านักเลง',health: 140, attack: 0, defend: 4, accuracy: 0, newAttack: 0, newDefend: 4, newAccuracy: 0,star: 4, type: 'foots', textColor: 'text-green-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          ]
        },
        {name: 'ทูมสโตน',star: 5,  chanceDropItem: '0.06', chanceDropWorkerKey: '0.12',chanceDropPetKey: 1.8,
         thiefs: [
          {name: 'มูนอ่อนหัด',health: 205,star: 5, attack: 40, defend: 14, accuracy: 20, newAccuracy: 29, silver: 9, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
          {name: 'มูนเจ้าถิ่น',health: 210,star: 5, attack: 40, defend: 14, accuracy: 21, newAccuracy: 30, silver: 9, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
          {name: 'มูนจอมโฉด',health: 215,star: 5, attack: 40, defend: 14, accuracy: 22, newAccuracy: 31, silver: 9, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
          {name: 'มูนบ้าคลั่ง',health: 800,star: 5, attack: 80, defend: 14, accuracy: 100, newAccuracy: 100, silver: 18, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fmoon.png?alt=media&token=dd1c894e-0781-4577-8578-47ec1d5d5cd3'},
         ],
         drops:[
         {name:'อีโต้',health: 0, attack: 35, defend: 0, accuracy: 7,star: 5, newAttack: 35, newDefend: 0, newAccuracy: 7, type: 'weapon', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fcleaver.png?alt=media&token=4fee1733-6261-4639-9e52-5e97a79b84c5'},
            {name:'ผ้าพันคอโจร',health: 0, attack: 0, defend: 0, accuracy: 5, newAttack: 0, newDefend: 5, newAccuracy: 5,star: 5, type: 'neck', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
            {name:'เสื้อคุมโจร',health: 0, attack: 0, defend: 0, accuracy: 5, newAttack: 0, newDefend: 5, newAccuracy: 5,star: 5, type: 'cape', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
            {name:'หมวกโจร',health: 170, attack: 0, defend: 5, accuracy: 0, newAttack: 0, newDefend: 5, newAccuracy: 0,star: 5, type: 'head', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
            {name:'เสื้อโจร',health: 170, attack: 0, defend: 6, accuracy: 0, newAttack: 0, newDefend: 6, newAccuracy: 0,star: 5, type: 'body', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
            {name:'กางเกงโจร',health: 170, attack: 0, defend: 5, accuracy: 0, newAttack: 0, newDefend: 5, newAccuracy: 0,star: 5, type: 'bottom', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
            {name:'รองเท้าโจร',health: 170, attack: 0, defend: 5, accuracy: 0, newAttack: 200, newDefend: 5, newAccuracy: 0,star: 5, type: 'foots', textColor: 'text-cyan-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          ]
        },
        {name: 'เดเซิร์ทโรส',star: 6, chanceDropItem: '0.03', chanceDropWorkerKey: '0.13',chanceDropPetKey: 2,
         thiefs: [
          {name: 'ลูคัสอ่อนหัด',health: 240,star: 6, attack: 45, defend: 17, accuracy: 24, newAccuracy: 35, silver: 10, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Flucas.png?alt=media&token=77e7d979-3680-415f-a2d4-3588cae83e91'},
          {name: 'ลูคัสเจ้าถิ่น',health: 245,star: 6, attack: 45, defend: 17, accuracy: 25, newAccuracy: 36, silver: 10, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Flucas.png?alt=media&token=77e7d979-3680-415f-a2d4-3588cae83e91'},
          {name: 'ลูคัสจอมโฉด',health: 250,star: 6, attack: 45, defend: 17, accuracy: 26, newAccuracy: 37, silver: 10, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Flucas.png?alt=media&token=77e7d979-3680-415f-a2d4-3588cae83e91'},
          {name: 'ลูคัสหน้าเลือด',health: 1000,star: 6, attack: 45, defend: 17, accuracy: 100, newAccuracy: 100, silver: 20, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Flucas.png?alt=media&token=77e7d979-3680-415f-a2d4-3588cae83e91'},
         ],
         drops:[
           {name:'ขวาน',health: 0, attack: 40, defend: 0, accuracy: 8, newAttack: 40, newDefend: 0, newAccuracy: 8,star: 6, type: 'weapon', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Faxe.png?alt=media&token=e1c886d4-0553-41a1-9f6d-f7f2c1d16df3'},
            {name:'ผ้าพันคอพ่อค้า',health: 0, attack: 0, defend: 0, accuracy: 6, newAttack: 0, newDefend: 6, newAccuracy: 4,star: 6, type: 'neck', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
            {name:'เสื้อคุมพ่อค้า',health: 0, attack: 0, defend: 0, accuracy: 6, newAttack: 0, newDefend: 6, newAccuracy: 4,star: 6, type: 'cape', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
            {name:'หมวกพ่อค้า',health: 200, attack: 0, defend: 6, accuracy: 0, newAttack: 0, newDefend: 6, newAccuracy: 0,star: 6, type: 'head', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
            {name:'เสื้อพ่อค้า',health: 200, attack: 0, defend: 7, accuracy: 0, newAttack: 0, newDefend: 7, newAccuracy: 0,star: 6, type: 'body', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
            {name:'กางเกงพ่อค้า',health: 200, attack: 0, defend: 6, accuracy: 0, newAttack: 0, newDefend: 6, newAccuracy: 0,star: 6, type: 'bottom', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
            {name:'รองเท้าพ่อค้า',health: 200, attack: 0, defend: 6, accuracy: 0, newAttack: 0, newDefend: 6, newAccuracy: 0,star: 6, type: 'foots', textColor: 'text-sky-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          ]
        },
        {name: 'วิสกี้ริเวอร์',star: 7,  chanceDropItem: '0.02', chanceDropWorkerKey: '0.13',chanceDropPetKey: 2.2,
         thiefs: [
          {name: 'เบนจามินอ่อนหัด',health: 280,star: 7, attack: 50, defend: 20, accuracy: 28, newAccuracy: 41, silver: 11, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
          {name: 'เบนจามินเจ้าถิ่น',health: 285,star: 7, attack: 50, defend: 20, accuracy: 29, newAccuracy: 42, silver: 11, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
          {name: 'เบนจามินจอมโฉด',health: 290,star: 7, attack: 50, defend: 20, accuracy: 30, newAccuracy: 43, silver: 11, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
          {name: 'เบนจามินบ้าคลั่ง',health: 1120,star: 7, attack: 50, defend: 20, accuracy: 100, newAccuracy: 100, silver: 22, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fbenjamin.png?alt=media&token=8d96baae-dbe4-400c-aac7-a0cfdbb2eca5'},
         ],
         drops:[
           {name:'ดาบโจรสลัด',health: 0, attack: 45, defend: 0, accuracy: 9, newAttack: 45, newDefend: 0, newAccuracy: 9,star: 7, type: 'weapon', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fpiratesword.png?alt=media&token=79009398-9469-40d0-bba2-a2c0261cc251'},
            {name:'ผ้าพันคอโจรสลัด',health: 0, attack: 0, defend: 0, accuracy: 7, newAttack: 0, newDefend: 7, newAccuracy: 4,star: 7, type: 'neck', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
            {name:'เสื้อคุมโจรสลัด',health: 0, attack: 0, defend: 0, accuracy: 7, newAttack: 0, newDefend: 7, newAccuracy: 4,star: 7, type: 'cape', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
            {name:'หมวกโจรสลัด',health: 230, attack: 0, defend: 7, accuracy: 0, newAttack: 0, newDefend: 7, newAccuracy: 0,star: 7, type: 'head', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
            {name:'เสื้อโจรสลัด',health: 230, attack: 0, defend: 8, accuracy: 0, newAttack: 0, newDefend: 8, newAccuracy: 0,star: 7, type: 'body', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
            {name:'กางเกงโจรสลัด',health: 230, attack: 0, defend: 7, accuracy: 0, newAttack: 0, newDefend: 7, newAccuracy: 0,star: 7, type: 'bottom', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
            {name:'รองเท้าโจรสลัด',health: 230, attack: 0, defend: 7, accuracy: 0, newAttack: 0, newDefend: 7, newAccuracy: 0,star: 7, type: 'foots', textColor: 'text-blue-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          ]
        },
        {name: 'เอาท์ลอว์ส์เฮเว่น',star: 8, chanceDropItem: '0.01', chanceDropWorkerKey: '0.14',chanceDropPetKey: 2.4,
         thiefs: [
          {name: 'เจคอบอ่อนหัด',health: 320,star: 8, attack: 55, defend: 23, accuracy: 32, newAccuracy: 47, silver: 12, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce'},
          {name: 'เจคอบเจ้าถิ่น',health: 325,star: 8, attack: 55, defend: 23, accuracy: 33, newAccuracy: 48, silver: 12, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce'},
          {name: 'เจคอบจอมโฉด',health: 330,star: 8, attack: 55, defend: 23, accuracy: 34, newAccuracy: 49, silver: 12, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce'},
          {name: 'เจคอบบ้าคลั่ง',health: 1280,star: 8, attack: 55, defend: 23, accuracy: 100, newAccuracy: 100, silver: 12, img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fjacob.png?alt=media&token=c60a49c6-978c-4e2c-a3b7-5341319c24ce'},
         ],
         drops:[
            {name:'ดาบเหล็กกล้า',health: 0, attack: 50, defend: 0, accuracy: 10, newAttack: 50, newDefend: 0, newAccuracy: 10,star: 8, type: 'weapon', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fweapon%2Fsword.png?alt=media&token=61028d31-1ae6-4eed-9294-f00ad0971dd9'},
            {name:'ผ้าพันคอคนรวย',health: 0, attack: 0, defend: 0, accuracy: 8, newAttack: 0, newDefend: 8, newAccuracy: 8,star: 8, type: 'neck', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fscarf.png?alt=media&token=e87f4686-9bce-47f8-819e-69e4d3faf0ab'},
            {name:'เสื้อคุมคนรวย',health: 0, attack: 0, defend: 0, accuracy: 8, newAttack: 0, newDefend: 8, newAccuracy: 8,star: 8, type: 'cape', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fcape.png?alt=media&token=accb0cf0-45cf-4e8b-a042-058c378481d0'},
            {name:'หมวกคนรวย',health: 260, attack: 0, defend: 8, accuracy: 0, newAttack: 0, newDefend: 8, newAccuracy: 0,star: 8, type: 'head', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fhat.png?alt=media&token=6b2bb4bc-c456-4ac4-bd48-899c2ad97cf4'},
            {name:'เสื้อคนรวย',health: 260, attack: 0, defend: 9, accuracy: 0, newAttack: 0, newDefend: 9, newAccuracy: 0,star: 8, type: 'body', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fshirt.png?alt=media&token=a362656a-cfc7-4f5b-8f3a-6499932215b7'},
            {name:'กางเกงคนรวย',health: 260, attack: 0, defend: 8, accuracy: 0, newAttack: 0, newDefend: 8, newAccuracy: 0,star: 8, type: 'bottom', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Ftrouser.png?alt=media&token=8860f30f-e4fb-4e3d-827b-1fab0ee997af'},
            {name:'รองเท้าคนรวย',health: 260, attack: 0, defend: 8, accuracy: 0, newAttack: 0, newDefend: 8, newAccuracy: 0,star: 8, type: 'foots', textColor: 'text-indigo-500', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fboots.png?alt=media&token=43cd281a-395b-4f62-a0a1-dbd467dfc165'},
          ]
        }
      ])

      const itemPet = ref([
          {name:'ดัลเมเชียน',health: 110, attack: 1, defend: 0, accuracy: 0,star: 1, textColor: 'text-white', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fdalmatian.png?alt=media&token=36550e66-073b-4384-872c-9131fcbd14c9'},
          {name:'บูลเทอร์เรีย',health: 120, attack: 2, defend: 0, accuracy: 0,star: 2, textColor: 'text-amber-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fbullterrier.png?alt=media&token=053515f5-9a40-404e-b6c1-fadd6a09eed3'},
          {name:'บ๊อกเซอร์',health: 130, attack: 3, defend: 0, accuracy: 0,star: 3, textColor: 'text-lime-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fboxer.png?alt=media&token=38d2078e-6dce-4e18-ad9d-b680b9c9eefe'},
          {name:'บูลด็อก',health: 140, attack: 4, defend: 0, accuracy: 0,star: 4, textColor: 'text-green-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fbulldog.png?alt=media&token=901c8fe8-30f6-4f7a-aaa5-dc6a89556f52'},
          {name:'บอร์เดอร์คอลลี่',health: 150, attack:5, defend: 0, accuracy: 1,star: 5, textColor: 'text-cyan-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fbordercollie.png?alt=media&token=9bebdac7-3dc0-45f9-9c8b-2f5aff8e77f3'},
          {name:'เกรทเดน',health: 160, attack: 6, defend: 1, accuracy: 2,star: 6, textColor: 'text-sky-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fgreatdane.png?alt=media&token=9eca6356-b999-4434-8f98-14ab37cd50d4'},
          {name:'เยอรมันเชพเพิร์ด',health: 170, attack: 7, defend: 2, accuracy: 3,star: 7, textColor: 'text-blue-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fgermanshepherd.png?alt=media&token=efd6ef7a-2fff-4d57-9df5-27569d378c39'},
          {name:'โดเบอร์แมน',health: 180, attack: 8, defend: 3, accuracy: 5,star: 8, textColor: 'text-indigo-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fdoberman.png?alt=media&token=1aa5bbf8-ee8e-4720-bb6b-0adba32b72cf'},
          {name:'พิทบูล',health: 190, attack: 9, defend: 4, accuracy: 7,star: 9, textColor: 'text-violet-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fpitbull.png?alt=media&token=c5fe566a-ed20-4e80-8812-3449dc859601'},
          {name:'ร็อตไวเลอร์',health: 200, attack: 10, defend: 5, accuracy: 9,star: 10, textColor: 'text-pink-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Frottweiler.png?alt=media&token=01b12ac2-a4c9-4ceb-9005-23e62c338177'},
         ])


      

      return { places,itemPet }
  }
}
</script>

<style>

</style>
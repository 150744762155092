<template>
  <div class="p-1 pb-16 mb-auto h-full text-white overflow-auto">
    <div v-if="toggleModal === 'ModalCreateWorkerKey'" @cancelModal="toggleModal = ''">
      <ModalCreateWorkerKey @cancelModal="toggleModal = ''"/>
    </div>
    <div v-if="toggleModal === 'ModalCreatePetKey'" @cancelModal="toggleModal = ''">
      <ModalCreatePetKey @cancelModal="toggleModal = ''"/>
    </div>
    <div v-if="toggleModal === 'ModalCreateVipKey'" @cancelModal="toggleModal = ''">
      <ModalCreateVipKey @cancelModal="toggleModal = ''"/>
    </div>
    <div v-if="toggleModal === 'ModalEvidence'" @cancelModal="toggleModal = ''">
      <ModalEvidence @cancelModal="toggleModal = ''"/>
    </div>
    <div v-if="toggleModal === 'ModalPackage'" @cancelModal="toggleModal = ''">
      <ModalPackage @cancelModal="toggleModal = ''"/>
    </div>
    <div v-if="toggleModal === 'ModalCreateSilverKey'" @cancelModal="toggleModal = ''">
      <ModalCreateSilverKey @cancelModal="toggleModal = ''"/>
    </div>

    <div class="bg-gray-800 p-4 rounded-lg shadow-lg ">
      <div class="grid grid-cols-1 sm:grid-cols-5 gap-4 mb-4">
        <div class="bg-gray-900 p-3 rounded-lg">
          <div class="text-sm text-gray-400">ชื่อร้าน</div>
          <div class="font-bold">{{ partner.shopName }}</div>
        </div>
        <div class="bg-gray-900 p-3 rounded-lg">
          <div class="text-sm text-gray-400">เบอร์โทร</div>
          <div class="font-bold">{{ partner.phoneNumber }}</div>
        </div>
        <div class="bg-gray-900 p-3 rounded-lg col-span-2">
          <div class="text-sm text-gray-400">ที่อยู่ร้านค้า</div>
          <div class="font-bold">{{ partner.shopAddress }}</div>
        </div>
        <div @click="toggleModal = 'ModalEvidence'" class="bg-gray-900 p-3 rounded-lg cursor-pointer hover:bg-gray-700 transition-colors duration-300">
          <div class="text-sm text-gray-400">ส่งหลักฐานติดโปสเตอร์</div>
          <div v-if="now <= remainingEvidenceToDate" class="font-bold text-green-500">{{ remainingEvidence }}</div>
          <div v-else class="font-bold text-red-500">กรุณาส่งหลักฐานเพื่อใช้งาน</div>
        </div>
        <!-- <div @click="toggleModal = 'ModalPackage'" class="bg-gray-900 p-3 rounded-lg cursor-pointer hover:bg-gray-700 transition-colors duration-300">
          <div class="text-sm text-gray-400">แพคเกจ</div>
          <div v-if="partner.package === 'silver' || partner.package === 'gold'" class="flex items-center space-x-1 font-bold uppercase">
            <div>{{ partner.package }}</div>
            <div v-if="now <= remainingPackageToDate" class="text-green-500">({{ remainingPackage }})</div>
            <div v-else class="text-red-500">(หมดอายุ)</div>
          </div>
          <div v-if="partner.package === 'free'" class="flex items-center space-x-1 font-bold uppercase">
            <div>{{ partner.package }}</div>
          </div>
        </div> -->
      </div>

      <div class="bg-gray-900 p-4 rounded-lg mb-4">
        <div class="font-bold mb-2">เงื่อนไขการใช้งานเบื้องต้น</div>
        <ul class="list-disc list-inside text-gray-400">
          <li>ไม่อนุญาติให้ขาย Key คนงานเป็นเงินสด</li>
          <li>คีย์สุ่มไอเทมสัตว์เลี้ยง, คีย์สร้างVIP, คีย์เพิ่มเหรียญเงิน สามารถขายได้</li>
        </ul>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-4 gap-1.5 mb-4">
        <div @click="toggleModal = 'ModalCreateWorkerKey'" class="flex items-center justify-between bg-indigo-500 hover:bg-indigo-400 text-white font-bold py-2 px-4 rounded-lg cursor-pointer transition-colors duration-300">
          <div class="flex items-center space-x-2">
            <img src="../assets/worker/peter.png" class="w-8">
            <span>สร้างคีย์สุ่มคนงาน</span>
          </div>
          <div>{{ partner.workerKey }}</div>
        </div>
        <div @click="toggleModal = 'ModalCreatePetKey'" class="flex items-center justify-between bg-indigo-500 hover:bg-indigo-400 text-white font-bold py-2 px-4 rounded-lg cursor-pointer transition-colors duration-300">
          <div class="flex items-center space-x-2">
            <img src="../assets/item/pet/boxer.png" class="w-8">
            <span>สร้างคีย์สุ่มสัตว์เลี้ยง</span>
          </div>
          <div>{{ partner.petKey }}</div>
        </div>
        <div @click="toggleModal = 'ModalCreateVipKey'" class="flex items-center justify-between bg-indigo-500 hover:bg-indigo-400 text-white font-bold py-2 px-4 rounded-lg cursor-pointer transition-colors duration-300">
          <div class="flex items-center space-x-2">
            <img src="../assets/images/vip.png" class="w-8">
            <span>สร้างคีย์ VIP</span>
          </div>
          <div>{{ partner.vipKey }}</div>
        </div>
        <div @click="toggleModal = 'ModalCreateSilverKey'" class="flex items-center justify-between bg-indigo-500 hover:bg-indigo-400 text-white font-bold py-2 px-4 rounded-lg cursor-pointer transition-colors duration-300">
          <div class="flex items-center space-x-2">
            <img src="../assets/images/coin.png" class="w-8">
            <span>สร้างคีย์ Silver</span>
          </div>
          <div>{{ partner.silverKey }}</div>
        </div>
      </div>

      <div class="bg-gray-900 p-4 rounded-lg">
        <div class="text-center font-bold mb-2">คีย์ที่คุณสร้าง</div>
        <KeyList />
      </div>
    </div>
  </div>
</template>


<script>
import { ref, onMounted } from 'vue';
import ModalCreateWorkerKey from '../components/partner/ModalCreateWorkerKey.vue';
import ModalCreatePetKey from '../components/partner/ModalCreatePetKey.vue';
import ModalCreateVipKey from '../components/partner/ModalCreateVipKey.vue';
import ModalCreateSilverKey from '../components/partner/ModalCreateSilverKey.vue';
import KeyList from '../components/partner/KeyList.vue';
import getUser from '@/composables/getUser';
import { projectFirestore } from '@/firebase/config';
import ModalEvidence from '../components/partner/ModalEvidence.vue';
import ModalPackage from '../components/partner/ModalPackage.vue';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.locale('th');
dayjs.extend(relativeTime);

export default {
  components: { ModalCreateSilverKey, ModalPackage, ModalEvidence, KeyList, ModalCreateWorkerKey, ModalCreatePetKey, ModalCreateVipKey },
  setup() {
    const { user } = getUser();
    const toggleModal = ref('');
    const partner = ref({});
    const remainingEvidence = ref('');
    const remainingPackage = ref('')
    const remainingEvidenceToDate = ref()
    const remainingPackageToDate = ref()
    const now = new Date()
    onMounted(() => {
      projectFirestore.collection('partners').doc(user.value.uid).onSnapshot(partnerDoc => {
        partner.value = partnerDoc.data();
        const remainingEvidenceDate = partner.value.remainingEvidence.toDate();
        remainingEvidence.value = dayjs(remainingEvidenceDate).fromNow();
        const remainingPackageDate = partner.value.remainingPackage.toDate();
        remainingPackage.value = dayjs(remainingPackageDate).fromNow();

        remainingEvidenceToDate.value = partner.value.remainingEvidence.toDate()
        remainingPackageToDate.value = partner.value.remainingPackage.toDate()
      });
    });



    return { remainingPackageToDate,remainingEvidenceToDate,now,toggleModal, partner, remainingEvidence,remainingPackage };
  },
};
</script>

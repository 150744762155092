<template>
  <div class="px-1 mb-auto h-full text-white overflow-auto pb-24" id="style-1">
    <div class="flex justify-center items-center h-full">
      <form @submit.prevent="handleSubmit" class="bg-gray-900 text-white p-8 rounded shadow-md w-full max-w-md">
        <h3 class="text-2xl font-bold mb-4">สมัครสมาชิก</h3>
        <div class="mb-4">
          <label for="email" class="block text-gray-100 text-sm font-bold mb-2">อีเมลย์</label>
          <input type="email" id="email" placeholder="Enter your email" v-model="email" class="w-full p-1 border rounded focus:outline-none focus:border-blue-500 text-gray-900" />
        </div>
        <div class="mb-4">
          <label for="password" class="block text-gray-100 text-sm font-bold mb-2">รหัสผ่าน</label>
          <input type="password" id="password" placeholder="Enter your password" v-model="password" class="w-full p-1 border rounded focus:outline-none focus:border-blue-500 text-gray-900" />
        </div>
        <div class="mb-4">
          <label for="confirmPassword" class="block text-gray-100 text-sm font-bold mb-2">ยืนยันรหัสผ่าน</label>
          <input type="password" id="confirmPassword" placeholder="Confirm your password" v-model="confirmPassword" class="w-full p-1 border rounded focus:outline-none focus:border-blue-500 text-gray-900" />
          <div v-if="passwordMismatch" class="text-red-500 mt-2 text-xs">Passwords do not match</div>
        </div>
        <div class="mb-4">
          <input type="checkbox" id="terms" v-model="termsAccepted" class="mr-2 leading-tight" />
          <label for="terms" class="text-sm font-bold text-gray-100">ฉันยอมรับ <a href="/terms" target="_blank" class="text-blue-500 underline">เงื่อนไขการใช้เว็บไซต์</a></label>
        </div>
        <div v-if="error" class="text-red-500 mb-4 text-xs">{{ error }}</div>
        <button v-if="!isPending" class="w-full bg-indigo-800 hover:bg-indigo-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue duration-300" :disabled="passwordMismatch || !termsAccepted">Sign up</button>
        <button v-if="isPending" disabled class="w-full bg-gray-500 text-white font-bold py-2 px-4 rounded cursor-not-allowed">Loading</button>
      </form>
    </div>
  </div>
</template>

<script>
import useSignup from '@/composables/useSignup'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const { error, signup, isPending } = useSignup()
    const router = useRouter()

    const email = ref('')
    const password = ref('')
    const confirmPassword = ref('')
    const passwordMismatch = ref(false)
    const termsAccepted = ref(false)

    const handleSubmit = async () => {
      if (password.value !== confirmPassword.value) {
        error.value = "Passwords don't match"
        return
      }

      if (!termsAccepted.value) {
        error.value = "You must agree to the terms of service"
        return
      }

      const res = await signup(email.value, password.value)
      if (!error.value) {
        router.push({ name: 'Worker' })
      }
    }

    // Watch for changes in password and confirmPassword
    watch([() => password.value, () => confirmPassword.value], () => {
      passwordMismatch.value = password.value !== confirmPassword.value
    })

    return { 
      email, 
      password, 
      confirmPassword, 
      handleSubmit, 
      error, 
      isPending, 
      passwordMismatch, 
      termsAccepted 
    }
  }
}
</script>

<template>
  <div class="p-1 pb-24 mb-auto h-full text-white overflow-auto" id="style-1">
    <div class=" mx-auto space-y-2">
      <div><input type="text" v-model="uid"  class="w-full p-1 border text-center text-gray-900" placeholder="UID"></div>
      <div @click="addWilliam" class="p-1 bg-sky-500 hover:bg-sky-600 duration-300 text-center rounded cursor-pointer">เพิ่มวิลเลี่ยม</div>
      <div class="grid grid-cols-4 sm:grid-cols-3 lg:grid-cols-5 gap-2">
        <div class="bg-gray-900 p-4 rounded-lg text-center shadow-md hover:shadow-lg transition-shadow duration-300">
          <div class="text-xs sm:text-sm text-gray-400">สร้างคีย์สัตว์เลี้ยง</div>
          <div class="text-xl font-bold">{{ stat.goldTotal }}</div>
        </div>
        <div class="bg-gray-900 p-4 rounded-lg text-center shadow-md hover:shadow-lg transition-shadow duration-300">
          <div class="text-xs sm:text-sm text-gray-400">สร้างคีย์VIP</div>
          <div class="text-xl font-bold">{{ stat.workerKeyCreated }}</div>
        </div>
      </div>
      <div class="grid grid-cols-3 gap-1">
        <div @click="downloadWorkerData" class="p-3 bg-gray-900 hover:bg-indigo-900 rounded-lg cursor-pointer duration-300 text-center">1</div>
        <div @click="downloadItemData" class="p-3 bg-gray-900 hover:bg-indigo-900 rounded-lg cursor-pointer duration-300 text-center">2</div>
        <div @click="downloadUserDoc" class="p-3 bg-gray-900 hover:bg-indigo-900 rounded-lg cursor-pointer duration-300 text-center">3</div>
      </div>
      <div class="text-center">ประวัติแลกเปลี่ยคนงาน</div>
      <TranferWorkerLogs />
      <div class="text-center">ประวัตแลกเปลี่ยนเหรียญ</div>
      <SilverTranferLogs />
      <div class="text-center">ประวัตโอนไอเทม</div>
      <TranferItemLogs />
    </div>
  </div>
</template>

<script>
import ModalRemovePartner from '../components/admin/ModalRemovePartner.vue'
import { ref } from 'vue'
import { projectFirestore } from '@/firebase/config'
import TranferWorkerLogs from '../components/admin/TranferWorkerLogs.vue'
import SilverTranferLogs from '../components/admin/TranferSilverLogs.vue'
import TranferItemLogs from '../components/admin/TranferItemLogs.vue'

export default {
  components: { ModalRemovePartner,TranferWorkerLogs,SilverTranferLogs,TranferItemLogs },
  setup() {
    const toggleModal = ref('')
    const stat = ref({})
    
    projectFirestore.collection('server').doc('stat').onSnapshot(snapshot => {
      stat.value = snapshot.data()
    })

    const getCurrentDate = () => {
      const now = new Date()
      const year = now.getFullYear()
      const month = String(now.getMonth() + 1).padStart(2, '0')
      const day = String(now.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    }

    const downloadWorkerData = () => {
      projectFirestore.collection('workers').get().then(snapshot => {
        let workers = []
        snapshot.forEach(workerDoc => {
          workers.push(workerDoc.data())
        })
        const workerDataText = workers.map(worker => JSON.stringify(worker)).join('\n')
        const element = document.createElement('a')
        const file = new Blob([workerDataText], { type: 'text/plain' })
        const date = getCurrentDate()
        element.href = URL.createObjectURL(file)
        element.download = `workers_${date}.txt`
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      })
    }

    const downloadItemData = () => {
      projectFirestore.collection('items').get().then(snapshot => {
        let items = []
        snapshot.forEach(itemDoc => {
          let itemData = itemDoc.data()
          itemData.id = itemDoc.id  // Add document ID to the data
          items.push(itemData)
        })
        const itemDataText = items.map(item => JSON.stringify(item)).join('\n')
        const element = document.createElement('a')
        const file = new Blob([itemDataText], { type: 'text/plain' })
        const date = getCurrentDate()
        element.href = URL.createObjectURL(file)
        element.download = `items_${date}.txt`
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      })
    }

    const downloadUserDoc = () => {
      projectFirestore.collection('users').where('silver', '>=', 100).get().then(snapshot => {
        let users = []
        snapshot.forEach(userDoc => {
          users.push(userDoc.data())
        })
        const userDataText = users.map(user => JSON.stringify(user)).join('\n')
        const element = document.createElement('a')
        const file = new Blob([userDataText], { type: 'text/plain' })
        const date = getCurrentDate()
        element.href = URL.createObjectURL(file)
        element.download = `users_${date}.txt`
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      })
    }
    

    // projectFirestore.collection('items').add({
    //   name:'เกาะซองอาวุธ+2',health: 0, attack: 0, defend: 2,accuracy: 0,star: 2, textColor: 'text-amber-500', type:'armor', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/images%2Fholster.png?alt=media&token=b537af7a-1fa2-4d0a-96df-385e51d3939a',
    //   used: false,
    //   ownerUid: '7ghl25Ph9qS28r1H9wb7h57TOU72',
    //   createdAt: new Date(),
    //   ownerHistory: [`7ghl25Ph9qS28r1H9wb7h57TOU72`],
    //   changeOwnerCooldown : new Date(),
    //   removeWorkerItem: new Date(),
    // })

    const now = new Date();
    const uid = ref('')
const addWilliam = e => {
  projectFirestore.collection('workers').add({
    ownerUid: uid.value,
    name: 'วิลเลี่ยม',
    isLive: true,
    health: 150,
    hit: 0,
    star: 5,
    attack: 15,
    defend: 1,
    accuracy: 2,
    createdAt: now,
    updated: now,
    items: [],
    id: '',
    img: 'https://firebasestorage.googleapis.com/v0/b/mdtboss.appspot.com/o/thief%2Fwilliam.png?alt=media&token=24e44745-1eb8-47a6-811b-d26f10b52654',
    changeOwner: now,
    textColor: 'text-sky-500',
    typeItemUsed: [],
    collectionUsed: [],
    collections: [],
    maps: [],
    work: false,
    position: '',
    itemHealth: 0,
    itemDefend: 0,
    itemAttack: 0,
    itemAccuracy: 0,
    skillHealth: 0,
    skillDefend: 0,
    skillAttack: 0,
    skillAccuracy: 0,
    collectionHealth: 0,
    collectionDefend: 0,
    collectionAttack: 0,
    collectionAccuracy: 0,
    skills: [],
    silverPrize: 0,
    kill: 0,
    dead: 0,
    ownerHistory: [`${uid.value}`],
    born: now,
    vip: now,
    itemPower: 0,
    killWorker: 0,
    silverPrizeDay: 0,
    instantBorn: now,
    firstAid: now
  }).then(worker => {
    const workerId = worker.id;
    const workerPrivate = projectFirestore.collection('workerPrivate').doc(worker.id)

    // Update document after it's created to add the ID and other info
    projectFirestore.collection('workers').doc(workerId).update({
      id: workerId,
      ownerUid: uid.value, 
      createdAt: now
    });
    workerPrivate.set({
      ownerUid: uid.value, createdAt: now, id: workerId
    })
  }).catch(error => {
    console.error('Error adding worker: ', error);
  });
}

 
  projectFirestore.collection('workers').get().then(snapshot =>{
    snapshot.forEach(workerDoc =>{
      workerDoc.ref.update({
        instantBorn: new Date(),
           firstAid: new Date()

      })
    })
  })

    return { addWilliam,uid,toggleModal, stat, downloadWorkerData, downloadItemData, downloadUserDoc }
  },
}
</script>

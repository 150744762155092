<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-4/5 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div class="space-y-2">
            <div class="flex justify-center items-center space-x-1 text-base font-bold">
              <div >ราคา</div>
              <div>{{ order.price }}</div>
            <div><img src="../../assets/images/coin.png" class="w-3 sm:w-4"></div>
            </div>
            <div class="flex justify-center"><img :src="order.img" class="w-28"></div>
            
            <div class="flex items-center justify-center  space-x-1">
              <div class="flex justify-center items-center space-x-1 border border-gray-700 px-1 rounded">
                <div><img src="../../assets/images/star.png" class="w-3"></div>
                <div class=" font-bold text-sm">{{ order.star }}</div>
              </div>
              <div class="text-center font-bold text-sm">{{order.name}}</div>
              </div>
              <div class="flex justify-center space-x-1 items-center text-sm font-bold">
                <div v-if="order.health >= 1" class="flex items-center space-x-1">
                  <div><img src="../../assets/images/hearth.png" class="w-4"></div>
                  <div>{{ order.health  }}</div>
                </div>
                <div v-if="order.attack >= 1" class="flex items-center space-x-1">
                  <div><img src="../../assets/images/sword.png" class="w-4"></div>
                  <div>{{ order.attack }}</div>
                </div>
                <div v-if="order.defend >= 1" class="flex items-center space-x-1">
                  <div><img src="../../assets/images/shield.png" class="w-4"></div>
                  <div>{{ order.defend  }}</div>
                </div>
                <div v-if="order.accuracy >= 1" class="flex items-center space-x-1">
                  <div><img src="../../assets/images/target.png" class="w-4"></div>
                  <div>{{ order.accuracy  }}</div>
                </div>
              </div>
              
          </div>
          <div v-if="report" class="text-center text-sm text-red-500">{{ report }}</div>
          <div class="mt-2">
          <button @click="handleCancelOrder" v-if="!isPending" type="button" class="w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">ยกเลิกออเดอร์</button>
          <button v-if="isPending" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm duration-500 cursor-not-allowed">Loading...</button>
          <button @click="cancelModal" v-if="!isPending" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
        </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { projectFirestore, projectFunctions } from '@/firebase/config';
import { ref } from 'vue'
  export default {
    props: ['orderId'],
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}
        const order = ref({})
        projectFirestore.collection('orders').doc(props.orderId).get().then(snapshot =>{
            order.value = snapshot.data()
        })

        const isPending = ref(false)
        const report = ref('')

        const handleCancelOrder = e => {
        const cancelOrder = projectFunctions.httpsCallable('cancelOrder')
        isPending.value = true
        cancelOrder({ orderId: props.orderId }).then(result => {
            isPending.value = false
            report.value = result.data 
            if(report.value === 'ยกเลิกออเดอร์สำเร็จ'){ context.emit('cancelModal') }
        }).catch(err => {
            isPending.value = false
            report.value = err.message
        })
        }



        return { order,cancelModal,handleCancelOrder,isPending,report }
    },
  }
  </script>
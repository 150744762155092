<template>
  <ModalThief v-if="toggleModal === 'ModalThief'" :thiefId="thiefId" @cancelModal="toggleModal = ''" />
  <ModalItem v-if="toggleModal === 'ModalItem'" @cancelModal="toggleModal=''" :item="item" />
  <div class="p-1 sm:p-4">
    <div class="flex justify-center mb-1">
      <button 
        @click="handleManualRefresh"
        :disabled="countdown > 0"
        :class="{'bg-gray-500 cursor-not-allowed': countdown > 0, 'bg-indigo-900 hover:bg-indigo-800': countdown === 0}"
        class="w-full text-white font-bold py-2 px-4 rounded"
      >
        Refresh Data ({{ countdown }}s)
      </button>
    </div>
  </div>
  <div class="p-1 sm:px-4 sm:py-1 pb-16 sm:pb-16 space-y-1 mb-auto h-full text-white overflow-auto" id="style-1">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <div v-for="thief in thiefs" :key="thief.id">
        <div class="flex items-center p-3 bg-gray-900 duration-300 rounded-lg shadow space-y-0.5">
          <div class="w-full">
            <div @click="handleModalThief('ModalThief', thief.id)" class="hover:bg-indigo-900 duration-300 cursor-pointer rounded p-1 mb-1">
              <div class="flex justify-center items-center space-x-1 text-center text-gray-300">
                <div><img src="../assets/images/roadsign.png" class="w-3"></div>
                <div class="font-bold">{{ thief.position }}</div>
              </div>
              <div class="flex items-center space-x-1">
                <div class="flex justify-center"><img :src="thief.img" class="w-14 sm:w-20 rounded-full bg-indigo-900"></div>
                <div class="w-full space-y-0.5">
                  <div class="flex justify-between space-x-1">
                    <div class="flex items-center space-x-1 font-bold">
                      <div class="flex items-center space-x-1 border px-2 rounded border-gray-600">
                        <div><img src="../assets/images/star.png" class="w-2 sm:w-4"></div>
                        <div class="text-xs sm:text-base">{{thief.star}}</div>
                      </div>
                      <div class="text-center text-xs sm:text-sm">{{ thief.name }}</div>
                    </div>
                    <div class="flex justify-center items-center space-x-1 mb-1 text-white text-xs sm:text-sm font-bold">
                      <div class="p-0.5 px-2 sm:px-1 flex items-center space-x-1 bg-red-800 rounded">
                        <div class="text-xs sm:text-sm">{{ thief.attack }}</div>
                        <img src="../assets/images/sword.png" class="w-2 sm:w-3.5">
                      </div>
                      <div class="p-0.5 px-2 sm:px-1 flex items-center space-x-1 bg-blue-800 rounded">
                        <div class="text-xs sm:text-sm">{{ thief.defend }}</div>
                        <img src="../assets/images/shield.png" class="w-2 sm:w-3.5">
                      </div>
                      <div class="p-0.5 px-2 sm:px-1 flex items-center space-x-1 bg-amber-900 rounded">
                        <div class="text-xs sm:text-sm">{{ thief.accuracy }}</div>
                        <img src="../assets/images/target.png" class="w-2 sm:w-3.5">
                      </div>
                    </div>
                  </div>
                  <div class="relative w-full h-3 sm:h-5 rounded bg-gray-300 text-xs text-gray-800 overflow-hidden">
                    <div class="bg-red-600 h-full" :style="{ width: 100 - ((thief.hit / thief.health) * 100) + '%' }"></div>
                    <div class="absolute inset-0 flex items-center text-gray-900 font-semibold justify-center px-1">
                      {{ thief.health - thief.hit }}/{{ thief.health }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="thief.drops.length"  class="grid grid-cols-3 sm:grid-cols-4 gap-1 border p-1 border-gray-700 rounded-lg">
              <div v-for="item in thief.drops" :key="item">
                <div @click="handleModalItem('ModalItem', item)" class="flex justify-center items-center space-x-1 cursor-pointer opacity-80 hover:opacity-100">
                  <div><img :src="item.img" class="w-3.5 sm:w-5"></div>
                  <div :class="item.textColor" class="text-xs sm:text-sm">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <ThiefData /> -->
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import { projectFirestore } from '@/firebase/config'
import ThiefData from '@/components/thief/ThiefData.vue'
import ModalThief from '@/components/thief/ModalThief.vue'
import ModalItem from '../components/thief/ModalItem.vue'

export default {
  components: { ThiefData, ModalThief, ModalItem },
  setup() {
    const thiefs = ref([])
    const countdown = ref(180) // Initial countdown in seconds (3 minutes)

    const fetchThiefs = async () => {
      const snapshot = await projectFirestore.collection('thiefs')
        .where('isLive', '==', true)
        .orderBy('number', 'asc')
        .get()
      
      thiefs.value = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
    }

    const handleManualRefresh = () => {
      if (countdown.value === 0) {
        fetchThiefs()
        countdown.value = 180 // Reset the countdown after fetching data
      }
    }

    const thiefId = ref('')
    const toggleModal = ref('')
    const handleModalThief = (modal, id) => {
      thiefId.value = id
      toggleModal.value = modal
    }

    const item = ref({})
    const handleModalItem = (modal, itemData) => {
      toggleModal.value = modal
      item.value = itemData
    }

    onMounted(() => {
      fetchThiefs()
      const interval = setInterval(() => {
        if (countdown.value > 0) {
          countdown.value--
        }
      }, 1000) // Update countdown every second

      onUnmounted(() => {
        clearInterval(interval)
      })
    })

    return { fetchThiefs, handleManualRefresh, handleModalItem, item, thiefs, handleModalThief, thiefId, toggleModal, countdown }
  }
}
</script>

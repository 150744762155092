<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-4/5 items-end justify-center p-1 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-1 sm:p-3">
          <div class="grid grid-cols-3 gap-1">
            <div v-for="partner in partnerPackage" :key="partner">
              <div :class="partner.bgColor" class="bg-gray-900 space-y-1 p-1 rounded text-center">
                <div class="text-center text-xs">แพคเกจ</div>
                <div class="font-bold uppercase ">{{ partner.name }}</div>
                <div class="p-1 bg-gray-900 bg-opacity-50  rounded">
                  <div class="text-sm">คนงาน</div>
                  <div class="font-bold">{{partner.workerKey}} คีย์ / วัน</div>
                </div>
                <div class="p-1 bg-gray-900 bg-opacity-50  rounded">
                  <div class="text-sm">สัตว์เลี้ยง</div>
                  <div class="font-bold">{{partner.petKey}} คีย์ / วัน</div>
                </div>
                <div class="p-1 bg-gray-900 bg-opacity-50 rounded">
                  <div class="text-sm">VIP</div>
                  <div class="font-bold">{{partner.vipKey}} คีย์ / วัน</div>
                </div>
                <div class="p-1 bg-gray-900 bg-opacity-50 rounded">
                  <div class="text-sm">เพิ่มเหรียญ</div>
                  <div class="font-bold">{{partner.silverKey}} คีย์ / วัน</div>
                </div>
                <div class="p-1 ">
                  <div>ราคา</div>
                  <div class="font-bold">{{partner.price}} บาท</div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <div class="text-red-500 text-center">**ข้อมูลแพคเกจสามารถเปลี่ยนได้ตลอดโดยไม่แจ้งให้ทราบ</div>
            <div class="text-center font-bold">สามารถติดต่อซื้อแพคเกจได้ที่</div>
            <div class="text-center font-bold">เฟสบุ๊ค: WorkerKey</div>
          </div>
          <div class="mt-2">
          <button @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
        </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
  export default {
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}

        const partnerPackage = ref([
          {name:'free', bgColor: 'bg-blue-900', price: 0, workerKey: 20, petKey: 1, vipKey: 1, silverKey: 1},
          {name:'silver', bgColor: 'bg-indigo-900', price: 100, workerKey: 30, petKey: 5, vipKey: 5, silverKey: 5},
          {name:'gold', bgColor: 'bg-yellow-600', price: 300, workerKey: 50, petKey: 10, vipKey: 10, silverKey: 10},
        ])

        return { cancelModal,partnerPackage }
    },
  }
  </script>
<template>
  <ModalProfile v-if="toggleModal === 'ModalProfile'" :profileUid="profileUid" @cancelModal="toggleModal =''" />
  <div class="w-full m-auto">
    <div class="overflow-x-auto">
      <div>
        <select @change="changeFilter" class="w-full rounded p-2 text-gray-900 bg-gray-300">
          <option v-for="option in filterOptions" :key="option" :value="option.filter">{{ option.message }}</option>
          <option v-if="admin" value="silver">จำนวนเหรียญ</option>
        </select>
      </div>
      <table class="min-w-full bg-gray-800 rounded-lg shadow-lg mt-2">
        <thead>
          <tr class="bg-indigo-900 text-left text-gray-300">
            <th class="py-3 px-6">ผู้เล่น</th>
            <th v-if="filterSelected === 'totalPrizeSilver'" class="py-3 px-6 text-center cursor-pointer">รางวัลเหรียญที่ได้รับ</th>
            <th v-if="filterSelected === 'killThief'" class="py-3 px-6 text-center cursor-pointer">สังหารโจร</th>
            <th v-if="filterSelected === 'openWorkerBox'" class="py-3 px-6 text-center cursor-pointer">กล่องคนงาน</th>
            <th v-if="filterSelected === 'silver'" class="py-3 px-6 text-center cursor-pointer">จำนวนเหรียญ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in users" :key="index" 
              class="border-b border-gray-700 bg-gray-900 hover:bg-opacity-80 cursor-pointer"
              @click="goToProfile(user.uid)">
            <td class="flex items-center space-x-1 py-3 px-6 font-bold uppercase">
              <div class="w-10 bg-gray-700 text-center rounded-lg text-xs p-0.5 font-normal sm:font-bold">{{ index + 1 }}</div>
              <div><img :src="user.profile" class="w-8 sm:w-10"></div>
              <div class="font-normal sm:font-fold text-xs sm:text-sm">{{ user.name }}</div>
            </td>
            <td v-if="filterSelected === 'totalPrizeSilver'" class="py-3 px-6 text-center">{{ user.totalPrizeSilver }}</td>
            <td v-if="filterSelected === 'killThief'" class="py-3 px-6 text-center">{{ user.killThief }}</td>
            <td v-if="filterSelected === 'openWorkerBox'" class="py-3 px-6 text-center">{{ user.openWorkerBox }}</td>
            <td v-if="filterSelected === 'silver'" class="py-3 px-6 text-center">{{ user.silver }}</td>
          </tr>
        </tbody>
      </table>
      <button @click="loadMore" class="w-full py-2 mt-2 text-center text-gray-900 bg-gray-300 rounded">Load More</button>
    </div>
  </div>
</template>

<script>
import { projectFirestore } from '@/firebase/config'
import { ref, watch } from 'vue'
import ModalProfile from './ModalProfile.vue'
import getUser from '@/composables/getUser'

export default {
  components: { ModalProfile },
  setup() {
    const { admin } = getUser()
    const filterOptions = ref([
      {filter: 'killThief', message: 'สังหารโจร'},
      {filter: 'totalPrizeSilver', message: 'เหรียญเงินที่ได้รับ'},
      {filter: 'openWorkerBox', message: 'เปิดกล่องคนงาน'},
    ])
    const filterSelected = ref('killThief')
    const users = ref([])
    const lastVisible = ref(null)

    const fetchUsers = (reset = false) => {
      let query = projectFirestore.collection('users')
        .orderBy(filterSelected.value, 'desc')
        .orderBy('createdAt', 'asc')
        .limit(15)

      if (lastVisible.value && !reset) {
        query = query.startAfter(lastVisible.value)
      }

      query.get().then(snapshot => {
        if (snapshot.empty) {
          // No more users to load
          return
        }
        const newUsers = []
        snapshot.forEach(userDoc => {
          newUsers.push({ id: userDoc.id, ...userDoc.data() })
        })
        if (reset) {
          users.value = newUsers
        } else {
          users.value = [...users.value, ...newUsers]
        }
        lastVisible.value = snapshot.docs[snapshot.docs.length - 1]
      })
    }

    watch(filterSelected, () => {
      users.value = []
      lastVisible.value = null
      fetchUsers(true)
    }, { immediate: true })

    const changeFilter = (event) => {
      filterSelected.value = event.target.value
    }

    const profileUid = ref('')
    const toggleModal = ref('')
    const handleModal = (modal, uid) => {
      toggleModal.value = modal
      profileUid.value = uid
    }

    const goToProfile = (uid) => {
      window.open(`/profile/${uid}`, '_blank');
    }

    const loadMore = () => {
      fetchUsers()
    }

    return {
      admin,
      goToProfile,
      filterOptions,
      users,
      filterSelected,
      changeFilter,
      profileUid,
      toggleModal,
      handleModal,
      loadMore
    }
  }
}

</script>

<style scoped>
#style-1 {
  scrollbar-width: thin;
  scrollbar-color: #6b7280 #2d3748;
}

#style-1::-webkit-scrollbar {
  width: 12px;
}

#style-1::-webkit-scrollbar-track {
  background: #2d3748;
}

#style-1::-webkit-scrollbar-thumb {
  background-color: #6b7280;
  border-radius: 6px;
  border: 3px solid #2d3748;
}
</style>

<template>
    <div class="p-1 pb-16 mb-auto h-full text-white overflow-auto" id="style-1">
      <div class="max-w-screen-xl m-auto bg-gray-900 p-3 rounded mb-3">
        <div class="text-xl font-bold text-center">สิ่งที่คุณจะได้รับเมื่อคุณเป็นพันธมิตรกับเรา</div>
        <div class="grid grid-cols-2 text-center text-xs sm:text-sm my-3">
          <div class="p-1 bg-gray-800 border-gray-700 border">
            <div>คีย์คนงาน</div>
            <div class="text-xs sm:text-base font-bold">20 คีย์ / วัน</div>
          </div>
          <div class="p-1 bg-gray-800 border-gray-700 border">
            <div>คีย์ไอเทมสัตว์</div>
            <div class="text-xs sm:text-base font-bold">1 คีย์ / วัน</div>
          </div>
          <div class="p-1 bg-gray-800 border-gray-700 border">
            <div>คีย์ VIP 3 วัน</div>
            <div class="text-xs sm:text-base font-bold">1 คีย์ / วัน</div>
          </div>
          <div class="p-1 bg-gray-800 border-gray-700 border">
            <div>คีย์เพิ่มเหรียญ</div>
            <div class="text-xs sm:text-base font-bold">1 คีย์ / วัน</div>
          </div>
        </div>
        <div class="flex items-center space-x-2 justify-center bg-sky-600 rounded p-3 font-bold text-sm sm:text-base text-center hover:bg-sky-700 cursor-pointer duration-300">
          <div><img src="../assets/images/facebook.png" class="w-6 sm:w-8"></div>
          <div>ติดต่อสมัครได้ที่ข้อความเฟสบุ้ค Workerkey</div>
        </div>
        <div class="text-xl font-bold text-center mb-1 p-3 rounded">จังหวัดและจำนวนที่เปิดรับ</div>
        <table class="table-auto w-full text-sm">
          <thead>
            <tr>
              <th class="px-4 py-2 bg-gray-800 border-gray-700 border">จังหวัด</th>
              <th class="px-4 py-2 bg-gray-800 border-gray-700 border">จำนวนที่เปิดรับ</th>
            </tr>
          </thead>
          <tbody class="text-center">
            <tr v-for="(province, index) in provinces" :key="index">
              <td class="border border-gray-700 px-4 py-2">{{ province.name }}</td>
              <td class="border border-gray-700 px-4 py-2">{{ province.amount }} ร้าน</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
import { ref } from 'vue'
  export default {
      setup() {
        const provinces = ref([
            { name: 'กรุงเทพมหานคร', amount: 10 },
            { name: 'กระบี่', amount: 1 },
            { name: 'กาญจนบุรี', amount: 1 },
            { name: 'กาฬสินธุ์', amount: 1 },
            { name: 'กำแพงเพชร', amount: 1 },
            { name: 'ขอนแก่น', amount: 3 },
            { name: 'จันทบุรี', amount: 1 },
            { name: 'ฉะเชิงเทรา', amount: 1 },
            { name: 'ชลบุรี', amount: 3 },
            { name: 'ชัยนาท', amount: 1 },
            { name: 'ชัยภูมิ', amount: 1 },
            { name: 'ชุมพร', amount: 1 },
            { name: 'เชียงราย', amount: 3 },
            { name: 'เชียงใหม่', amount: 3 },
            { name: 'ตรัง', amount: 1 },
            { name: 'ตราด', amount: 1 },
            { name: 'ตาก', amount: 1 },
            { name: 'นครนายก', amount: 1 },
            { name: 'นครปฐม', amount: 3 },
            { name: 'นครพนม', amount: 1 },
            { name: 'นครราชสีมา', amount: 5 },
            { name: 'นครศรีธรรมราช', amount: 3 },
            { name: 'นครสวรรค์', amount: 2 },
            { name: 'นนทบุรี', amount: 5 },
            { name: 'นราธิวาส', amount: 1 },
            { name: 'น่าน', amount: 1 },
            { name: 'บึงกาฬ', amount: 1 },
            { name: 'บุรีรัมย์', amount: 3 },
            { name: 'ปทุมธานี', amount: 3 },
            { name: 'ประจวบคีรีขันธ์', amount: 1 },
            { name: 'ปราจีนบุรี', amount: 1 },
            { name: 'ปัตตานี', amount: 1 },
            { name: 'พระนครศรีอยุธยา', amount: 2 },
            { name: 'พังงา', amount: 1 },
            { name: 'พัทลุง', amount: 1 },
            { name: 'พิจิตร', amount: 1 },
            { name: 'พิษณุโลก', amount: 2 },
            { name: 'เพชรบุรี', amount: 1 },
            { name: 'เพชรบูรณ์', amount: 1 },
            { name: 'แพร่', amount: 1 },
            { name: 'พะเยา', amount: 1 },
            { name: 'ภูเก็ต', amount: 2 },
            { name: 'มหาสารคาม', amount: 1 },
            { name: 'มุกดาหาร', amount: 1 },
            { name: 'แม่ฮ่องสอน', amount: 1 },
            { name: 'ยโสธร', amount: 1 },
            { name: 'ยะลา', amount: 1 },
            { name: 'ร้อยเอ็ด', amount: 1 },
            { name: 'ระนอง', amount: 1 },
            { name: 'ระยอง', amount: 3 },
            { name: 'ราชบุรี', amount: 1 },
            { name: 'ลพบุรี', amount: 1 },
            { name: 'ลำปาง', amount: 1 },
            { name: 'ลำพูน', amount: 1 },
            { name: 'เลย', amount: 1 },
            { name: 'ศรีสะเกษ', amount: 1 },
            { name: 'สกลนคร', amount: 1 },
            { name: 'สงขลา', amount: 1 },
            { name: 'สตูล', amount: 1 },
            { name: 'สมุทรปราการ', amount: 3 },
            { name: 'สมุทรสงคราม', amount: 1 },
            { name: 'สมุทรสาคร', amount: 3 },
            { name: 'สระแก้ว', amount: 1 },
            { name: 'สระบุรี', amount: 1 },
            { name: 'สิงห์บุรี', amount: 1 },
            { name: 'สุโขทัย', amount: 1 },
            { name: 'สุพรรณบุรี', amount: 1 },
            { name: 'สุราษฎร์ธานี', amount: 1 },
            { name: 'สุรินทร์', amount: 1 },
            { name: 'หนองคาย', amount: 1 },
            { name: 'หนองบัวลำภู', amount: 1 },
            { name: 'อ่างทอง', amount: 1 },
            { name: 'อำนาจเจริญ', amount: 1 },
            { name: 'อุดรธานี', amount: 1 },
            { name: 'อุตรดิตถ์', amount: 1 },
            { name: 'อุทัยธานี', amount: 1 },
            { name: 'อุบลราชธานี', amount: 1 }
        ]);

        return {  provinces  }
      },
  }
  </script>
import { ref } from 'vue'
import { projectAuth, projectFirestore } from '../firebase/config'

// refs
const user = ref(projectAuth.currentUser)
const admin = ref(false)
const gold = ref(0)
const silver = ref(0)
const partnerPackage = ref('')
const partner = ref(false)
const workerKey = ref(0)
const petKey = ref(0)
const vipKey = ref(0)
const remainingEvidence = ref()
const remainingPackage = ref()
const vip = ref()
const getFreeWorker = ref(true)
const getFreeItem = ref(true)

// auth changes
const getUser = () => {
  // Ensure user is defined before attempting to access properties
  projectAuth.onAuthStateChanged((_user) => {
    user.value = _user 

    if (_user) {
      projectFirestore.collection('users').doc(_user.uid).onSnapshot((snap) => {
        admin.value = snap.data()?.admin || false
        gold.value = snap.data()?.gold || 0
        silver.value = snap.data()?.silver || 0
        partnerPackage.value = snap.data()?.package || 'free'
        partner.value = snap.data()?.partner || false
        workerKey.value = snap.data()?.workerKey || 0
        petKey.value = snap.data()?.petKey || 0
        vipKey.value = snap.data()?.vipKey || 0
        remainingEvidence.value = snap.data()?.remainingEvidence || new Date()
        remainingPackage.value = snap.data()?.remainingPackage || new Date()
        vip.value = snap.data()?.vip.toDate() || new Date()
        getFreeWorker.value = snap.data()?.getFreeWorker || false
        getFreeItem.value = snap.data()?.getFreeItem || false
      })
    }
  })

  return { getFreeItem,getFreeWorker,vip,user, admin, gold,silver,
    partnerPackage,
    partner,
    workerKey,
    petKey,
    vipKey,remainingEvidence,
    remainingPackage }
}

export default getUser
<template>
   <div class="grid grid-cols-3 border-gray-700 border font-bold text-xs sm:text-sm">
    <div @click="menu = 'Buy'" :class="[menu === 'Buy' ? 'bg-indigo-800' : 'bg-indigo-900 hover:bg-indigo-900']" class="p-2  duration-500 text-center cursor-pointer">ซื้อ</div>
    <div @click="menu = 'Sell'" :class="[menu === 'Sell' ? 'bg-indigo-800' : 'bg-indigo-900 hover:bg-indigo-900']"   class="p-2  duration-500 text-center cursor-pointer">ขาย</div>
    <div @click="menu = 'History'" :class="[menu === 'History' ? 'bg-indigo-800' : 'bg-indigo-900 hover:bg-indigo-900']"  class="p-2  duration-500 text-center cursor-pointer">ประวัติซื้อขาย</div>
   </div>
   <Buy v-if="menu === 'Buy'" />
      <Sell v-if="menu === 'Sell'" />
      <History v-if="menu === 'History'" />
  </template>
  <script>
  import { ref } from 'vue';
import Buy from '../components/marketplace/Buy.vue'
import Sell from '../components/marketplace/Sell.vue'
import History from '../components/marketplace/History.vue'
  export default {
    components : { Buy,History,Sell },
    setup() {
      const menu = ref('Buy')

      return { menu }
    },
  }
  </script>
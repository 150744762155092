<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-full pb-16 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-2xl">
        <div class="bg-gray-800 text-white p-3">
          <div class="space-y-1 sm:space-y-2">
            <div class="flex justify-center space-x-1 items-center text-gray-400">
              <div><img src="../../assets/images/roadsign.png" class="w-5"></div>
              <div>{{ thief.position }}</div>
            </div>
            <div class="flex justify-center"><img :src="thief.img" class="w-20"></div>
            <div class="flex justify-center items-center space-x-1">
              <div class="flex items-center space-x-1 border px-1  rounded  border-gray-600">
                <div><img src="../../assets/images/star.png" class="w-3"></div>
                <div class="text-sm font-bold">{{thief.star}}</div>
              </div>
              <div class="text-xs sm:text-base font-bold">{{ thief.name }}</div>    
            </div>
            <div class="flex items-center justify-center font-bold space-x-1 text-xs sm:text-sm">
              <div class="p-1 w-16 flex items-center justify-center space-x-1 bg-pink-900 rounded">
                <div>{{ thief.health }}</div>
                <div><img src="../../assets/images/hearth.png" class="w-5"></div>
              </div>
              <div class="p-1 w-16 flex items-center justify-center space-x-1 bg-red-900 rounded">
                <div>{{ thief.attack }}</div>
                <div><img src="../../assets/images/sword.png" class="w-5"></div>
              </div>
              <div class="p-1 w-16 flex items-center justify-center space-x-1 bg-blue-900 rounded">
                <div>{{ thief.defend }}</div>
              <div><img src="../../assets/images/shield.png" class="w-5"></div>
              </div>
              <div class="p-1 w-16 flex items-center justify-center space-x-1 bg-amber-900 rounded">
                <div>{{ thief.accuracy }}</div>
              <div><img src="../../assets/images/target.png" class="w-5"></div>
              </div>
            </div>
            <div class="text-center text-sm">ค่าหัว</div>
            <div class="flex justify-center items-center space-x-1">
              <div class="font-bold text-xl">{{ thief.silver }}</div>
              <div><img src="../../assets/images/coin.png" class="w-5"></div>
            </div>
            <div class="text-center text-sm">ไอเทมที่จะดรอป (โอกาส {{ thief.chanceDropItem }}%)</div>
            <div class="text-center text-sm text-gray-300">(อนาคตข้างหน้าจะเปลี่ยนรูปภาพไอทมให้มีความสอดคล้องกับชื่อไอเทมมากขึ้น)</div>
            <div class="grid grid-cols-1 gap-1 h-32 overflow-auto rounded" id="style-1">
              <div v-for="drop in thief.drops" :key="drop">
                <div class="flex justify-between p-1 bg-gray-900 rounded-lg shadow">
                  <div class="flex items-center space-x-1">
                    <div class="flex justify-center"><img :src="drop.img" class="w-8 sm:w-10"></div>
                    <div class="flex items-center space-x-1 justify-center mt-1">
                      <div class="flex items-center space-x-1 border px-1  rounded  border-gray-600">
                        <div><img src="../../assets/images/star.png" class="w-2.5"></div>
                        <div class="text-xs font-bold">{{drop.star}}</div>
                      </div>
                      <div :class="drop.textColor" class="text-sm text-center">{{ drop.name }}</div>
                    </div>
                  </div>
                  <div class="mt-1 flex items-center justify-center font-bold space-x-1 text-sx sm:text-sm">
                    <div v-if="drop.health >= 1" class="px-0.5 w-12 flex items-center justify-center space-x-1 bg-pink-900 rounded">
                      <div>{{ drop.health }}</div>
                      <div><img src="../../assets/images/hearth.png" class="w-3"></div>
                    </div>
                    <div v-if="drop.attack >= 1" class="px-0.5 w-12 flex items-center justify-center space-x-1 bg-red-900 rounded">
                      <div>{{ drop.attack }}</div>
                      <div><img src="../../assets/images/sword.png" class="w-3"></div>
                    </div>
                    <div v-if="drop.defend >= 1" class="px-0.5 w-12 flex items-center justify-center space-x-1 bg-blue-900 rounded">
                      <div>{{ drop.defend }}</div>
                    <div><img src="../../assets/images/shield.png" class="w-3"></div>
                    </div>
                    <div v-if="drop.accuracy >= 1" class="px-0.5 w-12 flex items-center justify-center space-x-1 bg-amber-900 rounded">
                      <div>{{ drop.accuracy }}</div>
                    <div><img src="../../assets/images/target.png" class="w-3"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center text-sm">ดรอปคีย์</div>
            <div class="space-y-1 ">
              <div class="flex justify-between items-center bg-gray-900 rounded-lg p-1">
                <div class="flex items-center space-x-1">
                  <div><img src="../../assets/images//key.png" class="w-8 sm:w-10"></div>
                  <div class="text-sm text-center">คีย์คนงาน</div>
                </div>
                <div class="font-bold">{{ thief.chanceDropWorkerKey }}%</div>
              </div>
              <div class="flex justify-between items-center bg-gray-900 rounded-lg p-1">
                <div class="flex items-center space-x-1">
                  <div><img src="../../assets/images//key.png" class="w-8 sm:w-10"></div>
                  <div class="text-sm text-center">คีย์สัตว์เลี้ยง (เฉพาะคนงานมี VIP)</div>
                </div>
                <div class="font-bold">{{ thief.chanceDropPetKey }}%</div>
              </div>
            </div>
          </div>
          <div class="mt-1.5">
            <button @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
         </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
  export default {
    props:['thief'],
    setup(props,context) {
        const cancelModal = e =>{
            context.emit('cancelModal')
        }
        return { cancelModal }
    },
  }
  </script>
<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-full pb-16 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div v-if="report" :class="[report === 'เปลี่ยนชื่อสำเร็จ' ? 'text-green-500' : 'text-red-500']" class="text-sm text-center">{{ report }}</div>
          <form v-if="!isPending" @submit.prevent="changeWorkerName" class="mt-1.5">
            <input  v-model="newName" type="text" required class="w-full p-3 bg-gray-700 rounded border border-gray-600 focus:border-indigo-500 focus:outline-none text-center text-2xl" placeholder="กรอกชื่อคนงาน">
            <button   type="submit" class="mt-1.5 w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300 flex items-center space-x-1">
              <div>เปลี่ยนชื่อคนงาน</div>
              <div>200</div>
              <div><img src="../../assets/images/coin.png" class="w-5"></div>
            </button>
            <button   @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
          </form>
          <div>
            <div v-if="isPending" class="flex items-center justify-center h-20">
              <div><img src="../../assets/worker/peter.png" class="w-20 animate-spin"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
    import { projectFunctions } from '@/firebase/config'
    export default {
        props:['id'],
        setup(props,context) {
            const cancelModal = e =>{context.emit('cancelModal')}
            const newName = ref('')
            const report = ref('')
            const isPending = ref(false)
            const changeWorkerName = e =>{
              const changeWorkerName = projectFunctions.httpsCallable('changeWorkerName')
              isPending.value = true
              changeWorkerName({newName : newName.value, workerId: props.id}).then(result =>{
                isPending.value = false
                report.value = result.data
              }).catch(err =>{
                report.value = err.message
                isPending.value = false
              })
            }
            return { newName,cancelModal,changeWorkerName,isPending,report }
        },
    }
  </script>
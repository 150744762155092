<template>
  <ModalVip v-if="toggleModal === 'ModalVip'" @cancelModal="toggleModal = ''" />
  <ModalAddSilver v-if="toggleModal === 'ModalAddSilver'" @cancelModal="toggleModal =''" />
  <ModalLog v-if="toggleModal === 'ModalLog'" @cancelModal="toggleModal =''" />
  <ModalUpdateUser v-if="toggleModal === 'ModalUpdateUser'" @cancelModal = "toggleModal =''" />
  <ModalTranferSilver v-if="toggleModal === 'ModalTranferSilver'" @cancelModal = "toggleModal = ''" />
  <div class="p-1 sm:p-4 pb-16 mb-auto h-full text-white overflow-auto" >
    <div class="m-auto max-w-xl space-y-2">
      <div class="p-1 bg-gray-900 rounded text-center space-y-1">
        <div class="text-sm text-gray-400">UID ของฉัน</div>
        <div class="flex justify-center font-bold text-base">
          <div class="hover:text-indigo-500 cursor-pointer"  @click="copyUidToClipboard">{{ user.uid }}</div>  
        </div>
        <div v-if="reportCoppyUid" class="text-xs text-lime-500 text-center">{{ reportCoppyUid }}</div>
        <div class="text-sm text-gray-400">จำนวนเหรียญเงิน</div>
        <div class="flex justify-center items-center space-x-1">
          <div class="font-bold text-base">{{ silver.toLocaleString() }}</div>
          <div><img src="../assets/images/coin.png" class="w-5"></div>
        </div>
      </div>
      <div @click="toggleModal = 'ModalUpdateUser'" class="bg-indigo-500 hover:bg-indigo-400 text-white font-bold py-2 px-4 border-b-4 border-indigo-700 hover:border-indigo-500 rounded cursor-pointer duration-300">
        <div class="text-center">แก้ไขโปรไฟล์</div>
      </div>
      <div @click="toggleModal = 'ModalAddSilver'" class="bg-indigo-500 hover:bg-indigo-400 text-white font-bold py-2 px-4 border-b-4 border-indigo-700 hover:border-indigo-500 rounded cursor-pointer duration-300">
        <div class="text-center">เติมคีย์เพิ่มเหรียญ</div>
      </div>
      <div @click="toggleModal = 'ModalTranferSilver'" class="bg-indigo-500 hover:bg-indigo-400 text-white font-bold py-2 px-4 border-b-4 border-indigo-700 hover:border-indigo-500 rounded cursor-pointer duration-300">
        <div class="text-center">โอนเหรียญเงิน</div>
      </div>
      <div @click="toggleModal = 'ModalLog'" class="bg-indigo-500 hover:bg-indigo-400 text-white font-bold py-2 px-4 border-b-4 border-indigo-700 hover:border-indigo-500 rounded cursor-pointer duration-300">
        <div class="text-center">ดูประวัติ</div>
      </div>
      <div>
        <a href="/terms" target="_blank" >
          <div  class="bg-gray-500 hover:bg-gray-400 text-white font-bold py-2 px-4 border-b-4 border-gray-700 hover:border-gray-500 rounded cursor-pointer duration-300">
            <div class="text-center">เงื่อนไขการใช้งานเว็บไซต์</div>
          </div>
        </a>
      </div>
      <div @click="handleClick" class="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded cursor-pointer duration-300">
        <div class="text-center">ออกจากระบบ</div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { ref,onMounted } from 'vue';
import ModalVip from '../components/account/ModalVip.vue';
import Logs from '../components/account/Logs.vue';
import getUser from '@/composables/getUser';
import ModalAddSilver from '../components/account/ModalAddSilver.vue'
import ModalLog from '../components/account/ModalLog.vue'
import useLogout from '../composables/useLogout'
import ModalUpdateUser from '../components/account/ModalUpdateUser.vue'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs';
import ModalTranferSilver from '../components/account/ModalTranferSilver.vue'
import { projectFunctions } from '@/firebase/config';
export default {
  components: { ModalLog,ModalAddSilver,ModalVip, Logs,ModalUpdateUser,ModalTranferSilver },
  setup() {
    const { user, vip, silver, gold } = getUser();
    const toggleModal = ref('');
    const { logout } = useLogout()
    const router = useRouter()

    const handleClick = async () => {
      await logout()
      location.reload()
    }
    const now = new Date();
    const copiedUid = ref(false); // สร้าง ref สำหรับตรวจสอบว่า UID ได้ถูกคัดลอกหรือไม่
    const reportCoppyUid = ref('')
    function copyUidToClipboard() {
      const uid = user.value.uid; // ใช้ .value เพื่อเข้าถึงค่าของ ref
      navigator.clipboard.writeText(uid)
        .then(() => {
          copiedUid.value = true; // ปรับค่า ref เมื่อคัดลอกเสร็จสิ้น
          reportCoppyUid.value = 'คัดลอก UID ไปยังคลิปบอร์ดเรียบร้อยแล้ว'
        })
        .catch(err => {
          console.error('เกิดข้อผิดพลาดในการคัดลอก UID:', err);
        });
    }

    const formatDate = date => {
        return dayjs(date).format('DD/MM/YYYY');
    }

    const fetchIpAddress = async () => {
          try {
            const response = await fetch('https://ipinfo.io/json');
            const data = await response.json();
            console.log('User IP Address:', data.ip);
            const updateIp = projectFunctions.httpsCallable('updateIp');
            await updateIp({ ipAddress: data.ip });
          } catch (error) {
            console.error('Failed to fetch IP address:', error);
          }
        };

        onMounted(() => {
          fetchIpAddress();
        });

    return { reportCoppyUid,formatDate, handleClick,now, user, toggleModal, vip, copyUidToClipboard, copiedUid, silver, gold };
  },
};
</script>

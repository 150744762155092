<template>
    <div class="px-1 mb-auto h-full text-white  overflow-auto  pb-24" id="style-1">
        <div class="flex justify-center items-center h-full ">
        <form @submit.prevent="handleSubmit" class="bg-gray-900 p-8 rounded shadow-md w-full max-w-md">
            <h3 class="text-2xl font-bold mb-4 text-gray-100">เข้าสู่ระบบ</h3>
            <div class="mb-4">
                <label for="email" class="block text-gray-100 text-sm font-bold mb-2">อีเมลย์</label>
                <input type="email" id="email" placeholder="Enter your email" v-model="email" class="w-full p-1 border rounded-md focus:outline-none focus:border-blue-500 text-gray-900" />
            </div>
            <div class="mb-4">
                <label for="password" class="block text-gray-100 text-sm font-bold mb-2">รหัสผ่าน</label>
                <input type="password" id="password" placeholder="Enter your password" v-model="password" class="w-full p-1 border rounded-md focus:outline-none focus:border-blue-500 text-gray-900" />
            </div>
            <div v-if="error" class="text-red-500 mb-4 text-xs">{{ error }}</div>
            <button v-if="!isPending" class="bg-indigo-800 hover:bg-indigo-900 w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue">Log in</button>
            <button v-if="isPending" disabled class="w-full bg-gray-500 text-white font-bold py-2 px-4 rounded cursor-not-allowed">Loading</button>
        </form>
        </div>
    </div>
  </template>
  
  <script>
  import useLogin from '@/composables/useLogin'
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'
  
  export default {
    setup() {
      const { error, login, isPending } = useLogin()
      const router = useRouter()
  
      const email = ref('')
      const password = ref('')
  
      const handleSubmit = async () => {
        const res = await login(email.value, password.value)
        if (!error.value) {
          router.push({ name: 'Home' })
        }
      }
  
      return { email, password, handleSubmit, error, isPending }
    }
  }
  </script>
  
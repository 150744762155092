<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-4/5 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div class="flex items-center space-x-2 justify-center text-4xl font-bold p-3 rounded">
            <div>{{ amountOfBought }}</div>
            <div class="flex justify-center"><img src="../assets/images/ticket.png" class="w-10"></div>
          </div>
          <div class="bg-gray-900">
            <div class="text-center p-1 rounded text-gray-300">ตั๋วลุ้นคีย์คนงานทุกวันอาทิตย์เวลา 18.00น.</div>
          </div>
          <div class="mt-2">
            <button v-if="!isPending" @click="handleBuyTicket" type="button" class="w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">ซื้อตั๋ว {{ ticketPrice }} เหรียญเงิน</button>
            <button v-if="isPending"  type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   cursor-not-allowed">Loading...</button>
            <button v-if="!isPending" @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
import { projectFirestore, projectFunctions } from '@/firebase/config'
import getUser from '@/composables/getUser'
  export default {
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}
        const { user } = getUser()
        const ticketPrice = ref(100)
        const isPending = ref(false)
        const handleBuyTicket = e =>{
          const buyTicket = projectFunctions.httpsCallable('buyTicket')
          isPending.value = true
          buyTicket().then(result =>{
            isPending.value = false
          }).catch(err =>{
            isPending.value = false
          })
        }

        
        const ticket = ref([]);
        const amountOfBought = ref(0);
        const userUid = user.value.uid; // Replace this with the actual user uid

          projectFirestore.collection('serverPublic').doc('ticket').onSnapshot(snapshot => {
            if (snapshot.exists) {
              ticket.value = snapshot.data().uid || []; // Assuming 'id' is the field with the array of uids

              // Count the occurrences of the user's uid in the ticket array
              amountOfBought.value = ticket.value.filter(uid => uid === userUid).length;

              console.log('User UID appears:', amountOfBought.value, 'times');
            } else {
              console.log('No ticket data found');
            }
          });

          

        

        return { amountOfBought,ticketPrice,cancelModal,isPending,handleBuyTicket }
    },
  }
  </script>
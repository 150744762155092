<template>
  <div class="sm:px-4 flex items-center justify-end sm:justify-between p-1 text-gray-100 bg-gray-900">
    <router-link :to="{name : 'Home'}" class="hidden sm:block font-bold text-lg hover:text-indigo-500 duration-300">WorkerKEY</router-link>
    
    <div class="flex items-center space-x-2  text-xs sm:text-sm">
      <router-link v-if="!user" :to="{name : 'Signup'}" class="p-1 px-3  bg-indigo-800 hover:bg-indigo-900  rounded duration-300">สมัครสมาชิก</router-link>
      <router-link v-if="!user" :to="{name : 'Login'}" class="p-1 px-3  bg-indigo-800 hover:bg-indigo-900  rounded duration-300">ล็อคอิน</router-link>
      <router-link v-if="user" :to="{name : 'Ticket'}" class="hover:text-indigo-500 duration-300">ซื้อตั๋ว</router-link>
      <router-link v-if="user" :to="{name : 'Marketplace'}" class="hover:text-indigo-500 duration-300">Marketplace</router-link>
      <router-link v-if="user" :to="{name : 'Shop'}" class="hover:text-indigo-500 duration-300">ร้านค้า</router-link>
      <!-- <router-link v-if="user" :to="{name : 'PVP'}" class="hover:text-indigo-500 duration-300">PVP</router-link> -->
      <router-link v-if="user" :to="{name : 'Worker'}" class="hover:text-indigo-500 duration-300">คนงาน</router-link>
      <router-link v-if="user" :to="{name : 'Item'}" class="hover:text-indigo-500 duration-300">อุปกรณ์</router-link>
      <router-link v-if=" admin" :to="{name : 'Admin'}" class="hover:text-indigo-500 duration-300">แอดมิน</router-link>
      <router-link v-if="user" :to="{name : 'Key'}" class="hover:text-indigo-500 duration-300">คีย์ของฉัน</router-link>
      <router-link v-if="user" :to="{name : 'Account'}" class="hover:text-indigo-500 duration-300">บัญชี</router-link>
    </div>
  </div>
</template>


<script>
import getUser from '../composables/getUser'
import useLogout from '../composables/useLogout'
import { useRouter } from 'vue-router'
import { ref } from 'vue'

export default {
  setup() {
    const { user,admin,partner,silver } = getUser()
    const { logout } = useLogout()
    const router = useRouter()

    const handleClick = async () => {
      console.log('heelo')
      await logout()
      location.reload()
    }

    return { handleClick, user, admin,partner,silver }
  }
}
</script>

<style lang="scss" scoped>

</style>
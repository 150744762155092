<template>
    <div class="p-1  pb-24 mb-auto h-full text-white  overflow-auto" id="style-1">
        <div class="space-y-1 max-w-screen-xl m-auto">
            <ThiefData />
        </div>
      
    </div>
  </template>
  <script>
  import ThiefData from '../components/thief/ThiefData.vue'
  export default {
    components: { ThiefData },
      setup() {
          
      },
  }
  </script>
<template>
    <div class="max-w-screen-md m-auto h-64 overflow-auto" id="style-1">
      <div class="grid grid-cols-1 gap-1">
        <div v-for="log in logs" :key="log">
          <div class="flex items-center justify-between bg-gray-900 rounded p-1">
            <a :href="'https://www.workerkey.com/profile/' + log.senderUid" target="_blank" rel="noopener noreferrer">
              <img src="../../assets/images/cowboy.png" class="w-10">
            </a>
            <div class="text-xs sm:text-sm text-center">
                <a :href="'https://www.workerkey.com/worker/' + log.workerId" target="_blank" rel="noopener noreferrer">
                  <div>{{ log.message }}</div>
                </a>
                <div>({{ dayjs(log.createdAt.toDate()).locale('th').fromNow() }})</div>
            </div>
            <a :href="'https://www.workerkey.com/profile/' + log.receiverUid" target="_blank" rel="noopener noreferrer">
              <img src="../../assets/images/cowboy.png" class="w-10">
            </a>
          </div>
        </div>
      </div>
    </div>
  </template>
  
<script>
import { ref } from 'vue'
import { projectFirestore } from '@/firebase/config'
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import relativeTime from 'dayjs/plugin/relativeTime';

export default {
    setup() {
        dayjs.extend(relativeTime);
        const logs = ref([])
        projectFirestore.collection('logs').where('type','==','changeWorkerOwnerLog').limit(50).orderBy('createdAt','desc').onSnapshot(snapshot =>{
            snapshot.forEach(log =>{
                logs.value.push(log.data())
            })
        })

        return { logs,dayjs }
    },
}
</script>
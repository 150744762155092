<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-4/5 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
        <div class="bg-gray-800 text-white p-3">
          <div class="text-sm text-center">{{ report }}</div>
          <form v-if="!isPending" @submit.prevent="handleAddSilver" class="mt-1.5">
            <input  v-model="key" type="text" required class="w-full  bg-gray-300 p-3 rounded text-center text-xl text-gray-900" placeholder="กรอกคีย์">
            <button   type="submit" class="mt-1.5 w-full justify-center rounded bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 duration-300">เติมคีย์ 300 เหรียญเงิน</button>
            <button   @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
          </form>
          <div>
            <div v-if="isPending" class="flex items-center justify-center h-20">
              <div><img src="../../assets/images/coin.png" class="w-20 animate-spin"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
import { projectFunctions } from '@/firebase/config'
  export default {
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}
        const key = ref('')
        const report = ref('')
        const isPending = ref(false)
        const handleAddSilver = e =>{
          const addSilver = projectFunctions.httpsCallable('addSilver')
          isPending.value = true
          addSilver({password: key.value}).then(result =>{
            report.value = result.data
            isPending.value = false
            if(result.data === 'เติมเหรียญเงินสำเร็จ'){
              context.emit('cancelModal')
            }
          }).catch(err =>{
            isPending.value = false
          })
        }
        return { cancelModal,key,handleAddSilver,isPending,report }
    },
  }
  </script>
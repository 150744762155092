<template>
    <div class="grid grid-cols-3 gap-1">
       <div v-for="item in items" :key="item">
         <div :class="[item.name === itemSelected.name ? 'bg-yellow-900' : 'bg-gray-900']" class="bg-gray-900 rounded">
           <div class="flex justify-center"><img :src="item.img" class="w-12"></div>
             <div class="flex justify-center items-center space-x-1">
               <div><img src="../../assets/images/star.png" class="w-2.5"></div>
               <div class="font-bold text-xs">{{ item.star }}</div>
             </div>
             <div :class="item.textColor" class="text-center text-xs sm:text-sm ">{{ item.name }}</div>
           </div>
         </div>
       </div>   
   </template>
   
   <script>
   import { ref } from 'vue'
   export default {
       setup() {
         const items = ref([
         {name:'ดัลเมเชียน',health: 110, attack: 1, defend: 0, accuracy: 0,star: 1, textColor: 'text-white', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fdalmatian.png?alt=media&token=36550e66-073b-4384-872c-9131fcbd14c9'},
          {name:'บูลเทอร์เรีย',health: 120, attack: 2, defend: 0, accuracy: 0,star: 2, textColor: 'text-amber-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fbullterrier.png?alt=media&token=053515f5-9a40-404e-b6c1-fadd6a09eed3'},
          {name:'บ๊อกเซอร์',health: 130, attack: 3, defend: 0, accuracy: 0,star: 3, textColor: 'text-lime-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fboxer.png?alt=media&token=38d2078e-6dce-4e18-ad9d-b680b9c9eefe'},
          {name:'บูลด็อก',health: 140, attack: 4, defend: 0, accuracy: 0,star: 4, textColor: 'text-green-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fbulldog.png?alt=media&token=901c8fe8-30f6-4f7a-aaa5-dc6a89556f52'},
          {name:'บอร์เดอร์คอลลี่',health: 150, attack:5, defend: 0, accuracy: 0,star: 5, textColor: 'text-cyan-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fbordercollie.png?alt=media&token=9bebdac7-3dc0-45f9-9c8b-2f5aff8e77f3'},
          {name:'เกรทเดน',health: 160, attack: 6, defend: 3, accuracy: 0,star: 6, textColor: 'text-sky-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fgreatdane.png?alt=media&token=9eca6356-b999-4434-8f98-14ab37cd50d4'},
          {name:'เยอรมันเชพเพิร์ด',health: 170, attack: 7, defend: 5, accuracy: 1,star: 7, textColor: 'text-blue-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fgermanshepherd.png?alt=media&token=efd6ef7a-2fff-4d57-9df5-27569d378c39'},
          {name:'โดเบอร์แมน',health: 180, attack: 8, defend: 5, accuracy: 3,star: 8, textColor: 'text-indigo-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fdoberman.png?alt=media&token=1aa5bbf8-ee8e-4720-bb6b-0adba32b72cf'},
          {name:'พิทบูล',health: 190, attack: 9, defend: 10, accuracy: 7,star: 9, textColor: 'text-violet-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Fpitbull.png?alt=media&token=c5fe566a-ed20-4e80-8812-3449dc859601'},
          {name:'ร็อตไวเลอร์',health: 200, attack: 10, defend: 15, accuracy: 11,star: 10, textColor: 'text-pink-500', type:'pet', img:'https://firebasestorage.googleapis.com/v0/b/workerkey.appspot.com/o/item%2Fpet%2Frottweiler.png?alt=media&token=01b12ac2-a4c9-4ceb-9005-23e62c338177'},
         ])
   
         const itemSelected = ref({
           name: '',
           health: 0,
           star: 0,
           attack: 0,
           defend: 0,
           img: ''
           })
   
           setInterval(() => {
           let random = Math.floor(Math.random() * items.value.length);
           itemSelected.value = items.value[random];
           }, 100);
   
   
         return { items,itemSelected }
       },
   }
   </script>
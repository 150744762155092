<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex h-full pb-16 items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-xl">
        <div class="bg-gray-800 text-white p-3">
          <div class="space-y-0.5 sm:space-y-1">
            <div class="flex items-center justify-center space-x-1">
              <div><img :src="profile.profile" class="w-10"></div>
              <div class="text-center font-bold uppercase">{{profile.name}}</div>
            </div>
            <div v-if="profile.facebook" class="flex justify-center items-center space-x-1">
              <div><img src="../assets/images/facebook.png" class="w-4"></div>
              <div class="text-xs sm:text-sm">{{ profile.facebook }}</div>
            </div>
            <div v-if="profile.instagram" class="flex justify-center items-center space-x-1">
              <div><img src="../assets/images/instgram.png" class="w-4"></div>
              <div class="text-xs sm:text-sm">{{ profile.instagram }}</div>
            </div>
            <div class="bg-gray-900 p-1 sm:p-2 rounded text-xs sm:text-sm">
              <div id="breakAll">{{ profile.biography }}</div>
            </div>
            <div>
              <div class="text-xs text-gray-300">รายชื่อคนงาน</div>
              <div v-if="workerLength >= 1" :class="[workerLength > 10 ? 'h-24 overflow-auto' : 'h-auto']"  class="grid grid-cols-5 sm:grid-cols-6 md:grid-cols-7 lg:grid-cols-8 xl:grid-cols-9 2xl:grid-cols-10 gap-1 bg-gray-900 rounded p-1" id="style-1">
                <div v-for="worker in workers" :key="worker">
                  <div :class="[worker.work ? 'bg-red-500' : 'bg-gray-500']" class="p-0.5 rounded-sm">
                    <div class="flex justify-center"><img :src="worker.img" alt=""></div>
                    <div class="flex justify-center space-x-1 font-bold items-center text-xs">
                      <div><img src="../assets/images//star.png" class="w-3"></div>
                      <div>{{ worker.star }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="workerLength < 1" class="bg-gray-900 h-14 rounded flex justify-center items-center">
                <div class="text-xl font-bold opacity-50">ไม่พบคนงาน</div>
              </div>
            </div>
            <div>
              <div class="text-xs text-gray-300">รายชื่อไอเทม</div>
              <div v-if="itemLength >= 1" :class="[itemLength > 10 ? 'h-24 overflow-auto' : 'h-auto']" class="grid grid-cols-5 sm:grid-cols-6 md:grid-cols-7 lg:grid-cols-8 xl:grid-cols-9 2xl:grid-cols-10 gap-1 bg-gray-900 rounded p-1" id="style-1">
                <div v-for="item in items" :key="item">
                  <div :class="[item.used ? 'bg-red-500' : 'bg-gray-500']" class="p-0.5 rounded-sm">
                    <div class="flex justify-center"><img :src="item.img" alt=""></div>
                    <div class="flex justify-center space-x-1 font-bold items-center text-xs">
                      <div><img src="../assets/images//star.png" class="w-3"></div>
                      <div>{{ item.star }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="itemLength < 1" class="bg-gray-900 h-14 rounded flex justify-center items-center">
                <div class="text-xl font-bold opacity-50">ไม่พบไอเทม</div>
              </div>
            </div>
          </div>
          <div class="mt-2">
          <button @click="cancelModal" type="button" class="mt-1.5 w-full justify-center rounded bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm   hover:bg-white  duration-300">ยกเลิก</button>
        </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  </template>
  <script>
  import { ref } from 'vue'
import { projectFirestore } from '@/firebase/config'
  export default {
    props:['profileUid'],
    setup(props,context) {
        const cancelModal = e =>{context.emit('cancelModal')}

        const profile = ref({})
        projectFirestore.collection('users').doc(props.profileUid).get().then(userDoc =>{
            profile.value = userDoc.data()
        })

        const items = ref([])
        const workers = ref([])
        const itemLength = ref(1)
        const workerLength = ref('')

        projectFirestore.collection('workers').where('ownerUid','==',props.profileUid).where('isLive','==',true).orderBy('work','desc').orderBy('star','desc').get().then(snapshot =>{
          workerLength.value = snapshot.size
          snapshot.forEach(workerDoc =>{
            workers.value.push(workerDoc.data())
          })
        })

        projectFirestore.collection('items').where('ownerUid','==',props.profileUid).get().then(snapshot =>{
          itemLength.value = snapshot.size
          snapshot.forEach(itemDoc =>{
            items.value.push(itemDoc.data())
          })
        })

        return { cancelModal,profile,items,workers,itemLength, workerLength }
    },
  }
  </script>
  
  <style>
  #breakAll {
  word-break: break-all;
}
  </style>